import React, { createContext, useContext, useState } from 'react'
import { shouldDisplayServerSideError } from '../_helpers'

const AlertContext = createContext()

export const useAlerts = () => useContext(AlertContext)

export const AlertProvider = ({ children }) => {
  const [alerts, setAlerts] = useState([])

  const addAlert = (message, type = 'info', timeout = 20000) => {
    const id = new Date().getTime()
    setAlerts((prevAlerts) => [...prevAlerts, { id, message, type }])

    if (timeout !== null) {
      setTimeout(() => {
        removeAlert(id)
      }, timeout)
    }
  }

  const removeAlert = (id) => {
    setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert.id !== id))
  }

  const displayAPIAlert = (responseError, altMessage) => {
    if (shouldDisplayServerSideError(responseError)) {
      addAlert(responseError.response.data.detail, 'error')
    } else {
      addAlert(altMessage, 'error')
    }
  }

  return (
    <AlertContext.Provider value={{ alerts, addAlert, removeAlert, displayAPIAlert }}>{children}</AlertContext.Provider>
  )
}
