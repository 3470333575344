import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import MultiForm from './multistepform/MultiForm'
import { ProgressBar } from 'react-bootstrap'
import PageOne from './multistepform/createFucha/PageOne'
import PageTwo from './multistepform/createFucha/PageTwo'
import useMultiStepForm from './multistepform/useMultistepForm'
import Controllers from './multistepform/Controllers'
import { useFuchaActions } from '../../../_actions'
import { createFuchaSchema } from './multistepform/createFucha/schema'
import { useAlerts } from '../../../context/AlertContext'
import { getQueryParam, removeQueryParam } from '../../../_helpers'
import { DYNAMIC_URL_PATHS } from '../../../_constants'
import { history } from '../../../_helpers'
import { scrollToTop } from '../../../_helpers'

import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import 'filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css'
import 'filepond/dist/filepond.min.css'
import 'react-datepicker/dist/react-datepicker.css'
import { v4 as uuidv4 } from 'uuid'
import PageThree from './multistepform/createFucha/PageThree'

const ContentOfPublishFuche = () => {
  const { addAlert } = useAlerts()
  const [showCreateFucheModal, setShowCreateFucheModal] = useState(false)
  const [fuchaId, setFuchaId] = useState()
  const [fucha, setFucha] = useState({})
  const { createUpdateFucha, publishFucha, getFuchaDetails } = useFuchaActions()

  const { Step, nextStep, previousStep, goToStep, isFirstStep, isLastStep, currentStep } = useMultiStepForm([
    PageOne,
    PageTwo,
    PageThree,
  ])

  const defaultValues = {
    categories: [],
    county: '',
    description: '',
    is_realization_asap: false,
    province: '',
    realization_date_from: '',
    realization_date_to: '',
    sqm: '',
    title: '',
    typesOfWork: [],
    workState: '',
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    control,
    watch,
    setValue,
    getValues,
  } = useForm({
    defaultValues,
    resolver: zodResolver(createFuchaSchema),
  })

  const categoriesArray = watch('categories')
  const typesOfWorkArray = watch('typesOfWork')
  const realizationDateFrom = watch('realizationDateFrom')
  const realizationDateTo = watch('realizationDateTo')

  const handleSubmitCreateFuchaForm = (data) => {
    if (currentStep === 0) {
      let isRealizationAsap = false
      if (data.realizationTime === 'realizationAsap') {
        isRealizationAsap = true
      }

      // Format dates to YYYY-MM-DD format
      const formatDate = (date) => {
        if (!date) return null
        const d = new Date(date)
        const year = d.getFullYear()
        const month = `0${d.getMonth() + 1}`.slice(-2)
        const day = `0${d.getDate()}`.slice(-2)
        return `${year}-${month}-${day}`
      }

      const payload = {
        categories: categoriesArray.map((category) => category.value),
        county: data.county,
        description: data.fuchaDescription,
        is_realization_asap: isRealizationAsap,
        province: data.province,
        sqm: data.sqm,
        title: data.fuchaTitle,
        types_of_work: typesOfWorkArray.map((type) => type.value),
        work_state: data.workState,
      }

      if (realizationDateFrom) {
        payload.realization_date_from = formatDate(realizationDateFrom)
      }
      if (realizationDateTo) {
        payload.realization_date_to = formatDate(realizationDateTo)
      }
      createUpdateFucha(payload, fuchaId)
        .then((data) => {
          setFucha(data)
        })
        .catch((err) => {
          console.log(err)
        })
    }
    nextStep()
  }

  useEffect(() => {
    // Load fucha if fuchaId is provided
    const maybeFuchaId = getQueryParam('fuchaId')
    if (maybeFuchaId) {
      getFuchaDetails(maybeFuchaId)
        .then((data) => {
          removeQueryParam('fuchaId')
          if (data.status !== 'nieaktywna') {
            addAlert('Fucha nie może być edytowana w tym momencie.', 'error')
            reset()
          } else {
            setFucha(data)
            setFuchaId(maybeFuchaId)
            setValue('realizationDateFrom', data.realization_date_from)
            setValue('realizationDateTo', data.realization_date_from)
            setValue('sqm', data.sqm)
            setValue('fuchaTitle', data.title)
            setValue('fuchaDescription', data.description)
            if (data.province) {
              setValue('province', data.province)
            }
            if (data.county) {
              setValue('county', data.county)
            }
            setValue('workState', data.work_state)

            if (data.realization_date_to || data.realization_date_from) {
              setValue('realizationTime', 'realizationInDates')
            } else if (data.is_realization_asap) {
              setValue('realizationTime', 'realizationAsap')
            } else {
              setValue('realizationTime', 'realizationNotAsap')
            }
            setValue(
              'typesOfWork',
              data.types_of_work.map((type) => ({ value: type.id, label: type.name }))
            )
            setValue(
              'categories',
              data.categories.map((type) => ({ value: type.id, label: type.name }))
            )
            setValue('fuchaDescription', data.description)
            setShowCreateFucheModal(true)
          }
        })
        .catch((err) => {
          removeQueryParam('fuchaId')
          addAlert('Wystąpił błąd podczas pobierania danych fuchy. Spróbuj ponownie później.', 'error')
          reset()
          handleCloseCreateFucheModal()
        })
    }
  }, [])

  const handleShowCreateFucheModal = () => {
    setFuchaId(uuidv4())
    setShowCreateFucheModal(true)
  }

  const handleCloseCreateFucheModal = () => {
    // this is needed when the modal is closed manually
    if (currentStep === 1) {
      addAlert('Fucha została zapisana na później. Znajdziesz ją w swoim profilu w zakładce "Moje Fuchy"', 'success')
    }
    reset()
    setShowCreateFucheModal(false)
    setFucha({})
  }

  const handleFinish = (e) => {
    reset()
    publishFucha(fuchaId).catch((err) => {
      // TODO: Spinner on button
      console.log(err)
    })
    handleCloseCreateFucheModal()
    addAlert(
      'Fucha została opublikowana. Powiadomimy Cię, jeśli dostaniesz nowe wyceny lub wiadomości od fachowców.',
      'success'
    )
    scrollToTop()
    history.push(DYNAMIC_URL_PATHS.FuchaDetail(fuchaId))
  }

  const handleOnExtraButtonClick = (e) => {
    reset()
    handleCloseCreateFucheModal()
    addAlert('Fucha została zapisana na później. Znajdziesz ją w swoim profilu w zakładce "Moje Fuchy"', 'success')
  }

  const renderCreateFuchaModal = () => {
    return (
      <Modal
        className="create-fucha-modal-container"
        show={showCreateFucheModal}
        onHide={() => handleCloseCreateFucheModal()}
      >
        <div className="create-fuche-modal">
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <img src={process.env.PUBLIC_URL + '/images/bg/client-right.png'} className="client-right-vector" alt="" />
            <img src={process.env.PUBLIC_URL + '/images/bg/client-right.png'} className="client-left-vector" alt="" />
            <img src={process.env.PUBLIC_URL + '/images/bg/clent-circle1.png'} className="client-circle1" alt="" />
            <img src={process.env.PUBLIC_URL + '/images/bg/clent-circle2.png'} className="client-circle2" alt="" />
            <img src={process.env.PUBLIC_URL + '/images/bg/clent-circle3.png'} className="client-circle3" alt="" />
            <div className="col-lg-9 h-100">
              <div
                className={`wow fadeInDown ${currentStep === 1 ? 'pt-0' : ''}
                  ${currentStep === 2 ? 'step-two' : ''}
                  ${Object.keys(errors).length > 0 ? 'errors-padding' : 'create-fuche-form-wrapper'}`}
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                {' '}
                {/* ----------- CREATE FUCHE FORM START ------------- */}
                <div className="form-wrapper">
                  {/* ----------- CREATE CONTRACTOR PROFILE FORM START ------------- */}
                  <MultiForm id="create-fucha-form" onSubmit={handleSubmit(handleSubmitCreateFuchaForm)}>
                    <div className="progress-bar-container">
                      <ProgressBar now={(currentStep / 2) * 100} />
                    </div>
                    {
                      <Step
                        register={register}
                        control={control}
                        watch={watch}
                        getValues={getValues}
                        errors={errors}
                        setValue={setValue}
                        fuchaId={fuchaId}
                        fucha={fucha}
                        setFucha={setFucha}
                      />
                    }
                  </MultiForm>
                  {/* ----------- FORM END ------------- */}
                  <Controllers
                    previousStep={previousStep}
                    isFirstStep={isFirstStep}
                    isLastStep={isLastStep}
                    handleFinish={handleFinish}
                    formId="create-fucha-form"
                    previousStepText="Wróć"
                    nextStepText="Dalej"
                    lastStepText="Wystaw Fuchę"
                    extraButton
                    extraButtonText="Dokończ później"
                    onExtraButtonClick={handleOnExtraButtonClick}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    )
  }

  const renderPartWhenNotFuchaYet = () => {
    return (
      <>
        <div className="create-fuche">
          <img src={`${process.env.PUBLIC_URL}/images/bg/client-bg.png`} className="mobiles-bg" alt="" />
          <div className="container">
            <div className="row d-flex justify-content-center g-4 align-items-center">
              <div className="col-lg-6 col-md-10">
                <div className="about-img-area">
                  <img
                    src={process.env.PUBLIC_URL + '/images/bg/about-img.png'}
                    className="img-fluid about-img wow fadeInUp"
                    data-wow-duration="1.5s"
                    data-wow-delay=".2s"
                    alt="about-img"
                  />
                  <img
                    src={process.env.PUBLIC_URL + '/images/bg/about-vector.png'}
                    className="img-fluid about-vector"
                    alt=""
                  />
                  <img
                    src={process.env.PUBLIC_URL + '/images/bg/about-linear.png'}
                    className="img-fluid about-linear"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-10">
                <div className="create-fuche-content wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".2s">
                  <h2 className="mb-4">Wystaw fuchę i znajdź fachowca który będzie Ci najbardziej odpowiadał!</h2>
                  <p className="para">
                    Pamiętaj! Po wystawieniu fuchy nie można jej już edytować. Jeśli popełnisz błąd lub zmienisz zdanie,
                    możesz usunąć fuchę i wystawić nową.
                  </p>
                  <p className="para">Wszystkie fuchy znajdziesz w zakładce „Moje Fuchy”.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="button-container">
          <button
            id="create-fucha-button"
            onClick={() => handleShowCreateFucheModal(true)}
            className="eg-btn btn--primary m-3"
          >
            Kliknij By Wystawić Fuchę
          </button>
        </div>
        {renderCreateFuchaModal()}
      </>
    )
  }

  return <>{renderPartWhenNotFuchaYet()}</>
}

export default ContentOfPublishFuche
