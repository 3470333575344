import React, { useState, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'
import Breadcrumb from '../../common/Breadcrumb'
import { useAuth } from '../../../context/AuthContext'
import { useForm } from 'react-hook-form'
import { SignUpSchema } from './schema'
import { zodResolver } from '@hookform/resolvers/zod'
import { URL_PATHS } from '../../../_constants/urls'
import { useHistory } from 'react-router-dom'
import { useAlerts } from '../../../context/AlertContext'
import { useUserActions } from '../../../_actions'
import { scrollToTop, metaPixelEvent, META_EVENT_NAME, googleAdsEvent, GOOGLE_EVENT_NAME } from '../../../_helpers'
import SignupForm from './SignupForm'

const SignUpClient = () => {
  const { addAlert } = useAlerts()
  const { isAuth } = useAuth()
  const userActions = useUserActions()
  // TODO(MAM-253): remove this code
  const localStorageKeyExist = localStorage.getItem('developerMode') === 'true'

  useEffect(() => {
    metaPixelEvent(META_EVENT_NAME.VIEW_CONTENT)
  }, [])

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm({
    resolver: zodResolver(SignUpSchema),
  })

  let history = useHistory()

  const onSubmit = async (data) => {
    const payload = {
      email: data.email.toLowerCase(),
      password: data.password,
      first_name: data.firstName,
      last_name: data.lastName,
      phone_number: data.phoneNumber,
      is_marketing_enabled: data.marketingComms,
      is_external_marketing_enabled: data.externalMarketingComms,
      is_contractor: false,
    }

    try {
      await userActions.register(payload)
      addAlert('Konto utworzone pomyślnie! Sprawdź skrzynkę pocztową i potwierdź swój adres e-mail.', 'success')
      metaPixelEvent(META_EVENT_NAME.COMPLETE_REGISTRATION, { is_contractor: false })
      googleAdsEvent(GOOGLE_EVENT_NAME.COMPLETE_REGISTRATION, {
        method: 'Google',
        value: 0,
        currency: 'PLN',
        is_contractor: false,
      })
      reset()
      scrollToTop()
      history.push(URL_PATHS.INFO_AFTER_SIGNUP)
    } catch (error) {
      if (error.response?.data?.display && error.response?.data?.detail) {
        addAlert(error.response.data.detail, 'error')
      } else {
        addAlert('Wystąpił błąd podczas tworzenia konta. Spróbuj ponownie za chwilę.', 'error', null)
      }
    }
  }

  if (isAuth) {
    scrollToTop()
    return <Redirect to={URL_PATHS.DASHBOARD} />
  }

  // TODO(MAM-253): remove this code
  const renderTemporaryMessage = () => {
    return (
      <div className="temporary-message-container">
        <div className="image-container">
          <img alt="images" src={process.env.PUBLIC_URL + '/images/icons/search-contractors.png'} />
        </div>
        <h4>Witaj! Aktualnie budujemy bazę fachowców, dbając o to, aby znaleźć tych najlepszych!</h4>
        <Link to={URL_PATHS.SIGNUP_CONTRACTOR}>
          <button className="eg-btn btn--primary mb-3" onClick={() => scrollToTop()}>
            Dołącz jako fachowiec
          </button>
        </Link>
        <h4>
          Wystawianie fuch będzie możliwe od 1 marca 2025 roku. Zapraszamy do odwiedzenia nas ponownie w tym czasie!
        </h4>
        <h4>Jeżeli masz jakieś pytania, chętnie na nie odpowiemy!</h4>
        <Link to={URL_PATHS.CONTACT}>
          <button className="eg-btn btn--primary mb-2" onClick={() => scrollToTop()}>
            Zadaj pytanie
          </button>
        </Link>
      </div>
    )
  }

  return (
    <div>
      <div className="client">
        <Breadcrumb pageName="Masz fuchę do zlecenia?" pageTitle="Zarejestruj konto i zacznij wystawiać fuchy!" />
      </div>
      <div className="signup-section pt-120 pb-120">
        <img alt="imges" src={process.env.PUBLIC_URL + '/images/bg/client-bg.svg'} className="img-fluid section-bg" />
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-xl-6 col-lg-8 col-md-10">
              {!localStorageKeyExist ? (
                renderTemporaryMessage()
              ) : (
                <div className="form-wrapper wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".2s">
                  <div className="form-title">
                    <h3>Zarejestruj konto i zacznij wystawiać fuchy!</h3>
                    <p className="signup-text">
                      Masz już konto?{' '}
                      <Link to={URL_PATHS.LOGIN} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                        Zaloguj się tutaj
                      </Link>
                    </p>
                    <div className="eg-btn btn--primary p-2 join-btn w-100 mt-4">
                      <Link to={URL_PATHS.SIGNUP_CONTRACTOR} onClick={() => scrollToTop()}>
                        Jesteś fachowcem? Kliknij tutaj!
                      </Link>
                    </div>
                  </div>
                  <SignupForm
                    register={register}
                    handleSubmit={handleSubmit}
                    onSubmit={onSubmit}
                    errors={errors}
                    isSubmitting={isSubmitting}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignUpClient
