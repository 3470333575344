import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAuth } from '../../../context/AuthContext'
import { useFuchaActions, useUserActions } from '../../../_actions/'
import { useAlerts } from '../../../context/AlertContext'
import Breadcrumb from '../../common/Breadcrumb'
import FuchaDetailsWrap from './FuchaDetailsWrap'
import { history } from '../../../_helpers'
import { URL_PATHS } from '../../../_constants'
import { FUCHA_STATUS } from '../../../_constants'

const FuchaDetails = () => {
  const { fuchaId } = useParams()
  const [fucha, setFucha] = useState(null)
  const [fuchaLoading, setFuchaLoading] = useState(true)
  const [userLoading, setUserLoading] = useState(true)
  const { addAlert } = useAlerts()
  const [user, setUser] = useState(null)
  const { auth } = useAuth()
  const { getFuchaDetails } = useFuchaActions()
  const { getUser } = useUserActions()

  useEffect(() => {
    setFuchaLoading(true)
    setUserLoading(true)

    const fetchDetails = getFuchaDetails(fuchaId)
      .then((data) => {
        console.log(data)
        return data
      })
      .catch((err) => {
        console.log(err)
        if (err?.response?.status === 404 || err === 'Unprocessable Entity') {
          history.push(URL_PATHS.NOT_FOUND)
        } else {
          addAlert('Nie udało się pobrać szczegółów fuchy. Spróbuj ponownie później.', 'error')
        }
      })

    const fetchUser = auth
      ? getUser().catch((err) => {
          console.error('User error')
          return null
        })
      : Promise.resolve(null)

    Promise.all([fetchDetails, fetchUser]).then((values) => {
      const [fuchaDetails, userDetails] = values
      setFucha(fuchaDetails)
      if (userDetails) {
        setUser(userDetails)
      }
      setFuchaLoading(false)
      setUserLoading(false)
    })
  }, [fuchaId, auth])

  const getFuchaTitle = () => {
    const status = fucha?.status
    const title = fucha?.title
    if ([FUCHA_STATUS.FINISHED, FUCHA_STATUS.ARCHIVED].includes(status)) {
      return `Fucha - ${title} - ZAKOŃCZONA`
    } else if (status && status !== FUCHA_STATUS.ACTIVE) {
      return `Fucha - ${title} - ${status.toUpperCase()}`
    } else {
      return `Fucha - ${title}`
    }
  }

  return (
    <>
      <Breadcrumb
        pageName={fuchaLoading ? '' : getFuchaTitle()}
        pageTitle={fuchaLoading ? '' : `${fucha?.province} - ${fucha?.county}`}
        loading={fuchaLoading}
        titleClassName="fucha-detail-title"
      />
      <FuchaDetailsWrap
        fucha={fucha}
        setFucha={setFucha}
        maybeUser={user}
        userLoading={userLoading}
        fuchaLoading={fuchaLoading}
      />

      {/* <AboutUsCounter /> */}
    </>
  )
}

export default FuchaDetails
