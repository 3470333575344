import React, { useState, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'
import Breadcrumb from '../../common/Breadcrumb'
import { useAuth } from '../../../context/AuthContext'
import { useForm } from 'react-hook-form'
import { SignUpSchema } from './schema'
import { zodResolver } from '@hookform/resolvers/zod'
import { URL_PATHS } from '../../../_constants/urls'
import { useHistory } from 'react-router-dom'
import { useAlerts } from '../../../context/AlertContext'
import { useUserActions } from '../../../_actions'
import { scrollToTop, metaPixelEvent, META_EVENT_NAME, googleAdsEvent, GOOGLE_EVENT_NAME } from '../../../_helpers'
import SignupForm from './SignupForm'

const SignUpContractor = () => {
  const { addAlert } = useAlerts()
  const { isAuth } = useAuth()
  const userActions = useUserActions()

  useEffect(() => {
    metaPixelEvent(META_EVENT_NAME.VIEW_CONTENT)
  }, [])

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    setError,
  } = useForm({
    resolver: zodResolver(SignUpSchema),
  })

  let history = useHistory()

  const onSubmit = async (data) => {
    const payload = {
      email: data.email.toLowerCase(),
      password: data.password,
      first_name: data.firstName,
      last_name: data.lastName,
      phone_number: data.phoneNumber,
      is_marketing_enabled: data.marketingComms,
      is_external_marketing_enabled: data.externalMarketingComms,
      is_contractor: true,
    }
    try {
      await userActions.register(payload)

      addAlert('Konto utworzone pomyślnie! Sprawdź skrzynkę pocztową i potwierdź swój adres e-mail.', 'success')
      metaPixelEvent(META_EVENT_NAME.COMPLETE_REGISTRATION, { is_contractor: true })
      googleAdsEvent(GOOGLE_EVENT_NAME.COMPLETE_REGISTRATION, {
        method: 'Google',
        value: 0,
        currency: 'PLN',
        is_contractor: true,
      })
      reset()
      scrollToTop()
      history.push(URL_PATHS.INFO_AFTER_SIGNUP)
    } catch (error) {
      if (error.response?.data?.display && error.response?.data?.detail) {
        addAlert(error.response.data.detail, 'error')
      } else {
        addAlert('Wystąpił błąd podczas tworzenia konta. Spróbuj ponownie za chwilę.', 'error', null)
      }
    }
  }

  if (isAuth) {
    scrollToTop()
    return <Redirect to={URL_PATHS.DASHBOARD} />
  }

  return (
    <div>
      <div className="contractor">
        <Breadcrumb pageName="Jesteś fachowcem?" pageTitle="Zarejestruj konto aby móc składać wyceny!" />
      </div>

      <div className="signup-section pt-120 pb-120">
        <img alt="imges" src={process.env.PUBLIC_URL + '/images/bg/client-bg.svg'} className="img-fluid section-bg" />
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-xl-6 col-lg-8 col-md-10">
              <div className="form-wrapper wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".2s">
                <div className="form-title">
                  <h3>Zarejestruj konto i łap najlepsze fuchy!</h3>
                  <p className="signup-text">
                    Masz już konto?{' '}
                    <Link to={URL_PATHS.LOGIN} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                      Zaloguj się tutaj
                    </Link>
                  </p>
                  <div className="eg-btn btn--primary p-2 join-btn w-100 mt-4">
                    <Link to={URL_PATHS.SIGNUP} onClick={() => scrollToTop()}>
                      Chcesz zlecić fuchę? Kliknij tutaj!
                    </Link>
                  </div>
                </div>
                <SignupForm
                  register={register}
                  handleSubmit={handleSubmit}
                  onSubmit={onSubmit}
                  errors={errors}
                  isSubmitting={isSubmitting}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignUpContractor
