import React, { useEffect, useRef, useState } from 'react'
import { getQueryParam } from '../../../_helpers'
import { Channel, ChannelList, MessageInput, MessageList, Thread, Window, InfiniteScroll } from 'stream-chat-react'
import dayjs from 'dayjs'
import 'dayjs/locale/pl'
import CustomChannelPreview from './CustomChannelPreview'
import CustomChannelHeader from './CustomChannelHeader'

// Set dayjs to use the Polish locale
dayjs.locale('pl')

const ChatChannels = ({ user, client }) => {
  const channelId = getQueryParam('chatId')
  const isChannelListOpenRef = useRef(true)
  const [noChannels, setNoChannels] = useState(false)

  const toggleChannelList = () => {
    const channelListElement = document.querySelector('.list-of-channels')
    isChannelListOpenRef.current = !isChannelListOpenRef.current
    channelListElement.classList.toggle('open-list', isChannelListOpenRef.current)
    channelListElement.classList.toggle('closed-list', !isChannelListOpenRef.current)
  }

  const initializeChannelListState = () => {
    const channelListElement = document.querySelector('.list-of-channels')
    if (channelId) {
      isChannelListOpenRef.current = false
      channelListElement.classList.add('closed-list')
      channelListElement.classList.remove('open-list')
    } else {
      isChannelListOpenRef.current = true
      channelListElement.classList.add('open-list')
      channelListElement.classList.remove('closed-list')
    }
  }

  useEffect(() => {
    initializeChannelListState()
  }, [channelId])

  return (
    <>
      <div className="list-of-channels open-list">
        <div className="channel-list-header-container">
          <h5>Aktywne czaty</h5>
          <i onClick={toggleChannelList} className="bi bi-x-lg"></i>
        </div>
        <ChannelList
          filters={{ type: 'messaging', members: { $in: [user.id] } }}
          sort={{ last_message_at: -1 }}
          options={{ limit: 15 }}
          customActiveChannel={channelId}
          Paginator={InfiniteScroll}
          Preview={(previewProps) => <CustomChannelPreview {...previewProps} client={client} />}
          EmptyStateIndicator={() => {
            setNoChannels(true)
            return (
              <div className="no-channels">
                <p className="para">
                  <b>Obecnie nie masz aktywnych kanałów...</b>
                </p>
              </div>
            )
          }}
        />
      </div>
      {noChannels ? (
        <div className="no-channels-list">
          <div className="close-list-button-container">
            <i onClick={toggleChannelList} className="bi bi-arrow-left-short"></i>
          </div>
          <p className="para">
            <b>Obecnie nie masz żadnych wiadomości...</b>
          </p>
        </div>
      ) : (
        <Channel filters={{ type: 'messaging', members: { $in: [user.id] } }} sort={{ last_message_at: -1 }}>
          <Window>
            <CustomChannelHeader user={user} toggleChannelList={toggleChannelList} />
            <MessageList />
            <MessageInput />
          </Window>
          <Thread />
        </Channel>
      )}
    </>
  )
}
export default ChatChannels
