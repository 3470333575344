import React from 'react'
import { Link } from 'react-router-dom'
import { URL_PATHS } from '../../../_constants/urls'
import { useAuth } from '../../../context/AuthContext'
import { useDashboardTab } from '../../../context/DashboardTabContext'
import { scrollToTop } from '../../../_helpers'

const HeroBanner = () => {
  const { auth } = useAuth()
  const { activeTab, setActiveTab } = useDashboardTab()
  const isContractor = auth?.user.is_contractor === true

  return (
    <>
      <div className="hero-area hero-style-two">
        <div className="hero-style-two-mobiles"></div>
        <img alt="heroImage" src={process.env.PUBLIC_URL + '/images/bg/section-bg2.png'} className="radial-bg" />
        <img alt="heroImage" src={process.env.PUBLIC_URL + '/images/bg/banner2-icon1.png'} className="banner2-icon1" />
        <img alt="heroImage" src={process.env.PUBLIC_URL + '/images/bg/banner2-icon2.png'} className="banner2-icon2" />
        <img alt="heroImage" src={process.env.PUBLIC_URL + '/images/bg/banner2-icon3.png'} className="banner2-icon3" />
        <div className="scroll-text">
          <h6>
            <a href="#category">Przewiń dalej</a>
          </h6>
        </div>
        <div className="container">
          <div className="row d-flex justify-content-start align-items-end">
            <div className="col-xl-7 col-lg-7">
              <div className="banner2-content">
                <h1 className="wow fadeInDown" data-wow-duration="1.5s" data-wow-delay="1s">
                  Wystawiaj i wybieraj spośród wielu fuch i fachowców w Polsce!
                </h1>
                <p className="wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="1s">
                  Jeżeli masz fuchę do zlecenia wystaw ją i pozwól fachowcom zgłaszać swoje wyceny.
                  <br />
                  Jeśli jesteś fachowcem, przeglądaj fuchy i wybierz te, które najbardziej odpowiadają Twoim
                  umiejętnościom!
                </p>
                {/* <Link
                  to={
                    auth && !isContractor
                      ? `${URL_PATHS.DASHBOARD}`
                      : auth && isContractor
                        ? URL_PATHS.FUCHA_LISTING
                        : URL_PATHS.SIGNUP
                  }
                  className="eg-btn btn--primary2 btn--lg wow fadeInUp"
                  data-wow-duration="2s"
                  data-wow-delay="0.8s"
                  onClick={() => {
                    if (auth && !isContractor) {
                      setActiveTab('tab21')
                      scrollToTop()
                    }
                  }}
                >
                  {!isContractor ? 'Wystaw fuchę' : 'Przeglądaj fuchy'}
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HeroBanner
