import React, { useState, useEffect } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import axios from 'axios'
import Skeleton from 'react-loading-skeleton'
import { CheckCircle } from '@mui/icons-material'
import { useApi } from '../../../../../_helpers'
import { MAX_IMAGE_SIZE_MB, DYNAMIC_API_URLS } from '../../../../../_constants'
// Import React FilePond
import pl_PL from 'filepond/locale/pl-pl.js'
import { FilePond, File, registerPlugin } from 'react-filepond'
import FilePondPluginImageCrop from 'filepond-plugin-image-crop'
import FilePondPluginImageEdit from 'filepond-plugin-image-edit'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import { useFuchaActions } from '../../../../../_actions'

import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import 'filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css'
import 'filepond/dist/filepond.min.css'

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageCrop,
  FilePondPluginImageEdit,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize
)

const PageTwo = ({ fuchaId }) => {
  const [files, setFiles] = useState([])
  const [errorMsg, setErrorMsg] = useState('')
  const [uploadedFiles, setUploadedFiles] = useState(0)
  const [totalFiles, setTotalFiles] = useState(0)
  const [isUploading, setIsUploading] = useState(false)
  const [loading, setLoading] = useState(true)
  const { api } = useApi()
  const { getFuchaImages } = useFuchaActions()
  const maxFilesQty = 8
  const allImagesUploaded = uploadedFiles === totalFiles && uploadedFiles > 0

  useEffect(() => {
    getFuchaImages(fuchaId)
      .then((resp) => {
        const initUserGallery = resp.map((item) => {
          return {
            source: `${item.url}`,
            options: {
              type: 'local',
              serverId: item.id,
            },
            serverId: item.id,
          }
        })
        setFiles(initUserGallery)
        setUploadedFiles(initUserGallery.length)
        setTotalFiles(initUserGallery.length)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }, [])

  const renderSkeleton = () => {
    return (
      <div className="loading-text">
        <div className="text-container-1">
          <Skeleton width={200} height={35} />
        </div>
        <div className="text-container-1">
          <Skeleton width={300} height={35} />
        </div>
      </div>
    )
  }

  const handleUpdateFiles = (fileItems) => {
    setFiles(fileItems)
    setUploadedFiles(fileItems.filter((file) => file.serverId).length)
    setTotalFiles(fileItems.length)
  }

  const handleAddFile = () => {
    setTotalFiles((prev) => prev + 1)
    setIsUploading(true)
  }

  const handleProcessFile = (error, file) => {
    if (!error) {
      setUploadedFiles((prev) => prev + 1)
      if (uploadedFiles + 1 === totalFiles) {
        setIsUploading(false)
      }
    }
  }

  const handleRemoveFile = (fileItem) => {
    if (fileItem.serverId) {
      const fileNameParts = fileItem.file.name.split('.')
      const fileId = fileNameParts.slice(0, -1).join('.')
      api
        .delete(DYNAMIC_API_URLS.FuchaDeleteImage(fuchaId, fileId), {})
        .then(() => {
          setUploadedFiles((prev) => Math.max(prev - 1, 0))
        })
        .catch((err) => {
          console.error('Error removing file:', err)
          if (err.response && err.response.status === 404) {
            console.warn('File not found on server. It may have already been removed.')
          }
        })
    }
  }

  return (
    <div className="create-fucha-gallery-section">
      <div className="form-section-title">
        <p>
          Dodaj zdjęcia {files.length}/{maxFilesQty}:
        </p>
      </div>
      <div className="explanation-text">
        <p>
          Przeciągnij lub dodaj zdjęcia z folderu. Staraj się dodawać zdjęcia, które ułatwią ocenę dla fachowców.
          <br></br> Pamiętaj, że pierwsze załadowane zdjęcie będzie używane jako miniatura dla ogłoszenia.
        </p>
      </div>
      <span className="text-danger ">{errorMsg}</span>
      <div className="row upload-container">
        <FilePond
          {...pl_PL}
          files={files}
          allowMultiple={true}
          onupdatefiles={handleUpdateFiles}
          onaddfile={handleAddFile}
          onprocessfile={(error, file) => handleProcessFile(error, file)}
          onwarning={(error) => {
            if (error.body === 'Max files') {
              setErrorMsg(`Maksymalnie możesz dodać ${maxFilesQty} zdjęć!`)
            }
          }}
          maxFileSize={`${MAX_IMAGE_SIZE_MB}MB`}
          labelMaxFileSizeExceeded={`Maksymalny rozmiar pliku to ${MAX_IMAGE_SIZE_MB}MB`}
          acceptedFileTypes={['image/jpeg', 'image/png']}
          labelIdle='Przeciągnij lub dodaj zdjęcia <span class="filepond--label-action">TUTAJ</span><br/>'
          maxFiles={maxFilesQty}
          name="file"
          allowImageEdit={true}
          storeAsFile={true}
          onremovefile={(error, fileItem) => {
            handleRemoveFile(fileItem)
          }}
          server={{
            process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
              const formData = new FormData()
              formData.append(fieldName, file, file.name)
              // Creating a CancelToken source
              const sourceToken = axios.CancelToken.source()
              api
                .post(DYNAMIC_API_URLS.FuchaImages(fuchaId), formData, {
                  onUploadProgress: (e) => {
                    progress(e.lengthComputable, e.loaded, e.total)
                  },
                  cancelToken: sourceToken.token,
                })
                .then((response) => {
                  if (response.status >= 200 && response.status < 300) {
                    const uniqueFileId = response.data.id

                    load(uniqueFileId)
                  } else {
                    error('Nie udało się przetworzyć pliku. Spróbuj ponownie później.')
                  }
                })
                .catch((err) => {
                  if (axios.isCancel(err)) {
                    console.log('Request canceled', err.message)
                  } else {
                    error(err.message || 'Nie udało się przetworzyć pliku. Spróbuj ponownie później.')
                  }
                })

              // Return the abort method so FilePond can cancel the upload if needed
              return {
                abort: () => {
                  sourceToken.cancel('Operation canceled by the user.')
                  abort()
                },
              }
            },
            revert: (uniqueFileId, load, error) => {
              api
                .delete(DYNAMIC_API_URLS.FuchaDeleteImage(fuchaId, uniqueFileId), {})
                .then((response) => {
                  if (response.status >= 200 && response.status < 300) {
                    load()
                  } else {
                    error('An error occurred')
                  }
                })
                .catch((err) => {
                  error(err.message || 'An error occurred')
                })
            },
            load: (source, load, error, progress, abort, headers) => {
              // Provide a way to cancel the request
              const sourceToken = axios.CancelToken.source()
              axios
                .get(source, {
                  responseType: 'blob',
                  onDownloadProgress: (progressEvent) => {
                    // Optional: handle the progress event here
                  },
                  cancelToken: sourceToken.token,
                })
                .then((response) => {
                  load(response.data)
                })
                .catch((err) => {
                  error(err.message)
                })

              return {
                abort: () => {
                  sourceToken.cancel('Operation canceled by the user.')
                },
              }
            },
          }}
        />
      </div>
      {loading ? (
        renderSkeleton()
      ) : (
        <div className="loading-text">
          <div className="text-container-1">
            <p className={`para text-1 ${allImagesUploaded ? 'text-success' : 'text-danger'}`}>
              Załadowano <span className={allImagesUploaded ? 'text-success' : 'text-danger'}>{uploadedFiles}</span>/
              <span className={allImagesUploaded ? 'text-success' : 'text-danger'}>{totalFiles}</span> zdjęć
              <span className="icon-success">
                {isUploading && totalFiles !== 0 && uploadedFiles !== files.length ? (
                  <CircularProgress
                    size={20}
                    sx={{
                      color: '#f9395f',
                    }}
                  />
                ) : (!isUploading && allImagesUploaded && uploadedFiles === files.length) ||
                  (allImagesUploaded && uploadedFiles === files.length) ? (
                  <CheckCircle size={20} />
                ) : null}
              </span>
            </p>
          </div>
          <div className="text-container-2">
            <p className="para text-2">
              * Poczekaj na załadowanie <span className="text-danger">wszystkich zdjęć</span> przed przejściem do
              następnego kroku!
            </p>
          </div>
        </div>
      )}
    </div>
  )
}

export default PageTwo
