import React, { useEffect, useRef } from 'react'
import { Avatar, useChannelStateContext, TypingIndicator } from 'stream-chat-react'
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService'
import { DYNAMIC_URL_PATHS } from '../../../_constants/urls'
import { scrollToTop } from '../../../_helpers'

const CustomChannelHeader = (props) => {
  const { title, user, toggleChannelList } = props
  const { channel } = useChannelStateContext()
  const { name } = channel.data || {}

  return (
    <div className="custom-header-container">
      <div className="custom-header-info-container">
        <div className="close-list-button-container">
          <i onClick={toggleChannelList} className="bi bi-arrow-left-short"></i>
        </div>
        <div className="header-item">
          <Avatar image={user.profile_image_thumbnail} name={name} />
          <p>
            <span className="header-pound">{title || name}</span>
          </p>
        </div>
        <TypingIndicator />
        <div className="custom-header-buttons-container-large">
          <div className="custom-header-button">
            <a
              className="eg-btn profile-btn contractor-btn"
              href={DYNAMIC_URL_PATHS.ContractorProfile(channel.data.contractor_id)}
              onClick={() => scrollToTop()}
            >
              Sprawdź profil fachowca
            </a>
          </div>
          <div className="custom-header-button">
            <a
              className="eg-btn profile-btn"
              href={DYNAMIC_URL_PATHS.FuchaDetail(channel.data.fucha_id)}
              onClick={() => scrollToTop()}
            >
              Sprawdź fuchę
            </a>
          </div>
        </div>

        <div className="custom-header-buttons-container-small">
          <div className="custom-header-button">
            <a
              className="eg-btn profile-btn contractor-btn"
              href={DYNAMIC_URL_PATHS.ContractorProfile(channel.data.contractor_id)}
              onClick={() => scrollToTop()}
            >
              <i className="bi bi-person-fill"></i>
            </a>
          </div>
          <div className="custom-header-button">
            <a
              className="eg-btn profile-btn"
              href={DYNAMIC_URL_PATHS.FuchaDetail(channel.data.fucha_id)}
              onClick={() => scrollToTop()}
            >
              <HomeRepairServiceIcon />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomChannelHeader
