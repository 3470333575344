import React, { useState, useEffect } from 'react'
import { useFuchaActions } from '../../../../../_actions'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import ImageGallery from 'react-image-gallery'
import 'react-loading-skeleton/dist/skeleton.css'

const PageThree = ({ fucha }) => {
  const [images, setImages] = useState([])
  const [isLoadingImages, setIsLoadingImages] = useState(true)
  const { getFuchaImages } = useFuchaActions()

  useEffect(() => {
    getFuchaImages(fucha.id)
      .then((resp) => {
        const images = resp.map((image) => ({
          original: `${image.url}`,
          thumbnail: `${image.url}`,
        }))
        setImages(images)
        setIsLoadingImages(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  return (
    <div className="pb-120">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-10 col-lg-10 col-xl-10 m-auto">
            <div className="mt-5 section-title1 summary-title">
              <h4>Podsumowanie Fuchy</h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
            <div
              className="single-feature summary-container hover-border1 wow fadeInDown"
              data-wow-duration="1.5s"
              data-wow-delay=".2s"
            >
              <span className="sn">01</span>
              <div className="summary-content">
                <div className="content-title">
                  <h4>Szczegóły fuchy</h4>
                </div>
                <div className="content-item-container">
                  <div className="title-box">
                    <h5>Tytuł</h5>
                  </div>
                  <p className="para">{fucha.title}</p>
                </div>
                <div className="content-item-container">
                  <div className="title-box">
                    <h5>Powierzchnia</h5>
                  </div>
                  <p className="para">{fucha.sqm} m²</p>
                </div>
                <div className="content-item-container">
                  <div className="title-box">
                    <h5>Kategoria</h5>
                  </div>

                  <p className="para">
                    {fucha.categories && fucha.categories.length > 0
                      ? fucha.categories.map((category) => category.name).join(', ')
                      : ''}
                  </p>
                </div>
                <div className="content-item-container">
                  <div className="title-box">
                    <h5>Zakres Prac</h5>
                  </div>
                  <p className="para">
                    {fucha.types_of_work && fucha.types_of_work.length > 0
                      ? fucha.types_of_work.map((work) => work.name).join(', ')
                      : ''}
                  </p>
                </div>
                <div className="content-item-container">
                  <div className="title-box">
                    <h5>Miejsce Realizacji</h5>
                  </div>
                  <p className="para">
                    {fucha.county}, {fucha.province}
                  </p>
                </div>
                <div className="content-item-container">
                  <div className="title-box">
                    <h5>Status Pracy</h5>
                  </div>
                  <p className="para">{fucha.work_state}</p>
                </div>
                <div className="content-item-container">
                  <div className="title-box">
                    <h5>Termin Realizacji</h5>
                  </div>
                  {fucha.realization_date_from && fucha.realization_date_to ? (
                    <>
                      <p className="para">
                        <span className="date-text">Data od:</span>
                        <span className="date">{fucha.realization_date_from}</span>
                      </p>
                      <p className="para">
                        <span className="date-text">Data do:</span>
                        <span className="date">{fucha.realization_date_to}</span>
                      </p>
                    </>
                  ) : (
                    <p className="para">
                      {fucha.is_realization_asap ? 'Jak najszybciej' : 'Dostosuję się do wykonawcy'}
                    </p>
                  )}
                </div>
                <div className="description-container">
                  <div className="title-box">
                    <h5>Opis Fuchy</h5>
                  </div>
                  <div className="fucha-description">
                    <p className="para">{fucha.description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-12 col-md-12">
            <div
              className={`single-feature summary-container hover-border1 wow fadeInDown ${images.length > 0 ? 'gallery-summary-container' : ''}`}
              data-wow-duration="1.5s"
              data-wow-delay=".2s"
            >
              <span className="sn">02</span>
              <div className="summary-content">
                <div className="content-title">
                  <h4>{images.length === 0 ? 'Brak dodanych zdjęć' : 'Dodane Zdjęcia'}</h4>
                </div>
                <div className="d-flex align-items-stretch gallery-content-container">
                  {isLoadingImages ? (
                    <Skeleton height={200} />
                  ) : images.length === 0 ? (
                    <p className="para text-left">
                      Jeśli zdjęcia miały zostać dodane, prawdopodobnie nie wszystkie załadowały się w poprzednim kroku.
                      Wróć do poprzedniego kroku i spróbuj ponownie załadować zdjęcia.
                    </p>
                  ) : (
                    <ImageGallery
                      additionalClass="summary-fucha-gallery-container"
                      items={images}
                      showPlayButton={false}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-12 col-md-12">
          <div className="mt-5 single-feature summary-container hover-border1 wow fadeInDown summary-text-container">
            <h4 className="mt-3 mb-0 warning-text text-center">
              Uwaga! <br /> Zanim wystawisz fuchę:
            </h4>
            <div className="summary-text-box mt-4">
              <p style={{ textAlign: 'center', color: '#696969' }} className="mt-2">
                Po kliknięciu <b>'Wystaw Fuchę'</b> nie będzie możliwości jej edycji!
              </p>
            </div>

            <div className="summary-text-box mb-3">
              <p style={{ textAlign: 'center', fontWeight: 'bold', color: '#696969' }}>
                Jeśli chcesz wprowadzić zmiany, skorzystaj z opcji:
              </p>
            </div>

            <div className="summary-text-box-steps">
              <div className="summary-text-box ">
                <p>
                  <i className="bi bi-arrow-right-circle"></i>
                  <b>"Wróć"</b>, aby wrócić do poprzednich kroków i wprowadzić zmiany teraz
                </p>
              </div>
              <div className="summary-text-box mb-4">
                <p>
                  <i className="bi bi-arrow-right-circle"></i>
                  <b>"Dokończ później"</b>, aby zapisać fuchę i wrócić do niej później <span>*</span>
                </p>
              </div>
            </div>
            <div className="summary-text-box mb-3">
              <p style={{ textAlign: 'center' }} className="additional-text">
                *Zapisana fucha będzie dostępna w zakładce <b>"Moje Fuchy"</b> na Twoim profilu. Dzięki temu możesz
                dokończyć ją w dogodnym momencie.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageThree
