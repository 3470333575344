import React, { useEffect, useReducer, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { URL_PATHS } from '../../_constants/urls'
import { useAuth } from '../../context/AuthContext'
import { useDashboardTab } from '../../context/DashboardTabContext'
import { useNotificationsAndMessages } from '../../context/NotificationsAndMessagesContext'
import { tabMapping } from '../page/dashboard/Tabs'
import { useNotificationActions } from '../../_actions'
import Skeleton from 'react-loading-skeleton'
import { ReactComponent as HouseWrench } from '../../assets/images/icons/house-wrench.svg'
import { ReactComponent as MobilePhoneIcon } from '../../assets/images/icons/mobile-phone.svg'
import Badge from '@mui/material/Badge'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
import { createTheme, ThemeProvider } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#000',
      light: '#f5f5f5',
    },
  },
  typography: {
    fontFamily: 'Saira',
  },
})

const Header = () => {
  const [sidebar, setSidebar] = useState(false)
  const { activeTab, setActiveTab } = useDashboardTab()
  const { unreadNotificationCount, unreadMessagesCount, loadingNotificationsAndMessages } =
    useNotificationsAndMessages()
  const { auth, setAuth } = useAuth()
  const path = window.location.pathname

  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener('scroll', isSticky)
    return () => {
      window.removeEventListener('scroll', isSticky)
    }
  })

  /* Method that will fix header after a specific scrollable */
  const isSticky = (e) => {
    const header = document.querySelector('.header-area')

    if (!header) return

    if (path === URL_PATHS.CHAT) {
      header.classList.remove('sticky')
      return
    }

    const scrollTop = window.scrollY
    scrollTop >= 20
      ? header.classList.add('sticky')
      : header.classList
        ? header.classList.remove('sticky')
        : header.classList.add('sticky')
  }

  /*---------menu button event----------*/
  const handleSidbarMenu = () => {
    if (sidebar === false || sidebar === 0) {
      setSidebar(1)
    } else {
      setSidebar(false)
    }
  }

  /*---------add event scroll top----------*/
  const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })

  /*---------Using reducer mange the active or inactive menu----------*/
  const initialState = { activeMenu: '' }
  const [state, dispatch] = useReducer(reducer, initialState)

  function reducer(state, action) {
    switch (action.type) {
      case 'homeOne':
        return { activeMenu: 'homeOne' }
      case 'pages':
        return { activeMenu: 'pages' }
      case 'news':
        return { activeMenu: 'news' }
      case 'brows':
        return { activeMenu: 'brows' }
      case 'itwork':
        return { activeMenu: 'itwork' }
      case 'about':
        return { activeMenu: 'about' }
      case 'contact':
        return { activeMenu: 'contact' }
      default:
        return { activeMenu: '' }
    }
  }

  const authNotUserNav = () => {
    return (
      <div className="nav-right d-flex align-items-center">
        <div className="eg-btn btn--primary header-btn m-3">
          <Link to={URL_PATHS.SIGNUP_CONTRACTOR} onClick={scrollTop}>
            Dołącz jako fachowiec
          </Link>
        </div>
        <div className="eg-btn btn--primary header-btn">
          <Link to={URL_PATHS.LOGIN} onClick={scrollTop}>
            Zaloguj się
          </Link>
        </div>
      </div>
    )
  }
  const authUserNav = () => {
    return (
      <div className="nav-right d-flex align-items-center">
        <div className="eg-btn btn--primary header-btn m-3">
          <Link to={`${URL_PATHS.CHAT}`}>
            <ThemeProvider theme={theme}>
              <Badge
                color="primary"
                badgeContent={
                  loadingNotificationsAndMessages ? null : unreadMessagesCount === 0 ? null : ` ${unreadMessagesCount}`
                }
                max={99}
                overlap="circular"
              >
                <ChatBubbleOutlineIcon />
              </Badge>
            </ThemeProvider>
          </Link>
        </div>
        <div className="eg-btn btn--primary header-btn">
          <Link
            to={`${URL_PATHS.DASHBOARD}`}
            onClick={() => {
              setActiveTab('tab04')
              scrollTop()
            }}
          >
            <ThemeProvider theme={theme}>
              <Badge
                badgeContent={
                  loadingNotificationsAndMessages
                    ? null
                    : unreadNotificationCount === 0
                      ? null
                      : ` ${unreadNotificationCount}`
                }
                color="primary"
                max={99}
                overlap="circular"
              >
                <NotificationsNoneIcon />
              </Badge>
            </ThemeProvider>
          </Link>
        </div>
        <div className="eg-btn btn--primary header-btn m-3">
          {auth?.user.is_contractor ? (
            <Link to={URL_PATHS.FUCHA_LISTING} onClick={scrollTop}>
              Przeglądaj Fuchy
            </Link>
          ) : (
            <Link
              to={`${URL_PATHS.DASHBOARD}`}
              onClick={() => {
                setActiveTab('tab21')
                scrollTop()
              }}
            >
              Wystaw Fuche
            </Link>
          )}
        </div>
        <div className="eg-btn btn--primary header-btn">
          <Link to={URL_PATHS.DASHBOARD} onClick={scrollTop}>
            Mój Profil
          </Link>
        </div>
      </div>
    )
  }

  return (
    <>
      <header className="mobile-header-area">
        <Link to={URL_PATHS.HOME} onClick={scrollTop}>
          <div className="mobile-logo">
            <p className="logo-text">mamfuche.pl</p>
          </div>
        </Link>
        {auth ? (
          <div className="notification-icon-container">
            <Link
              to={`${URL_PATHS.DASHBOARD}`}
              onClick={() => {
                setActiveTab('tab04')
                scrollTop()
              }}
            >
              <ThemeProvider theme={theme}>
                <Badge
                  badgeContent={
                    loadingNotificationsAndMessages
                      ? null
                      : unreadNotificationCount === 0
                        ? null
                        : ` ${unreadNotificationCount}`
                  }
                  color="primary"
                  max={99}
                  overlap="circular"
                >
                  <NotificationsNoneIcon />
                </Badge>
              </ThemeProvider>
            </Link>
          </div>
        ) : null}
      </header>
      <header className="header-area style-1">
        <div className="header-logo">
          <Link to={URL_PATHS.HOME} onClick={scrollTop}>
            <p className="logo-text">mamfuche.pl</p>
          </Link>
        </div>
        <div className={sidebar === 1 ? 'main-menu show-menu' : 'main-menu'}>
          <div className="mobile-logo-area d-lg-none d-flex justify-content-between align-items-center">
            <div className="mobile-logo-wrap ">
              <Link to={'/'}>
                <img alt="logo" src={process.env.PUBLIC_URL + '/images/bg/header-logo.png'} />
              </Link>
            </div>
            <div className="menu-close-btn" onClick={handleSidbarMenu}>
              <i className="bi bi-x-lg" />
            </div>
          </div>
          <ul className="menu-list">
            {!auth?.user.is_contractor && (
              <li onClick={() => dispatch({ type: 'brows' })}>
                <Link
                  to={URL_PATHS.FUCHA_LISTING}
                  onClick={scrollTop}
                  className={`${state.activeMenu === 'brows' ? 'active' : ''} `}
                >
                  Przeglądaj Fuchy
                </Link>
              </li>
            )}

            <li onClick={() => dispatch({ type: 'homeOne' })}>
              <Link to={URL_PATHS.HOME} className={`${state.activeMenu === 'homeOne' ? 'active' : ''} ${'drop-down'}`}>
                Strona Główna
              </Link>
            </li>
            <li onClick={() => dispatch({ type: 'about' })}>
              <Link
                to={URL_PATHS.ABOUT}
                onClick={scrollTop}
                className={`${state.activeMenu === 'about' ? 'active' : ''} `}
              >
                Kim Jesteśmy
              </Link>
            </li>
            <li onClick={() => dispatch({ type: 'itwork' })}>
              <Link
                to={URL_PATHS.HOW_IT_WORKS}
                onClick={scrollTop}
                className={`${state.activeMenu === 'itwork' ? 'active' : ''} `}
              >
                Jak To Działa
              </Link>
            </li>

            {/* <li className="menu-item-has-children" onClick={() => dispatch({ type: 'news' })}>
              <Link to={'#'} className={`${state.activeMenu === 'news' ? 'active' : ''} ${'drop-down'}`}>
                News
              </Link>
              <i className="bx bx-plus dropdown-icon" />
              <ul className={state.activeMenu === 'news' ? 'submenu d-block' : 'submenu d-xl-block d-none'}>
                <li>
                  <NavLink to={URL_PATHS.BLOG} onClick={scrollTop}>
                    Blog
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`${process.env.PUBLIC_URL}/blog-Details`} onClick={scrollTop}>
                    Blog Details
                  </NavLink>
                </li>
              </ul>
            </li> */}
            {/* <li className="menu-item-has-children" onClick={() => dispatch({ type: 'pages' })}>
              <Link to={'#'} className={`${state.activeMenu === 'pages' ? 'active' : ''} ${'drop-down'}`}>
                Pages
              </Link>
              <i className="bx bx-plus dropdown-icon" />
              <ul className={state.activeMenu === 'pages' ? 'submenu d-block' : 'submenu d-xl-block d-none'}>
                <li>
                  <NavLink to={URL_PATHS.FUCHA_DETAILS} onClick={scrollTop}>
                    Auction Details
                  </NavLink>
                </li>
                <li>
                  <NavLink to={URL_PATHS.FAQ} onClick={scrollTop}>
                    Faq
                  </NavLink>
                </li>
                <li>
                  <NavLink to={URL_PATHS.DASHBOARD} onClick={scrollTop}>
                    Dashboard
                  </NavLink>
                </li>
                <li>
                  <NavLink to={URL_PATHS.LOGIN} onClick={scrollTop}>
                    Login
                  </NavLink>
                </li>
                <li>
                  <NavLink to={URL_PATHS.SIGNUP} onClick={scrollTop}>
                    Zarejestruj się
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`${process.env.PUBLIC_URL}/error`} onClick={scrollTop}>
                    404
                  </NavLink>
                </li>
              </ul>
            </li> */}
            <li onClick={() => dispatch({ type: 'contact' })}>
              <Link
                to={URL_PATHS.CONTACT}
                onClick={scrollTop}
                className={`${state.activeMenu === 'contact' ? 'active' : ''} `}
              >
                Kontakt
              </Link>
            </li>
          </ul>
        </div>
        {auth ? authUserNav() : authNotUserNav()}
      </header>
    </>
  )
}

export default Header
