import React from 'react'

function TermsAndConditions() {
  return (
    <div className="row d-flex justify-content-center align-items-center mb-60 g-4 w-100">
      <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6">
        <div className="section-title2 text-start mt-5">
          <h2 className="pl-1r">Regulamin Serwisu mamfuche.pl</h2>
          <h5 className="pl-1r">Data obowiązywania: 2024-01-01</h5>
          <hr />
          <ol className="mt-5">
            <li>
              <strong>Postanowienia ogólne</strong>
              <ol>
                <li>
                  Niniejszy regulamin określa zasady i warunki korzystania z serwisu internetowego Mamfuche.pl,
                  dostępnego pod adresem www.mamfuche.pl.
                </li>
                <li>
                  Serwis Mamfuche.pl jest platformą umożliwiającą Klientom (osobom poszukującym usług) publikowanie
                  zleceń, na które mogą odpowiedzieć Fachowcy (osoby oferujące usługi).
                </li>
                <li>
                  Korzystanie z Serwisu oznacza akceptację niniejszego Regulaminu. W przypadku braku akceptacji
                  któregokolwiek z postanowień Regulaminu, Użytkownik powinien powstrzymać się od korzystania z Serwisu.
                </li>
                <li>
                  Serwis jest zarządzany przez Tobiasz Chodarewicz, z siedzibą na ul. Kajki 10-12 Olsztyn 10-547, NIP
                  5671893536.
                  {/* wpisaną do rejestru
                  przedsiębiorców przez Sąd Rejonowy w [miasto], pod numerem KRS [numer KRS], NIP [numer NIP], REGON
                  [numer REGON]. */}
                </li>
              </ol>
            </li>
            <li>
              <strong>Definicje</strong>
              <ol>
                <li>
                  <strong>Użytkownik</strong> – każda osoba fizyczna posiadająca pełną zdolność do czynności prawnych,
                  osoba prawna lub jednostka organizacyjna nieposiadająca osobowości prawnej, korzystająca z Serwisu.
                </li>
                <li>
                  <strong>Fachowiec</strong> – osoba fizyczna lub prawna, która wykupiła dostęp do Serwisu w celu
                  oferowania swoich usług i składania wycen. Fachowiec posiada publiczny profil dostępny dla wszystkich
                  Użytkowników.
                </li>
                <li>
                  <strong>Klient</strong> – osoba fizyczna lub prawna, poszukująca usług oferowanych przez Fachowców i
                  publikująca zlecenia w Serwisie.
                </li>
                <li>
                  <strong>Fucha</strong> – zlecenie opublikowane przez Klienta w Serwisie, na które Fachowcy mogą
                  składać swoje wyceny.
                </li>
                <li>
                  <strong>Dostęp do Serwisu</strong> – opłata jednorazowa ponoszona przez Fachowca za dostęp do pełnej
                  funkcjonalności Serwisu na określony czas. Po upływie tego okresu Fachowiec musi samodzielnie wykupić
                  dodatkowy czas dostępu, aby dalej korzystać z Serwisu.
                </li>
                <li>
                  <strong>Rejestracja</strong> – procedura, w ramach której Użytkownik podaje swoje dane osobowe oraz
                  kontaktowe w formularzu rejestracyjnym i otrzymuje dostęp do Serwisu za pośrednictwem Konta z
                  odpowiednimi funkcjonalnościami.
                </li>
                <li>
                  <strong>Konto Użytkownika, Konto</strong> – dostępne dla Użytkownika miejsce w Serwisie, za
                  pośrednictwem którego wprowadza on dane, zarządza nimi, składa wyceny lub zlecenia, a także komunikuje
                  się z innymi Użytkownikami.
                </li>
                <li>
                  <strong>Zapytanie</strong> – zapytanie ofertowe złożone przez Klienta celem jego publikacji w Serwisie
                  oraz przesłania przez Administratora w imieniu Klienta potencjalnym Fachowcom.
                </li>
                <li>
                  <strong>Wycena Szacunkowa</strong> – przedstawia orientacyjny koszt realizacji określonego zadania lub
                  usługi. Wycena Szacunkowa ma charakter informacyjny, nie stanowi wiążącej oferty w rozumieniu
                  przepisów prawa cywilnego i może ulec zmianie w przypadku modyfikacji zakresu zlecenia, warunków
                  realizacji, technologii lub innych istotnych czynników wpływających na koszt.
                </li>
                <li>
                  <strong>Odkrycie Danych Klienta</strong> – przekazanie Fachowcowi, który złożył wybraną przez Klienta
                  wycenę, danych kontaktowych Klienta, takich jak numer telefonu, adres e-mail, adres zamieszkania oraz
                  nazwisko.
                </li>
                <li>
                  <strong>Profil</strong> – publicznie dostępna wizytówka Fachowca w Serwisie, zawierająca informacje o
                  jego umiejętnościach, doświadczeniu, portfolio wykonanych prac oraz opinie wystawione przez Klientów.
                </li>
                <li>
                  <strong>Opinia</strong> – ocena wystawiona przez Klienta na temat Fachowca lub przez Fachowca na temat
                  Klienta po realizacji zlecenia, która może być pozytywna, neutralna lub negatywna.
                </li>
                <li>
                  <strong>Polityka Prywatności</strong> – dokument określający zasady przetwarzania danych osobowych
                  Użytkowników Serwisu oraz zasady wykorzystywania plików cookies.
                </li>
                <li>
                  <strong>Administrator</strong> – podmiot zarządzający Serwisem Mamfuche.pl, odpowiedzialny za jego
                  działanie, bezpieczeństwo oraz przestrzeganie postanowień niniejszego Regulaminu.
                </li>
              </ol>
            </li>
            <li>
              <strong>Zasady korzystania z Serwisu</strong>

              <ol>
                <li>
                  Użytkownicy są zobowiązani do korzystania z Serwisu zgodnie z obowiązującym prawem oraz
                  postanowieniami niniejszego Regulaminu.
                </li>
                <li>
                  Fachowiec ma obowiązek wykupienia dostępu do Serwisu na określony czas, aby móc w pełni korzystać z
                  funkcjonalności Serwisu, w tym składania wycen i komunikacji z Klientami.
                </li>
                <li>Klient ma prawo do publikowania zleceń i otrzymywania wycen od Fachowców bezpłatnie.</li>
                <li>
                  Po zakończeniu okresu wykupionego dostępu Fachowiec musi samodzielnie przedłużyć dostęp, aby nadal
                  korzystać z Serwisu.
                </li>
                <li>
                  Użytkownicy są zobowiązani do podawania prawdziwych danych podczas rejestracji oraz korzystania z
                  Serwisu.
                </li>
              </ol>
            </li>
            <li>
              <strong>Ograniczenie odpowiedzialności Serwisu</strong>

              <ol>
                <li>
                  Serwis Mamfuche.pl pełni wyłącznie funkcję platformy umożliwiającej kontakt pomiędzy Klientami a
                  Fachowcami, ułatwiając publikowanie zleceń oraz składanie wycen. Serwis nie bierze udziału w
                  nawiązywaniu, negocjowaniu ani zawieraniu umów pomiędzy Klientami a Fachowcami.
                </li>
                <li>
                  Serwis Mamfuche.pl nie ponosi odpowiedzialności za jakiekolwiek szkody wynikające z zawarcia umowy
                  pomiędzy Klientem a Fachowcem, w tym za jej wykonanie, niewykonanie, nienależyte wykonanie, a także za
                  wszelkie spory, które mogą powstać na tle realizacji zlecenia.
                </li>
                <li>
                  Serwis nie weryfikuje szczegółowo danych dostarczonych przez Użytkowników (Klientów i Fachowców).
                  Użytkownicy są odpowiedzialni za prawdziwość i aktualność podawanych przez siebie danych.
                </li>
                <li>
                  Serwis Mamfuche.pl zaleca, aby Użytkownicy zachowali szczególną ostrożność podczas nawiązywania
                  współpracy, weryfikując dane drugiej strony oraz ustalając szczegółowe warunki współpracy przed
                  przystąpieniem do realizacji zlecenia.
                </li>
                <li>
                  Użytkownicy korzystają z Serwisu na własne ryzyko, a wszelkie decyzje związane z nawiązywaniem
                  współpracy są podejmowane wyłącznie przez nich. Serwis Mamfuche.pl nie ponosi odpowiedzialności za
                  jakość, bezpieczeństwo ani legalność usług oferowanych przez Fachowców oraz za wiarygodność Klientów.
                </li>
              </ol>
            </li>
            <li>
              <strong>Publikacja zleceń i wycen</strong>

              <ol>
                <li>
                  Klient może opublikować zlecenie w Serwisie, wypełniając odpowiedni formularz i podając szczegóły
                  dotyczące zadania.
                </li>
                <li>Fachowcy mogą składać swoje wyceny na opublikowane zlecenia w ramach określonych limitów.</li>
                <li>
                  Klient ma możliwość wyboru jednej z przedstawionych wycen, po czym otrzymuje dane kontaktowe wybranego
                  Fachowca.
                </li>
                <li>
                  Zlecenia są ważne przez określony czas; po tym okresie zlecenie zostaje zamknięte, jeżeli żaden
                  Fachowiec nie zostanie wybrany.
                </li>
              </ol>
            </li>
            <li>
              <strong>Wycena Szacunkowa</strong>

              <ol>
                <li>
                  Klient potwierdza akceptację wyceny szacunkowej za wykonanie danej fuchy. Wycena szacunkowa ma
                  charakter informacyjny i przedstawia orientacyjny koszt realizacji określonego zadania.
                </li>
                <li>
                  Wycena szacunkowa nie jest ofertą w rozumieniu przepisów prawa cywilnego i nie zobowiązuje Fachowca do
                  realizacji zamówienia na przedstawionych w niej warunkach.
                </li>
                <li>
                  Wycena szacunkowa może ulec zmianie w zależności od zmieniających się okoliczności, takich jak zmiany
                  w zakresie projektu, materiałów, technologii lub innych czynników wpływających na koszt realizacji.
                </li>
              </ol>
            </li>
            <li>
              <strong>Limity i zarządzanie aktywnością Użytkowników</strong>

              <ol>
                <li>Na każde ogłoszenie (fuchę) może przypadać maksymalnie 15 aktywnych wycen jednocześnie.</li>
                <li>Fachowiec może uczestniczyć w maksymalnie 20 fuchach jednocześnie.</li>
                <li>
                  Fachowiec ma prawo do jednorazowej korekty wyceny, którą złożył w ramach danego ogłoszenia, pod
                  warunkiem, że klient jeszcze jej nie zaakceptował.
                </li>
                <li>
                  Fachowiec ma prawo do usunięcia swojej wyceny z dowolnego ogłoszenia w dowolnym momencie, z
                  zastrzeżeniem, że usunięcie wyceny nie zwalnia go z odpowiedzialności za wcześniejsze działania w
                  ramach danego ogłoszenia.
                </li>
                <li>
                  Serwis Mamfuche.pl zastrzega sobie prawo do zmiany powyższych limitów w dowolnym momencie, w
                  szczególności w celu dostosowania ich do potrzeb funkcjonowania platformy lub wymagań technicznych.
                </li>
                <li>
                  Zmiana limitów nie wpływa na aktualnie trwające ogłoszenia, w których Fachowiec bierze udział, chyba
                  że zmiana wynika z obowiązujących przepisów prawa lub konieczności zapewnienia bezpieczeństwa
                  funkcjonowania Serwisu.
                </li>
              </ol>
            </li>
            <li>
              <strong>Profil Fachowca</strong>
              <ol>
                <li>
                  Fachowiec posiada profil, który jest publicznie dostępny i może być przeglądany przez innych
                  Użytkowników Serwisu.
                </li>
                <li>
                  Serwis Mamfuche.pl zastrzega sobie prawo do usunięcia lub zablokowania profilu Fachowca, jeżeli
                  zawiera on treści niezgodne z Regulaminem.
                </li>
              </ol>
            </li>
            <li>
              <strong>Opinie i oceny</strong>
              <ol>
                <li>
                  Fachowiec ma możliwość wystawienia opinii Klientowi, a Klient Fachowcowi, wyłącznie w przypadku, gdy
                  wycena Fachowca została wybrana przez Klienta i doszło do realizacji zlecenia.
                </li>
                <li>
                  Opinie są podzielone na kategorie, takie jak jakość wykonania, terminowość, komunikacja i inne. Każda
                  z kategorii jest oceniana w skali od 1 do 5.
                </li>
                <li>
                  Ogólna opinia jest liczona przez algorytm Serwisu na podstawie ocen z poszczególnych kategorii, przy
                  czym różne kategorie mogą mieć różne wagi, co oznacza, że niektóre aspekty mogą mieć większy wpływ na
                  ogólną ocenę.
                </li>
                <li>Opinie powinny być zgodne z rzeczywistością, rzetelne i dotyczyć tylko konkretnego zlecenia.</li>
                <li>
                  Serwis Mamfuche.pl zastrzega sobie prawo do edycji lub usuwania opinii, które są niezgodne z prawdą,
                  obraźliwe, niezgodne z Regulaminem lub noszą znamiona działań oszustwa.
                </li>
                <li>
                  Opinie są widoczne na publicznych profilach Fachowców i mogą być przeglądane przez innych Użytkowników
                  Serwisu.
                </li>
                <li>
                  Użytkownik, który wystawia opinię, ponosi pełną odpowiedzialność za jej treść i jej zgodność z prawem.
                </li>
              </ol>
            </li>
            <li>
              <strong>Regulamin z czatu</strong>
              <ol>
                <li>
                  Serwis Mamfuche.pl udostępnia funkcję czatu, która umożliwia kontakt Fachowca z klientem oraz klienta
                  z Fachowcem w celu uzgodnienia szczegółów dotyczących fuchy.
                </li>
                <li>
                  Korzystanie z czatu jest integralną częścią funkcjonalności Serwisu i podlega postanowieniom
                  niniejszego Regulaminu.
                </li>
                <li>
                  Czat powinien być wykorzystywany wyłącznie do celów związanych z realizacją usług i komunikacją
                  dotyczącą ogłoszeń publikowanych w Serwisie.
                </li>
                <li>
                  Użytkownicy zobowiązani są do zachowania kultury osobistej oraz przestrzegania norm prawnych,
                  etycznych i dobrych obyczajów podczas korzystania z czatu.
                </li>
                <li>
                  Zabrania się publikowania na czacie treści:
                  <ul>
                    <li>obraźliwych, wulgarnych, niezgodnych z dobrymi obyczajami,</li>
                    <li>zawierających spam lub reklamy niezwiązane z ogłoszeniem,</li>
                    <li>
                      naruszających prawa autorskie, prawa do znaków towarowych lub inne prawa własności intelektualnej,
                    </li>
                    <li>wprowadzających w błąd lub mających na celu oszustwo.</li>
                  </ul>
                </li>
                <li>
                  Serwis Mamfuche.pl nie ponosi odpowiedzialności za treści przekazywane za pośrednictwem czatu przez
                  Użytkowników.
                </li>
                <li>
                  Użytkownik ponosi pełną odpowiedzialność za treści zamieszczane w rozmowach na czacie oraz za
                  konsekwencje wynikające z ich publikacji.
                </li>
                <li>
                  Administrator zastrzega sobie prawo do monitorowania rozmów na czacie w celu zapewnienia zgodności z
                  niniejszym Regulaminem.
                </li>
                <li>
                  Serwis Mamfuche.pl ma prawo do usuwania treści naruszających Regulamin oraz blokowania dostępu do
                  czatu Użytkownikom, którzy nie przestrzegają jego zasad.
                </li>
                <li>
                  Serwis Mamfuche.pl nie udostępnia danych osobowych Użytkowników poza komunikacją wymaganą w ramach
                  danego ogłoszenia, zgodnie z Polityką Prywatności.
                </li>
                <li>
                  Klient ma możliwość kontaktu z Fachowcem bez ujawniania danych osobowych, które są udostępniane tylko
                  w przypadku wyboru wyceny Fachowca.
                </li>
                <li>
                  Korzystanie z czatu wymaga dostępu do Internetu oraz kompatybilnego urządzenia z przeglądarką
                  internetową.
                </li>
                <li>
                  Serwis Mamfuche.pl nie gwarantuje nieprzerwanego działania czatu w przypadku awarii technicznych lub
                  przerw konserwacyjnych.
                </li>
              </ol>
            </li>
            <li>
              <strong>Moderacja i odpowiedzialność za treści</strong>
              <ol>
                <li>
                  Użytkownicy są odpowiedzialni za treść publikowanych przez siebie ogłoszeń, wycen oraz innych
                  materiałów zamieszczanych w Serwisie.
                </li>
                <li>
                  Serwis Mamfuche.pl zastrzega sobie prawo do zablokowania lub usunięcia konta Użytkownika, zapytania,
                  wyceny lub innych materiałów, jeżeli naruszają one w jakikolwiek sposób postanowienia niniejszego
                  Regulaminu lub obowiązujące przepisy prawa, w szczególności gdy zawierają treści:
                  <ul>
                    <li>powszechnie uznane za obraźliwe,</li>
                    <li>noszące znamiona czynów nieuczciwej konkurencji,</li>
                    <li>
                      naruszające dobra osobiste, dobre obyczaje, prawa autorskie, prawa ochronne do znaków towarowych
                      lub inne prawa własności intelektualnej,
                    </li>
                    <li>szkodzące dobremu imieniu lub renomie Serwisu, Operatora, Partnera,</li>
                    <li>wprowadzające w błąd.</li>
                  </ul>
                </li>
                <li>
                  Serwis Mamfuche.pl zastrzega sobie również prawo do moderowania, edytowania lub usuwania treści
                  publikowanych przez Użytkowników, które są niezgodne z obowiązującym prawem, dobrymi obyczajami,
                  niniejszym Regulaminem lub mogą naruszać prawa osób trzecich.
                </li>
                <li>
                  Administrator nie ponosi odpowiedzialności za treści zamieszczane przez Użytkowników oraz za jakość,
                  bezpieczeństwo ani legalność oferowanych usług.
                </li>
                <li>
                  Publikowanie treści o charakterze obraźliwym, niezgodnym z prawem, naruszającym dobre obyczaje lub
                  inne nieodpowiednie treści mogą skutkować natychmiastowym zablokowaniem konta Użytkownika i/lub
                  usunięciem treści.
                </li>
              </ol>
            </li>
            <li>
              <strong>Ochrona danych osobowych i Polityka Prywatności</strong>
              <ol>
                <li>
                  Serwis Mamfuche.pl przetwarza dane osobowe Użytkowników zgodnie z obowiązującymi przepisami prawa, w
                  szczególności zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27
                  kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w
                  sprawie swobodnego przepływu takich danych (RODO).
                </li>
                <li>
                  Szczegółowe informacje na temat przetwarzania danych osobowych oraz wykorzystywania plików cookies
                  znajdują się w Polityce Prywatności, dostępnej na stronie Serwisu.
                </li>
                <li>
                  Użytkownik, korzystając z Serwisu, wyraża zgodę na przetwarzanie swoich danych osobowych zgodnie z
                  Polityką Prywatności.
                </li>
              </ol>
            </li>
            <li>
              <strong>Prawa autorskie i własność intelektualna</strong>
              <ol>
                <li>
                  Serwis Mamfuche.pl oraz wszelkie jego elementy, w tym oprogramowanie, teksty, grafika, logo, ikony,
                  obrazy, materiały wideo, dźwięki, bazy danych, treści, a także układ i wybór treści prezentowanych w
                  Serwisu, są chronione prawem autorskim oraz innymi przepisami prawa własności intelektualnej.
                </li>
                <li>
                  Użytkownicy mają prawo korzystać z Serwisu wyłącznie w celach zgodnych z jego przeznaczeniem oraz na
                  własny użytek osobisty.
                </li>
                <li>
                  Zabronione jest kopiowanie, modyfikowanie, dystrybuowanie, transmitowanie, publikowanie,
                  reprodukowanie, przekazywanie, tworzenie prac pochodnych, sprzedaż lub jakiekolwiek inne wykorzystanie
                  treści Serwisu, w całości lub w części, bez wyraźnej pisemnej zgody Administratora.
                </li>
              </ol>
            </li>
            <li>
              <strong>Reklamacje</strong>
              <ol>
                <li>
                  Użytkownik ma prawo zgłaszać reklamacje dotyczące funkcjonowania Serwisu oraz świadczonych usług.
                </li>
                <li>
                  Reklamacje należy zgłaszać na adres e-mail kontakt@mamfuche.pl lub za pośrednictwem formularza
                  kontaktowego dostępnego w Serwisie.
                </li>
                <li>
                  W zgłoszeniu reklamacyjnym Użytkownik powinien podać dane umożliwiające jego identyfikację (imię,
                  nazwisko, adres e-mail) oraz opisać problem będący przedmiotem reklamacji.
                </li>
                <li>
                  Administrator zobowiązuje się rozpatrzyć reklamację w terminie 14 dni od dnia jej otrzymania. O wyniku
                  rozpatrzenia reklamacji Użytkownik zostanie poinformowany drogą elektroniczną.
                </li>
                <li>
                  Reklamacje dotyczące jakości usług świadczonych przez Fachowców powinny być kierowane bezpośrednio do
                  Fachowców. Serwis Mamfuche.pl nie ponosi odpowiedzialności za jakość usług świadczonych przez
                  Fachowców.
                </li>
              </ol>
            </li>
            <li>
              <strong>Zmiany Regulaminu</strong>
              <ol>
                <li>
                  Administrator zastrzega sobie prawo do dokonywania zmian niniejszego Regulaminu z ważnych przyczyn, w
                  tym w przypadku zmian w przepisach prawa, zmian technologicznych, dodania nowych usług, zmiany
                  warunków technicznych lub organizacyjnych.
                </li>
                <li>Zmieniony Regulamin będzie udostępniony Użytkownikom na stronie Serwisu.</li>
                <li>
                  Zmieniony Regulamin wchodzi w życie po upływie 15 dni od dnia jego publikacji, chyba że zmiana
                  Regulaminu wynika z obowiązku dostosowania do przepisów prawa, które wchodzą w życie wcześniej.
                </li>
                <li>
                  Użytkownik, który nie akceptuje zmian Regulaminu, powinien zaprzestać korzystania z Serwisu i może
                  usunąć swoje Konto.
                </li>
              </ol>
            </li>
            <li>
              <strong>Postanowienia końcowe</strong>
              <ol>
                <li>
                  W sprawach nieuregulowanych niniejszym Regulaminem mają zastosowanie powszechnie obowiązujące przepisy
                  prawa polskiego, w szczególności Kodeksu cywilnego oraz ustawy o świadczeniu usług drogą
                  elektroniczną.
                </li>
                <li>
                  Wszelkie spory wynikłe na tle niniejszego Regulaminu będą rozstrzygane przez sądy powszechne właściwe
                  dla siedziby Administratora.
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </div>
  )
}

export default TermsAndConditions
