import React from 'react'
// import Swiper core and required modules
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, Navigation } from 'swiper'
import TestimonialSlider2 from '../../common/TestimonialSlider'

SwiperCore.use([Navigation, Autoplay])

const AboutTestimonial = () => {
  const testimonials = [
    {
      id: 1,
      name: 'Aleksandra K.',
      text: 'Korzystałam z usług tej platformy już kilka razy i zawsze byłam zadowolona. Fachowcy są rzetelni i profesjonalni. Polecam wszystkim!',
      image: '/images/bg/testi1.png',
    },
    {
      id: 2,
      name: 'Michał P.',
      text: 'Szybko znalazłem fachowca do naprawy hydrauliki. Usługa wykonana sprawnie i solidnie. Na pewno skorzystam ponownie.',
      image: '/images/bg/testi2.png',
    },
    {
      id: 3,
      name: 'Ewa L.',
      text: 'Świetna strona! Znalezienie kogoś do remontu kuchni zajęło mi tylko kilka minut. Wszystko poszło gładko i bez problemów.',
      image: '/images/bg/testi3.png',
    },
  ]
  return (
    <>
      <TestimonialSlider2
        testimonials={testimonials}
        header="Opinie Naszych Użytkowników"
        text="Opinie o naszej platformie są dla nas niezwykle ważne! Dzięki nim możemy stale podnosić jakość naszych usług i
              dostarczać najlepsze rozwiązania."
      />
    </>
  )
}

export default AboutTestimonial
