import React, { useState, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Link } from 'react-router-dom'
import { useNotificationActions } from '../../../_actions'
import { useNotificationsAndMessages } from '../../../context/NotificationsAndMessagesContext'
import { DYNAMIC_URL_PATHS } from '../../../_constants'
import { ReactComponent as AccountIcon } from '../../../assets/images/icons/account.svg'
import { ReactComponent as BiddingIcon } from '../../../assets/images/icons/man-bidding.svg'
import { ReactComponent as MessageIcon } from '../../../assets/images/icons/message.svg'
import { ReactComponent as PaymentIcon } from '../../../assets/images/icons/payment.svg'
import { ReactComponent as FuchaIcon } from '../../../assets/images/icons/renovation-house.svg'
import ReactPaginate from 'react-paginate'
import { getPageCount, getToPageNumber, getFromPageNumber } from '../../../_helpers'

const getNotificationIcon = (notification) => {
  switch (notification.type) {
    case 'ACCOUNT':
      return <AccountIcon width={30} height={30} />
    case 'BID':
      return <BiddingIcon width={30} height={30} />
    case 'MESSAGE':
      return <MessageIcon width={30} height={30} />
    case 'PAYMENT':
      return <PaymentIcon width={30} height={30} />
    default:
      return <FuchaIcon width={30} height={30} />
  }
}

const ContentOfNotifications = () => {
  const { refreshNotificationCount } = useNotificationsAndMessages()
  const { getUnreadNotifications, markRead } = useNotificationActions()
  const [unreadNotifications, setUnreadNotifications] = useState([])
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const perPage = 10

  const handlePageClick = (e) => {
    setPage(e.selected + 1)
  }

  const renderNotificationsSkeleton = () => {
    return Array.from({ length: 4 }).map((_, index) => <Skeleton height={100} key={index} className={'mb-20'} />)
  }

  const renderNotificationCountSkeleton = () => {
    return (
      <div className="table-pagination-skeleton">
        <p>
          <Skeleton height={40} width={200} className={'mb-10'} />
        </p>
      </div>
    )
  }

  useEffect(() => {
    getUnreadNotifications(page)
      .then(({ notificationsData, count }) => {
        setUnreadNotifications(notificationsData)
        setCount(count)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [page])

  const handleCloseNotification = (id) => {
    const newUnreadNotifications = unreadNotifications.filter((notification) => notification.id !== id)
    setUnreadNotifications(newUnreadNotifications)
    markRead(id).catch((err) => {
      console.log(err)
    })
    refreshNotificationCount().catch((err) => {
      console.log(err)
    })
  }

  return (
    <>
      <div className="row notification-list-area mb-4 w-100">
        {loading ? (
          renderNotificationsSkeleton()
        ) : unreadNotifications.length > 0 ? (
          unreadNotifications.map((notification) => (
            <div key={notification.id} className="notification-list-item w-100">
              <div className="icon-area">{getNotificationIcon(notification)}</div>
              <div className=" message-area">
                <div className="message-content">
                  <h6>{notification.title}</h6>
                  <p>{notification.message}</p>
                  {notification.fucha_id && (
                    <Link
                      to={DYNAMIC_URL_PATHS.FuchaDetail(notification.fucha_id)}
                      onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                    >
                      Zobacz Fuchę
                    </Link>
                  )}
                </div>
              </div>
              <div className="message-time">
                <p>{notification.timesince}</p>
              </div>
              <div className="notification-close-container">
                <div
                  className="notification-close"
                  onClick={() => {
                    handleCloseNotification(notification.id)
                  }}
                >
                  <i className="fa fa-times" aria-hidden="true"></i>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="no-notifications w-100">
            <p className="para">Brak nowych powiadomień</p>
          </div>
        )}
      </div>
      <div className="table-pagination">
        {loading ? (
          renderNotificationCountSkeleton()
        ) : (
          <p>
            <b>Liczba Powiadomień: {count}</b> <br />
            {count > 0 &&
              `Wyświelasz od ${getFromPageNumber(page, perPage)} do ${getToPageNumber(page, perPage, count)}`}
          </p>
        )}
        <nav className="pagination-wrap">
          <ul className="pagination style-two d-flex justify-content-center gap-md-3 gap-2">
            <ReactPaginate
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={getPageCount(count, perPage)}
              previousLabel="<"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
              forcePage={page > 0 ? page - 1 : 0}
            />
          </ul>
        </nav>
      </div>
    </>
  )
}

export default ContentOfNotifications
