import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAuth } from '../../../context/AuthContext'
import { useAlerts } from '../../../context/AlertContext'
import { Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { URL_PATHS } from '../../../_constants/urls'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { PasswordUpdateSchema } from './schema'
import { useUserActions } from '../../../_actions'
import { scrollToTop, history } from '../../../_helpers'

const PasswordUpdate = () => {
  let userAction = useUserActions()
  const { token } = useParams()
  const { auth } = useAuth()
  const [sent, setSent] = useState(false)
  const { addAlert } = useAlerts()
  const [openEye, setOpenEye] = useState(false)

  const handlePasswordEyeIcon = () => {
    if (openEye === false || openEye === 0) {
      setOpenEye(1)
    } else {
      setOpenEye(false)
    }
  }
  const handleConfirmPasswordEyeIcon = () => {
    if (openEye === false || openEye === 0) {
      setOpenEye(2)
    } else {
      setOpenEye(false)
    }
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    getValues,
    reset,
  } = useForm({
    resolver: zodResolver(PasswordUpdateSchema),
  })

  const handlePasswordUpdate = (data) => {
    setSent(true)
    data.token = token
    console.log(data)
    userAction
      .passwordUpdate(data)
      .then((response) => {
        addAlert('Hasło zostało zaktualizowane pomyślnie. Możesz się teraz zalogować.', 'success')
        scrollToTop()
        history.push(URL_PATHS.LOGIN)
      })
      .catch((error) => {
        if (error?.response?.data.display === true) {
          addAlert(error?.response?.data.detail, 'error')
        } else {
          addAlert('Wystąpił błąd podczas wysyłania wiadomości e-mail.', 'error')
        }
      })
    reset()
  }

  if (auth) {
    scrollToTop()
    return <Redirect to={URL_PATHS.DASHBOARD} />
  }

  return (
    <>
      <div className="login-section">
        <img alt="imges" src={process.env.PUBLIC_URL + '/images/bg/client-bg.svg'} className="img-fluid section-bg" />
        <img
          alt="imges"
          src={process.env.PUBLIC_URL + '/images/bg/client-bg-small.svg'}
          className="img-fluid section-bg-small"
        />
        <div className="container">
          <div className="row d-flex justify-content-center g-4">
            <div className="col-xl-6 col-lg-8 col-md-10">
              <div className="form-wrapper wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".2s">
                <div className="form-title reset-form-title">
                  <h3>Utwórz nowe hasło</h3>
                  <p className="login-text reset-text">
                    Wprowadź nowe hasło. Po jego zatwierdzeniu możliwe będzie zalogowanie się przy użyciu nowego hasła.
                  </p>
                </div>

                {/* ----------- FORM START ------------- */}
                <form className="w-100 login-form" onSubmit={handleSubmit(handlePasswordUpdate)}>
                  <div className="row">
                    {/* ----------- Password -----------*/}
                    <div className="col-md-12">
                      <div className="form-inner mb-0">
                        <label>Hasło *</label>
                        <input
                          {...register('password')}
                          type={openEye === 1 ? 'text' : 'password'}
                          name="password"
                          id="password"
                          placeholder="Hasło"
                        />
                        <i
                          className={openEye === 1 ? 'bi bi-eye-slash bi-eye' : 'bi bi-eye-slash'}
                          onClick={handlePasswordEyeIcon}
                          id="togglePassword"
                        />
                      </div>
                      <div className="password-error">
                        {errors.password && <div className="error-message">{errors.password.message}</div>}
                      </div>
                    </div>

                    {/* ----------- Confirm Password ----------- */}
                    <div className="col-md-12">
                      <div className="form-inner mb-0">
                        <label>Powtórz Hasło *</label>
                        <input
                          {...register('confirmPassword')}
                          type={openEye === 2 ? 'text' : 'password'}
                          name="confirmPassword"
                          id="confirmPassword"
                          placeholder="Powtórz Hasło"
                        />
                        <i
                          className={openEye === 2 ? 'bi bi-eye-slash bi-eye' : 'bi bi-eye-slash'}
                          onClick={handleConfirmPasswordEyeIcon}
                          id="toggleConfirmPassword"
                        />
                      </div>
                      <div className="password-error">
                        {errors.confirmPassword && (
                          <div className="error-message">{errors.confirmPassword.message}</div>
                        )}
                      </div>
                    </div>
                    <div className="lg-col-12">
                      <div className="reset-pass-container form-inner d-flex justify-content-between flex-wrap mt-3 mb-3">
                        <Link to={URL_PATHS.LOGIN} onClick={() => scrollToTop()} className="reset-pass">
                          Zaloguj się
                        </Link>
                      </div>
                    </div>
                  </div>
                  <button className="account-btn">Zapisz hasło</button>
                </form>

                {/* ----------- FORM END ------------- */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PasswordUpdate
