import React from 'react'
import PulseLoader from 'react-spinners/PulseLoader'

const LoadingIndicator = () => (
  <section className="chat-section">
    <div className="chat-container d-flex align-items-center justify-content-center">
      <PulseLoader color="#f9395f" />
    </div>
    <div className="chat-footer"></div>
  </section>
)

export default LoadingIndicator
