import React, { createElement } from 'react'
import PulseLoader from 'react-spinners/PulseLoader'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

const Form = ({
  buttonLabel = '',
  children,
  defaultValues,
  disabled,
  handleSubmit,
  isSubmitting,
  onSubmit,
  register,
  tooltip,
  tooltipKey,
  tooltipText,
  ...rest
}) => {
  return (
    <form onSubmit={handleSubmit(onSubmit)} {...rest}>
      <div className="row">
        {Array.isArray(children)
          ? children.map((child) => {
              return child.props.name
                ? createElement(child.type, {
                    ...{
                      ...child.props,
                      register,
                      key: child.props.name,
                    },
                  })
                : child
            })
          : children}
        <div className="col-12">
          <div className="button-group">
            {tooltip ? (
              <OverlayTrigger
                key={tooltipKey}
                placement="top"
                overlay={<Tooltip id={tooltipKey}>{tooltipText}</Tooltip>}
              >
                <button
                  id={tooltipKey}
                  type="submit"
                  className={`eg-btn profile-btn spinner-white-bg ${disabled ? 'disabled-button' : ''}`}
                  disabled={disabled}
                >
                  {!isSubmitting ? buttonLabel : <PulseLoader color="white" loading={isSubmitting} size={10} />}
                </button>
              </OverlayTrigger>
            ) : (
              <button
                type="submit"
                className={`eg-btn profile-btn spinner-white-bg ${disabled ? 'disabled-button' : ''}`}
                disabled={disabled}
              >
                {!isSubmitting ? buttonLabel : <PulseLoader color="white" loading={isSubmitting} size={10} />}
              </button>
            )}
          </div>
        </div>
      </div>
    </form>
  )
}

export default Form
