import React from 'react'

function PrivacyPolicyContent() {
  return (
    <div className="row d-flex justify-content-center align-items-center mb-60 g-4 w-100">
      <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6">
        <div className="section-title2 text-start mt-5">
          <h2 className="pl-1r">Polityka Prywatności mamfuche.pl</h2>
          <h5 className="pl-1r">Data ostatniej aktualizacji: 2024-01-01</h5>
        </div>

        <hr />

        <div className="container">
          <div className="mt-5">
            <h2>1. Wprowadzenie</h2>
            <p>
              Niniejsza Polityka Prywatności określa zasady przetwarzania i ochrony danych osobowych Użytkowników
              korzystających z serwisu mamafuche.pl, dostępnego pod adresem{' '}
              <a href="https://mamafuche.pl">https://mamafuche.pl</a>.
            </p>
          </div>
          <div>
            <h2>2. Administrator Danych Osobowych</h2>
            <p>
              Administratorem danych osobowych jest Tobiasz Chodarewicz z siedzibą na ul. Kajki 10-12, Olsztyn 10-547.
            </p>
          </div>

          <div>
            <h2>3. Zakres Zbieranych Danych</h2>
            <p>Podczas korzystania z serwisu możemy zbierać następujące dane osobowe:</p>
            <ul>
              <li>Imię i nazwisko</li>
              <li>Adres e-mail</li>
              <li>Numer telefonu</li>
              <li>Adres zamieszkania</li>
              <li>
                Dane firmy (np. nazwa firmy, adres siedziby, NIP, REGON) — jeśli Użytkownik prowadzi działalność
                gospodarczą i zdecyduje się je podać
              </li>
              <li>Treści ogłoszeń dodawanych przez Użytkowników, w tym zdjęcia i opisy</li>
              <li>Wiadomości przesyłane za pośrednictwem czatu między Użytkownikami</li>
              <li>Adres IP</li>
              <li>Informacje zbierane przez pliki cookies lub podobne technologie</li>
            </ul>
          </div>

          <div>
            <h2>4. Cel Przetwarzania Danych</h2>
            <p>Dane osobowe Użytkowników są przetwarzane w celu:</p>
            <ul>
              <li>
                Świadczenia usług dostępnych w serwisie, w tym publikacji ogłoszeń i umożliwienia komunikacji między
                Użytkownikami
              </li>
              <li>Obsługi zapytań i zgłoszeń Użytkowników</li>
              <li>Publikacji treści dodawanych przez Użytkowników (ogłoszenia, zdjęcia)</li>
              <li>Umożliwienia korzystania z funkcjonalności czatu</li>
              <li>Zapobiegania nadużyciom i zapewnienia bezpieczeństwa w serwisie</li>
              <li>Dostosowania treści serwisu do preferencji Użytkownika</li>
              <li>Prowadzenia działań marketingowych i promocyjnych</li>
              <li>Realizacji obowiązków wynikających z przepisów prawa</li>
            </ul>
          </div>

          <div>
            <h2>5. Podstawa Prawna Przetwarzania</h2>
            <p>Przetwarzanie danych odbywa się na podstawie:</p>
            <ul>
              <li>
                Zgody Użytkownika (<em>art. 6 ust. 1 lit. a RODO</em>) — w zakresie danych podawanych dobrowolnie
              </li>
              <li>
                Niezbędności do wykonania umowy (<em>art. 6 ust. 1 lit. b RODO</em>) — świadczenie usług serwisu
              </li>
              <li>
                Prawnie uzasadnionego interesu Administratora (<em>art. 6 ust. 1 lit. f RODO</em>) — zapewnienie
                bezpieczeństwa serwisu, marketing bezpośredni
              </li>
              <li>
                Obowiązków prawnych ciążących na Administratorze (<em>art. 6 ust. 1 lit. c RODO</em>)
              </li>
            </ul>
          </div>

          <div>
            <h2>6. Udostępnianie Danych</h2>
            <p>
              Dane osobowe mogą być udostępniane podmiotom trzecim wyłącznie w zakresie dozwolonym przez prawo, w
              szczególności:
            </p>
            <ul>
              <li>Podmiotom współpracującym przy realizacji usług (np. dostawcy hostingu, narzędzi analitycznych)</li>
              <li>
                Innym Użytkownikom serwisu — w zakresie niezbędnym do realizacji funkcjonalności serwisu (np. dane
                kontaktowe w ogłoszeniach)
              </li>
              <li>Organom publicznym uprawnionym do ich otrzymania na podstawie przepisów prawa</li>
            </ul>
          </div>

          <div>
            <h2>7. Prawa Użytkownika</h2>
            <p>Użytkownik ma prawo do:</p>
            <ul>
              <li>Dostępu do swoich danych osobowych</li>
              <li>Sprostowania nieprawidłowych danych</li>
              <li>Usunięcia danych ("prawo do bycia zapomnianym")</li>
              <li>Ograniczenia przetwarzania danych</li>
              <li>Przenoszenia danych</li>
              <li>Wniesienia sprzeciwu wobec przetwarzania</li>
              <li>Wycofania zgody na przetwarzanie danych</li>
            </ul>
            <p>
              W celu realizacji powyższych praw prosimy o kontakt pod adresem:{' '}
              <a href="mailto:kontakt@mamafuche.pl">kontakt@mamafuche.pl</a>.
            </p>
          </div>

          <div>
            <h2>8. Pliki Cookies</h2>
            <p>Serwis korzysta z plików cookies w celu:</p>
            <ul>
              <li>Dostosowania zawartości serwisu do preferencji Użytkownika</li>
              <li>Ułatwienia korzystania z serwisu</li>
              <li>Tworzenia statystyk pomagających zrozumieć, w jaki sposób Użytkownicy korzystają z serwisu</li>
            </ul>
            <p>
              Użytkownik może w każdej chwili zmienić ustawienia dotyczące plików cookies w swojej przeglądarce
              internetowej.
            </p>
          </div>

          <div>
            <h2>9. Bezpieczeństwo Danych</h2>
            <p>
              Administrator stosuje odpowiednie środki techniczne i organizacyjne w celu zabezpieczenia danych osobowych
              przed nieuprawnionym dostępem, utratą czy uszkodzeniem.
            </p>
          </div>

          <div>
            <h2>10. Zmiany w Polityce Prywatności</h2>
            <p>
              Administrator zastrzega sobie prawo do wprowadzania zmian w Polityce Prywatności. Wszelkie zmiany będą
              publikowane na tej stronie i wchodzą w życie z dniem publikacji.
            </p>
          </div>

          <div>
            <h2>11. Kontakt</h2>
            <p>W razie pytań lub wątpliwości dotyczących Polityki Prywatności prosimy o kontakt:</p>
            <ul>
              <li>
                Adres e-mail: <a href="mailto:kontakt@mamafuche.pl">kontakt@mamafuche.pl</a>
              </li>
              <li>Adres korespondencyjny: ul. Kajki 10-12, Olsztyn 10-547</li>
            </ul>
          </div>
        </div>

        <hr />
      </div>
    </div>
  )
}

export default PrivacyPolicyContent
