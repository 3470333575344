import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { DeleteAccountSchema } from './schemas/deleteAccount'
import Form from '../../../common/Form'
import Input from '../../../common/Input'
import { useApi } from '../../../../_helpers'
import { useForm, watch, Controller } from 'react-hook-form'
import { useUserActions } from '../../../../_actions/user.actions'
import { useAlerts } from '../../../../context/AlertContext'
import { zodResolver } from '@hookform/resolvers/zod'
import ConfirmModal from '../../../common/ConfirmModal'

const DeleteAccountForm = ({ user }) => {
  const [openEye, setOpenEye] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const { deleteAccount, logout } = useUserActions()
  const { addAlert } = useAlerts()

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    control,
    watch,
    setValue,
    resetField,
  } = useForm({
    resolver: zodResolver(DeleteAccountSchema),
  })

  const handlePasswordEyeIcon = () => {
    setOpenEye(!openEye)
  }

  const handleCloseModal = () => {
    setShowModal(false)
    resetField('password')
  }

  const handleConfirmModal = (setIsConfirmingModal) => {
    setShowModal(true)
    deleteAccount({ password: watch('password') })
      .then(() => {
        addAlert('- Twoje konto zostało usunięte pomyślnie', 'info')
        setIsConfirmingModal(false)
        logout()
      })
      .catch((error) => {
        addAlert('Wystąpił błąd podczas usuwania konta. Spróbuj ponownie później.', 'error')
        resetField('password')
        setShowModal(false)
        setIsConfirmingModal(false)
      })
  }

  return (
    <>
      <Form
        buttonLabel="Usuń Konto"
        register={register}
        handleSubmit={handleSubmit}
        onSubmit={() => setShowModal(true)}
        className="eg-btn profile-btn col-12"
        isSubmitting={isSubmitting}
      >
        <div className="form-section-title">
          <p>Usuń Konto</p>
        </div>
        <div className="col-12 col-lg-12 text-start w-100">
          <div className="form-inner">
            <label>Hasło *</label>
            <input
              {...register('password')}
              type={openEye ? 'text' : 'password'}
              name="password"
              id="password"
              placeholder="Hasło"
            />
            <i
              style={{ cursor: 'pointer' }}
              className={openEye ? 'bi bi-eye-slash bi-eye' : 'bi bi-eye-slash'}
              onClick={handlePasswordEyeIcon}
              id="togglePassword"
            />
          </div>
          <div className="password-error">
            {errors.password && <div className="error-message">{errors.password.message}</div>}
          </div>
        </div>
      </Form>

      <ConfirmModal
        header="Czy na pewno chcesz usunąć swoje konto?"
        showModal={showModal}
        handleClose={handleCloseModal}
        handleConfirm={handleConfirmModal}
        size="lg"
        className="bid-modal"
      >
        <>
          <div className="text-center">
            <p className="para">
              Usunięcie konta jest nieodwracalne. Po wykonaniu tej operacji stracisz dostęp do swojego profilu oraz
              wszystkich powiązanych danych, w tym do fuch, wycen i czatu. Czy na pewno chcesz usunąć swoje konto?
            </p>
          </div>
        </>
      </ConfirmModal>
    </>
  )
}
export default DeleteAccountForm

DeleteAccountForm.propsTypes = {
  user: PropTypes.object.isRequired,
}
