import React, { useState, useEffect } from 'react'
import PulseLoader from 'react-spinners/PulseLoader'
import { URL_PATHS } from '../../../_constants'
import { Link, Redirect } from 'react-router-dom'

const SignupForm = (props) => {
  const { register, handleSubmit, errors, onSubmit, isSubmitting } = props
  const [openEye, setOpenEye] = useState(false)

  const handlePasswordEyeIcon = () => {
    if (openEye === false || openEye === 0) {
      setOpenEye(1)
    } else {
      setOpenEye(false)
    }
  }

  const handleConfirmPasswordEyeIcon = () => {
    if (openEye === false || openEye === 0) {
      setOpenEye(2)
    } else {
      setOpenEye(false)
    }
  }
  return (
    <>
      {/* ----------- FORM START ------------- */}

      <form onSubmit={handleSubmit(onSubmit)} className="w-100 registration-form">
        <div className="row">
          {/* ----------- First Name ------------- */}
          <div className="col-md-6">
            <div className="form-inner">
              <label>Imię *</label>
              <input {...register('firstName')} type="text" placeholder="Imię" />
              {errors.firstName && <div className="error-message">{errors.firstName.message}</div>}
            </div>
          </div>

          {/*----------- Last Name ----------- */}
          <div className="col-md-6">
            <div className="form-inner">
              <label>Nazwisko *</label>
              <input {...register('lastName')} type="text" placeholder="Nazwisko" />
              {errors.lastName && <div className="error-message">{errors.lastName.message}</div>}
            </div>
          </div>

          {/* ----------- Email ----------- */}
          <div className="col-md-12">
            <div className="form-inner">
              <label>Email *</label>
              <input {...register('email')} type="email" placeholder="Email" />
              {errors.email && <div className="error-message">{errors.email.message}</div>}
            </div>
          </div>

          {/* ----------- Phone Number ----------- */}
          <div className="col-md-12">
            <div className="form-inner">
              <label>Numer Telefonu *</label>
              <input {...register('phoneNumber')} type="text" placeholder="Numer Telefonu" />
              {errors.phoneNumber && <div className="error-message">{errors.phoneNumber.message}</div>}
            </div>
          </div>

          {/* ----------- Password -----------*/}
          <div className="col-md-12">
            <div className="form-inner">
              <label>Hasło *</label>
              <input
                {...register('password')}
                type={openEye === 1 ? 'text' : 'password'}
                name="password"
                id="password"
                placeholder="Hasło"
              />
              <i
                className={openEye === 1 ? 'bi bi-eye-slash bi-eye' : 'bi bi-eye-slash'}
                onClick={handlePasswordEyeIcon}
                id="togglePassword"
              />
            </div>
            <div style={{ marginTop: '-20px' }} className="password-error">
              {errors.password && <div className="error-message">{errors.password.message}</div>}
            </div>
          </div>

          {/* ----------- Confirm Password ----------- */}
          <div className="col-md-12">
            <div className="form-inner">
              <label>Powtórz Hasło *</label>
              <input
                {...register('confirmPassword')}
                type={openEye === 2 ? 'text' : 'password'}
                name="confirmPassword"
                id="confirmPassword"
                placeholder="Powtórz Hasło"
              />
              <i
                className={openEye === 2 ? 'bi bi-eye-slash bi-eye' : 'bi bi-eye-slash'}
                onClick={handleConfirmPasswordEyeIcon}
                id="toggleConfirmPassword"
              />
            </div>
            <div style={{ marginTop: '-20px' }} className="password-error">
              {errors.confirmPassword && <div className="error-message">{errors.confirmPassword.message}</div>}
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-agreement form-inner d-flex justify-content-between flex-wrap mb-0">
              <div className="form-group">
                <input {...register('termsAndConditions')} type="checkbox" id="termsAndConditions" />
                <label htmlFor="termsAndConditions">
                  <span className="color-primary">*</span>
                  <span>Akceptuję </span>
                  <u>
                    <Link to={URL_PATHS.TERMS_AND_CONDITIONS} target="_blank">
                      Regulamin
                    </Link>
                  </u>
                  <span>
                    {' '}
                    oraz{' '}
                    <u>
                      <Link to={URL_PATHS.PRIVACY_POLICY} target="_blank">
                        Politykę Prywatności
                      </Link>
                    </u>{' '}
                    platformy mamfuche.pl
                  </span>
                </label>
                {errors.termsAndConditions && (
                  <div style={{ marginTop: '-8px' }} className="error-message">
                    {errors.termsAndConditions.message}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-agreement form-inner d-flex justify-content-between flex-wrap mb-0 mt-0">
              <div className="form-group">
                <input {...register('marketingComms')} type="checkbox" id="marketingComms" />
                <label htmlFor="marketingComms">
                  <span>
                    Chcę otrzymywać od mamfuche.pl oferty specjalne oraz inne treści marketingowe dopasowane do mnie.
                    Zgodę możesz wycofać w dowolnym momencie.
                  </span>
                </label>
                {errors.marketingComms && <div className="error-message">{errors.marketingComms.message}</div>}
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-agreement form-inner d-flex justify-content-between flex-wrap mb-0 mt-0">
              <div className="form-group">
                <input {...register('externalMarketingComms')} type="checkbox" id="externalMarketingComms" />
                <label htmlFor="externalMarketingComms">
                  <span>
                    Chcę otrzymywać od podmiotów współpracujących z mamfuche.pl oferty specjalne oraz inne treści
                    marketingowe dopasowane do mnie. Zgodę możesz wycofać w dowolnym momencie.
                  </span>
                </label>
                {errors.externalMarketingComms && (
                  <div className="error-message">{errors.externalMarketingComms.message}</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <button className="account-btn spinner-white-bg" disabled={isSubmitting}>
          {!isSubmitting ? 'Stwórz konto' : <PulseLoader color="white" loading={isSubmitting} size={10} />}
        </button>
      </form>

      {/* ----------- FORM END ------------- */}
    </>
  )
}

export default SignupForm
