import React from 'react'
import { Link } from 'react-router-dom'
import { URL_PATHS } from '../../../_constants/urls'
import { scrollToTop } from '../../../_helpers'

const TemporaryBanner = () => {
  return (
    <>
      <div className="testimonial-section pt-80 pb-80">
        <img
          alt="testimonialImage"
          src={process.env.PUBLIC_URL + '/images/bg/client-right.png'}
          className="client-right-vector"
        />
        <img
          alt="testimonialImage"
          src={process.env.PUBLIC_URL + '/images/bg/client-left.png'}
          className="client-left-vector"
        />
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-sm-12 col-md-10 col-lg-8 col-xl-8">
              <div className="section-title1">
                <h2 style={{ marginTop: '30px', marginBottom: '30px' }}>ZAUFAŁO NAM JUŻ 200 FACHOWCÓW! DZIĘKUJEMY!</h2>
                <p
                  style={{ marginTop: '-20px', marginLeft: 'auto', marginRight: 'auto', maxWidth: '500px' }}
                  className="para"
                >
                  Cieszymy się, że poprzednia promocja – darmowe konto na rok – spotkała się z tak dużym zainteresowaniem!
                </p>
                <p className="para"><b>Mamy dla was kolejną świetną okazję!</b></p>
                <h2>Teraz oferujemy 9 miesięcy DARMOWEGO KONTA dla kolejnych 100 fachowców!*</h2>
                <h4 style={{ marginTop: '30px', marginBottom: '30px' }}>
                  Jesteś fachowcem? <br /> Zarejestruj konto już dziś!
                </h4>
                <p
                  style={{
                    marginTop: '30px',
                    marginBottom: '30px',
                    maxWidth: '700px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                  className="para"
                >
                  Jesteśmy właśnie na etapie budowania bazy fachowców, więc to świetna okazja aby zgarnąć darmowe konto
                  na 9 miesięcy i mieć czas na zapoznanie się z naszą platformą!
                </p>
                <Link to={URL_PATHS.SIGNUP_CONTRACTOR}>
                  <button
                    style={{
                      marginTop: '40px',
                      marginBottom: '40px',
                      paddingTop: '9px',
                      paddingBottom: '9px',
                      paddingLeft: '20px',
                      paddingRight: '20px',
                      width: '250px',
                    }}
                    className="eg-btn btn--primary"
                    onClick={() => scrollToTop()}
                  >
                    Dołącz jako fachowiec
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <p style={{ fontSize: '12px', textAlign: 'center', zIndex: '99999', position: 'relative' }} className="para">
            *Promocja dotyczy kolejnych 100 zarejestrowanych fachowców.
            Oferta obejmuje darmowe konto przez 9 miesięcy od daty rejestracji.
          </p>
        </div>
      </div>
    </>
  )
}

export default TemporaryBanner
