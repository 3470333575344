import React from 'react'
import OtpInput from 'react-otp-input'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import PulseLoader from 'react-spinners/PulseLoader'

function PhoneVerificationModal({
  showModal,
  handleCloseModal,
  handleConfirmModal,
  otp,
  setOtp,
  newOtpCallback,
  modalError,
  isConfirming,
}) {
  return (
    <Modal size={'lg'} show={showModal} onHide={handleCloseModal} centered={true}>
      <Modal.Header closeButton className="sidebar-widget-title">
        <Modal.Title>
          Zweryfikuj swój numer telefonu
          <span />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          <div className="">
            <OtpInput
              containerStyle="otp-field mb-4"
              value={otp}
              onChange={setOtp}
              numInputs={6}
              inputType="number"
              renderInput={(props) => <input type="number" {...props} />}
            />
            <p className="para text-center">
              <b>Kod został wysłany na podany numer telefonu</b> i będzie ważny przez <b>2 minuty</b>. Jeśli nie
              otrzymasz kodu, możesz spróbować ponownie po upływie tego czasu.
            </p>
            <p className="text-center">
              <span className="text-danger">{modalError}</span>
            </p>
          </div>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="dark" className="eg-btn btn--sm" onClick={newOtpCallback}>
          Wyślij nowy kod
        </Button>
        <Button variant="secondary" className="eg-btn btn--sm" onClick={handleCloseModal}>
          Anuluj
        </Button>

        <Button
          variant="dark"
          className="eg-btn btn--primary btn--sm"
          onClick={() => {
            handleConfirmModal()
          }}
          disabled={isConfirming}
        >
          {isConfirming ? <PulseLoader color="white" loading={true} size={10} /> : 'Potwierdź'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default PhoneVerificationModal
