export const META_EVENT_NAME = Object.freeze({
  ADD_PAYMENT_INFO: 'AddPaymentInfo',
  ADD_TO_CART: 'AddToCart',
  ADD_TO_WISHLIST: 'AddToWishlist',
  COMPLETE_REGISTRATION: 'CompleteRegistration',
  CONTACT: 'Contact',
  CUSTOMIZE_PRODUCT: 'CustomizeProduct',
  DONATE: 'Donate',
  FIND_LOCATION: 'FindLocation',
  INITIATE_CHECKOUT: 'InitiateCheckout',
  LEAD: 'Lead',
  PAGE_VIEW: 'PageView',
  PURCHASE: 'Purchase',
  SCHEDULE: 'Schedule',
  SEARCH: 'Search',
  START_TRIAL: 'StartTrial',
  SUBMIT_APPLICATION: 'SubmitApplication',
  SUBSCRIBE: 'Subscribe',
  VIEW_CONTENT: 'ViewContent',
})

export const metaPixelEvent = (eventName, eventProperties, eventId = null) => {
  try {
    let trackValue = Object.values(META_EVENT_NAME).includes(eventName) ? 'track' : 'trackCustom'
    const fbq = window.fbq
    if (eventId) {
      fbq(trackValue, eventName, eventProperties || null, eventId)
    } else {
      fbq(trackValue, eventName, eventProperties || null)
    }
  } catch (error) {
    console.error('Meta Pixel Error:', error)
  }
}
