import React from 'react'

import { Controller } from 'react-hook-form'
import Select from 'react-select'

import makeAnimated from 'react-select/animated'

const PageTwo = ({ control, errors, typesOfWorkSelectOptions }) => {
  const animatedComponents = makeAnimated()

  return (
    <div>
      <p>Wybierz usługi które wykonujesz</p>
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 mb-1">
          <div className="form-inner">
            <Controller
              control={control}
              name={'typesOfWork'}
              render={({ field: { onChange, value, name, ref } }) => (
                <Select
                  inputRef={ref}
                  className="types-of-work-multiselect"
                  classNamePrefix="types-of-work-multiselect"
                  placeholder="Wybierz usługi"
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  value={value}
                  isMulti
                  options={typesOfWorkSelectOptions}
                  onChange={onChange}
                  noOptionsMessage={() => 'Brak opcji'}
                  styles={{
                    option: (provided) => ({
                      ...provided,
                      cursor: 'pointer',
                      textAlign: 'left',
                      textTransform: 'capitalize',
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      textAlign: 'left',
                      fontSize: '15px',
                      textTransform: 'capitalize',
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      textAlign: 'left',
                      fontSize: '15px',
                      textTransform: 'capitalize',
                    }),
                    multiValue: (provided) => ({
                      ...provided,
                      textTransform: 'capitalize',
                    }),
                    menu: (provided) => ({
                      ...provided,
                      textTransform: 'capitalize',
                    }),
                  }}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: '0.25rem',
                    colors: {
                      ...theme.colors,
                      primary25: '#f9395f',
                      primary: '#f9395f',
                    },
                  })}
                />
              )}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageTwo
