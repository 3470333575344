import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import FuchaCard from './FuchaCard'
import { useFuchaActions } from '../../../_actions'
import { useAlerts } from '../../../context/AlertContext'
import notFoundIcon from '../../../assets/images/icons/not-found.svg'
import FuchaSearch from './FuchaSearch'
import SkeletonFuchaCard from '../../common/SkeletonFuchCard'
import ReactPaginate from 'react-paginate'
import { getPageCount, getToPageNumber, getFromPageNumber, removeQueryParam } from '../../../_helpers'
import { getQueryParam, QUERY_VALUE_DELIMITER, setQueryParam } from '../../../_helpers'
import { FUCHA_LISTING_PER_PAGE } from '../../../_constants'
import { FUCHA_FILTERS, REGIONS, ALL_COUNTIES, WORK_STATE_VALUES } from '../../../_constants'
import { scrollToTop } from '../../../_helpers'
import { URL_PATHS } from '../../../_constants'

const getProvinceQueryParams = () => {
  let values = getQueryParam(FUCHA_FILTERS.PROVINCES, true)
  if (!values) {
    return []
  }
  return values
    .map((item) => item.toUpperCase())
    .filter((province) => province in REGIONS)
    .map((province) => province)
}

const getCountyQueryParams = () => {
  let values = getQueryParam(FUCHA_FILTERS.COUNTIES, true)
  if (!values) {
    return []
  }
  return values.filter((item) => ALL_COUNTIES.includes(item))
}

const getCategoriesQueryParams = () => {
  let values = getQueryParam(FUCHA_FILTERS.CATEGORIES, true)
  if (!values) {
    return []
  }
  return values.map((item) => item.toLowerCase())
}

const getTypesOfWorkQueryParams = () => {
  let values = getQueryParam(FUCHA_FILTERS.TYPES_OF_WORK, true)
  if (!values) {
    return []
  }
  return values.map((item) => item.toLowerCase())
}

const getWorkStateQueryParams = () => {
  let value = getQueryParam(FUCHA_FILTERS.WORK_STATE)
  if (!value) {
    return null
  }
  value = value.toLowerCase()
  return WORK_STATE_VALUES.includes(value) ? value : null
}

const getPageQueryParam = () => {
  let value = getQueryParam('strona')
  if (!value) {
    return 1
  }
  try {
    value = parseInt(value)
    return value > 0 ? value : 1
  } catch (error) {
    return 1
  }
}

const FuchaWrap = () => {
  const [categoriesSelectOptions, setCategoriesSelectOptions] = useState([])
  const { addAlert } = useAlerts()
  const [filters, setFilters] = useState({
    provinces: getProvinceQueryParams(),
    counties: getCountyQueryParams(),
    categories: getCategoriesQueryParams(),
    types_of_work: getTypesOfWorkQueryParams(),
    work_state: getWorkStateQueryParams(),
  })
  const [loadingInitial, setLoadingInitial] = useState(true)
  const [fuchaCount, setFuchaCount] = useState(0)
  const [fuchaListing, setFuchaListing] = useState([])
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(getPageQueryParam())
  const [typesOfWorkSelectOptions, setTypesOfWorkSelectOptions] = useState([])

  const { getFuchaListing, getCategories } = useFuchaActions()
  // TODO(MAM-253): remove this code
  const localStorageKeyExist = localStorage.getItem('developerMode') === 'true'

  // TODO(MAM-253): remove this code
  const renderTemporaryMessage = () => {
    return (
      <div className="temporary-message-container">
        <div className="image-container">
          <img alt="images" src={process.env.PUBLIC_URL + '/images/icons/search-contractors.png'} />
        </div>
        <h4>
          Witaj! Chociaż już wystartowaliśmy, wciąż jesteśmy na etapie tworzenia bazy fachowców, dlatego fuchy zaczną
          być widoczne od 1 marca 2025 roku.
        </h4>
        <h4>
          Jeśli jesteś fachowcem, zarejestruj konto już teraz!
          Teraz oferujemy 9 miesięcy DARMOWEGO KONTA dla kolejnych 100 fachowców!*
        </h4>
        <Link to={URL_PATHS.SIGNUP_CONTRACTOR}>
          <button className="eg-btn btn--primary mb-3" onClick={() => scrollToTop()}>
            Dołącz jako fachowiec
          </button>
        </Link>
        <h4></h4>

        <h4>Jeżeli masz jakieś pytania, chętnie na nie odpowiemy!</h4>
        <Link to={URL_PATHS.CONTACT}>
          <button className="eg-btn btn--primary mb-2" onClick={() => scrollToTop()}>
            Zadaj pytanie
          </button>
        </Link>
      </div>
    )
  }

  const fetchFuchaListing = async (filters, page = 1) => {
    setLoading(true)
    // Fetch fucha listing with applied filters
    try {
      const { fuchaListingData, count } = await getFuchaListing(filters, page)
      setFuchaListing(fuchaListingData)
      setFuchaCount(count)
      setLoading(false)
    } catch (error) {
      addAlert('Wystąpił błąd, spróbuj ponownie później.', 'error')
    }
  }

  const fetchInitialData = () => {
    getFuchaListing(filters, page)
      .then(({ fuchaListingData, count }) => {
        setFuchaListing(fuchaListingData)
        setFuchaCount(count)
        setLoadingInitial(false)
        setLoading(false)
      })
      .catch((error) => {
        addAlert('Wystąpił błąd podczas pobierania fuch, spróbuj ponownie później.', 'error', null)
      })
    getCategories()
      .then(({ categoriesData, typesOfWorkData }) => {
        setCategoriesSelectOptions(
          categoriesData.map((value) => {
            return { value: value.name, label: value.name }
          })
        )
        setTypesOfWorkSelectOptions(
          typesOfWorkData.map((value) => {
            return { value: value.name, label: value.name }
          })
        )
      })
      .catch((error) => {
        addAlert('Wystąpił błąd podczas pobierania kategorii, spróbuj ponownie później.', 'error', null)
      })
  }

  const updateSearchQueryParams = () => {
    // Generate query params for filters
    const queryParams = new URLSearchParams()
    if (filters.types_of_work.length > 0) {
      queryParams.append(FUCHA_FILTERS.TYPES_OF_WORK, filters.types_of_work.join(QUERY_VALUE_DELIMITER))
    }
    if (filters.provinces.length > 0) {
      queryParams.append(FUCHA_FILTERS.PROVINCES, filters.provinces.join(QUERY_VALUE_DELIMITER).toLowerCase())
    }
    if (filters.counties.length > 0) {
      queryParams.append(FUCHA_FILTERS.COUNTIES, filters.counties.join(QUERY_VALUE_DELIMITER))
    }
    if (filters.categories.length > 0) {
      queryParams.append(FUCHA_FILTERS.CATEGORIES, filters.categories.join(QUERY_VALUE_DELIMITER))
    }
    if (filters.work_state) {
      queryParams.append(FUCHA_FILTERS.WORK_STATE, filters.work_state)
    }

    // Update the URL with the new query parameters
    window.history.replaceState({}, '', `${window.location.pathname}?${queryParams.toString()}`)
  }

  const getSkeletonFuchaCards = () => {
    const cards = []
    for (let i = 0; i < FUCHA_LISTING_PER_PAGE; i++) {
      cards.push(
        <div key={i} className="col-lg-4 col-md-6 col-sm-10">
          <SkeletonFuchaCard />
        </div>
      )
    }
    return cards
  }

  const handlePageClick = (e) => {
    setPage(e.selected + 1)
    setQueryParam('strona', e.selected + 1)
  }

  useEffect(() => {
    // Fire once to get initial data
    fetchInitialData()
  }, [])

  useEffect(() => {
    if (!loadingInitial) {
      fetchFuchaListing(filters, page)
    }
  }, [page])

  useEffect(() => {
    let timeoutId
    const REQUEST_DELAY_MILLISECONDS = 500 // Adjust the delay time as needed

    setLoading(true)
    if (!loadingInitial) {
      timeoutId = setTimeout(() => {
        if (page !== 1) {
          setPage(1)
          removeQueryParam('strona')
        } else {
          fetchFuchaListing(filters)
        }
      }, REQUEST_DELAY_MILLISECONDS)

      updateSearchQueryParams()
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [JSON.stringify(filters)])

  return (
    <>
      <div className="live-auction-section pt-120 pb-120">
        <img
          alt="images"
          src={process.env.PUBLIC_URL + '/images/bg/section-bg2.png'}
          className="img-fluid section-bg-top"
        />
        <img
          alt="images"
          src={process.env.PUBLIC_URL + '/images/bg/section-bg2.png'}
          className="img-fluid section-bg-bottom"
        />
        <div className="container">
          {!localStorageKeyExist ? (
            <div className="row d-flex justify-content-center temporary-message">
              <div className="col-xl-8 col-md-10">
                {renderTemporaryMessage()}
                <p className="para">
                  *Promocja dotyczy kolejnych 100 zarejestrowanych fachowców. Oferta obejmuje darmowe konto przez 9 miesięcy od daty rejestracji.
                </p>
              </div>
            </div>
          ) : (
            <>
              <FuchaSearch
                filters={filters}
                setFilters={setFilters}
                typesOfWorkSelectOptions={typesOfWorkSelectOptions}
                categoriesSelectOptions={categoriesSelectOptions}
              />

              <div className="row gy-4 mb-60">
                {loading || loadingInitial ? (
                  getSkeletonFuchaCards()
                ) : fuchaListing.length === 0 ? (
                  <div className="no-fuchy-container">
                    <div className="image-container">
                      <img alt="images" src={notFoundIcon} />
                    </div>
                    <h4 className="text-center">
                      Nie udało nam się znaleźć żadnych fuch...<br></br> Może spróbujesz innych kryteriów wyszukiwania?
                    </h4>
                  </div>
                ) : (
                  fuchaListing.map((item, index) => (
                    <div key={item.id} className="col-xl-4 col-lg-6 col-md-6">
                      <FuchaCard fucha={item} loading={false} />
                    </div>
                  ))
                )}
              </div>
              <div className="table-pagination">
                <p>
                  <b>Liczba Fuch: {fuchaCount}</b> <br />
                  {fuchaCount > 0 &&
                    `Wyświetlasz od ${getFromPageNumber(page, FUCHA_LISTING_PER_PAGE)} do ${getToPageNumber(page, FUCHA_LISTING_PER_PAGE, fuchaCount)}`}
                </p>
                <nav className="pagination-wrap">
                  <ul className="pagination style-two d-flex justify-content-center gap-md-3 gap-2">
                    <ReactPaginate
                      nextLabel=">"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={2}
                      pageCount={getPageCount(fuchaCount, FUCHA_LISTING_PER_PAGE)}
                      previousLabel="<"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      containerClassName="pagination"
                      activeClassName="active"
                      renderOnZeroPageCount={null}
                      forcePage={page > 0 ? page - 1 : 0}
                    />
                  </ul>
                </nav>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default FuchaWrap
