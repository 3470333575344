import React, { useEffect, useState } from 'react'
import { Avatar } from 'stream-chat-react'

const CustomChannelPreview = (props) => {
  const { channel, client, activeChannel, displayImage, displayTitle, setActiveChannel } = props
  const [unreadCount, setUnreadCount] = useState(channel.state.unreadCount)
  const isSelected = channel.id === activeChannel?.id

  const handleClick = () => {
    setActiveChannel?.(channel)
    const channelListElement = document.querySelector('.list-of-channels')
    channelListElement?.classList.add('closed-list')
    channelListElement?.classList.remove('open-list')
    channel.markRead() // Mark the channel as read
    setUnreadCount(0)
  }

  useEffect(() => {
    const handleNewMessage = (event) => {
      if (event.channel?.id === channel.id && !isSelected) {
        setUnreadCount(channel.state.unreadCount)
      }
    }

    client.on('message.new', handleNewMessage)

    return () => {
      client.off('message.new', handleNewMessage)
    }
  }, [client, channel, isSelected])

  return (
    <div className={`channel-list-item ${isSelected ? 'active' : ''}`} onClick={handleClick}>
      <div className="channel-image">
        <Avatar image={displayImage} name={channel.data.name} />
      </div>
      <div className="channel-info">
        <p className="para channel-name text-truncate">
          <b>Fucha: {displayTitle}</b>
        </p>
        <p className="para channel-last-message">
          Ostatnia wiadomość: {channel.state.messages[channel.state.messages.length - 1]?.text || 'Brak wiadomości...'}
        </p>
      </div>
      {unreadCount > 0 && <span className="unread-badge">{unreadCount}</span>}
    </div>
  )
}

export default CustomChannelPreview
