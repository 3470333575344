// src/components/Alerts.js
import React from 'react'
import { useAlerts } from '../../context/AlertContext'
import Alert from '@mui/material/Alert'
import CheckIcon from '@mui/icons-material/Check'

const Alerts = () => {
  const { alerts, removeAlert } = useAlerts()

  return (
    <div aria-live="polite" aria-atomic="true" className="alert-wrapper">
      {alerts.map((alert) => (
        <Alert key={alert.id} severity={alert.type} onClose={() => removeAlert(alert.id)}>
          {alert.message}
        </Alert>
      ))}
    </div>
  )
}

export default Alerts
