import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { Rating } from 'react-simple-star-rating'
import AccountIcon from '../../../../assets/images/icons/account.svg'
import { useAlerts } from '../../../../context/AlertContext'
import { useContractorActions } from '../../../../_actions/'
import { useFuchaActions, useClientActions } from '../../../../_actions/'
import { DYNAMIC_URL_PATHS, FUCHA_STATUS } from '../../../../_constants'
import { STAR_COLORS } from '../../../../_constants/values'
import Skeleton from 'react-loading-skeleton'
import ConfirmModal from '../../../common/ConfirmModal'
import UserReviewsSummaryModal from '../../../common/UserReviewsSummaryModal'
import { scrollToTop, shouldDisplayServerSideError } from '../../../../_helpers'
import { history, renderStarRating } from '../../../../_helpers'

const BidListItemsCard = ({
  bids,
  contractorReviewsSummary,
  disabledFuchaAction,
  fucha,
  handleAddReviewForContractor,
  handleCloseReviewModal,
  handleGetContractorReviewsSummary,
  handleReviewRating,
  isOwner,
  loadingBids,
  offerListRef,
  onBidRemoved,
  ratingValues,
  setBids,
  setFucha,
  setLoadingBids,
  setShowReviewModal,
  showReviewModal,
}) => {
  const { addAlert } = useAlerts()
  const { getFuchaBids, startChat } = useFuchaActions()
  const { acceptBid } = useClientActions()
  const { deleteBid } = useContractorActions()
  const [showModal, setShowModal] = useState(false)
  const [showContractorReviewsSummaryModal, setShowContractorReviewsSummaryModal] = useState(false)
  const [selectedBid, setSelectedBid] = useState(null)
  const [bidIdToDelete, setBidIdToDelete] = useState(null)
  const [bidToAccept, setBidToAccept] = useState(null)
  const acceptedBid = fucha.accepted_bid ? fucha.accepted_bid : null

  const handleCloseModal = () => {
    setShowModal(false)
    setBidIdToDelete(null)
    setBidToAccept(null)
  }

  const handleCloseContractorReviewsSummaryModal = () => {
    setShowContractorReviewsSummaryModal(false)
    setSelectedBid(null)
  }

  const handleOpenContractorReviewsSummaryModal = (bid) => {
    setSelectedBid(bid)
    setShowContractorReviewsSummaryModal(true)
  }

  const handleDeleteBid = (setIsConfirming) => {
    deleteBid(bidIdToDelete)
      .then(() => {
        setBids((prev) =>
          prev.map((item) => {
            if (item.id === bidIdToDelete) {
              return { ...item, is_deleted: true }
            }
            return item
          })
        )
        addAlert('Oferta została usunięta', 'success')
        setFucha((prev) => ({ ...prev, bid_count: prev - 1 }))
        onBidRemoved()
      })
      .catch((err) => {
        if (shouldDisplayServerSideError(err)) {
          addAlert(err.response.data.display, 'error')
        } else {
          addAlert('Wystąpił błąd podczas usuwania oferty', 'error')
        }
      })
      .finally(() => {
        setBidIdToDelete(false)
        setShowModal(false)
        setIsConfirming(false)
      })
  }

  const handleConfirmBid = (setIsConfirming) => {
    const payload = {
      bid_id: bidToAccept.id,
      contractor_id: bidToAccept.contractor.id,
      fucha_id: fucha.id,
    }
    acceptBid(payload)
      .then((data) => {
        setFucha(data)
        setBidToAccept(null)
        addAlert('Oferta została zaakceptowana', 'success')
      })
      .catch((err) => {
        if (shouldDisplayServerSideError(err)) {
          addAlert(err.response.data.display, 'error')
        } else {
          addAlert('Wystąpił błąd podczas zaakceptowania oferty', 'error')
        }
      })
      .finally(() => {
        setShowModal(false)
        setBidToAccept(null)
        setIsConfirming(false)
      })
  }

  const handleChatStart = (contractorId) => {
    startChat(fucha.id, contractorId)
      .then((data) => {
        history.push('/chat?chatId=' + data.channel_id)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  useEffect(() => {
    getFuchaBids(fucha.id)
      .then((data) => {
        setBids(data)
        setLoadingBids(false)
      })
      .catch((err) => {
        if (shouldDisplayServerSideError(err)) {
          addAlert(err.response.data.display, 'error')
        } else {
          addAlert('Wystąpił błąd podczas pobierania ofert', 'error')
        }
      })
  }, [])

  const renderBigScreenBidItem = (bid) => {
    const contractor = bid.contractor
    const isAccepted = bid.id === acceptedBid?.id
    return (
      <>
        <div key={bid.id} className="offer-list-item-container d-lg-flex d-none">
          <div style={{ maxWidth: '80px' }} className="offer-image-container">
            <div className="offer-image-box">
              <Link to={DYNAMIC_URL_PATHS.ContractorProfile(bid.contractor.id)} onClick={() => scrollToTop()}>
                <OverlayTrigger
                  key={`contractor-img-${bid.id}`}
                  placement="top"
                  overlay={<Tooltip id={`contractor-img-${bid.id}`}>Kliknij żeby przejść do profilu fachowca</Tooltip>}
                >
                  <img
                    id={`contractor-img-${bid.id}`}
                    src={contractor.image ? contractor.image : AccountIcon}
                    alt="Admin"
                    className="rounded-circle"
                    width="50"
                  />
                </OverlayTrigger>
              </Link>
            </div>
          </div>

          <div style={{ maxWidth: '135px' }} className="offer-list-item">
            <Link to={DYNAMIC_URL_PATHS.ContractorProfile(bid.contractor.id)} onClick={() => scrollToTop()}>
              <OverlayTrigger
                key={`contractor-name-${bid.id}`}
                placement="top"
                overlay={<Tooltip id={`contractor-name-${bid.id}`}>Kliknij żeby przejść do profilu fachowca</Tooltip>}
              >
                <p id={`contractor-name-${bid.id}`} className="para name">
                  {contractor.name}
                </p>
              </OverlayTrigger>
            </Link>
          </div>

          <div style={{ maxWidth: '200px' }} className="offer-list-item">
            <div
              style={{ cursor: bid.contractor.average_score > 0 ? 'pointer' : 'auto' }}
              className="offer-review-rate"
              onClick={() => {
                if (bid.contractor.average_score > 0) {
                  handleOpenContractorReviewsSummaryModal(bid)
                  handleGetContractorReviewsSummary(bid.contractor.id)
                }
              }}
            >
              {renderStarRating(bid.contractor.average_score)}
              <span>({bid.contractor.average_score ? bid.contractor.average_score.toFixed(2) : '0'})</span>
            </div>
          </div>

          <div style={{ maxWidth: '50px', paddingLeft: '40px', paddingRight: '35px' }} className="offer-list-item">
            <div
              className={`icon-container offer-icon-container ${contractor.is_email_verified ? 'verified' : 'not-verified'}`}
            >
              <OverlayTrigger
                key={`${bid.id}-confirm-offer`}
                placement="top"
                overlay={
                  <Tooltip id={`${bid.id}-envelope`}>
                    {contractor.is_email_verified ? 'Zweryfikowany adres email' : 'Niezweryfikowany adres email'}
                  </Tooltip>
                }
              >
                <div id={`${bid.id}-envelope`} className="icon">
                  <i className="bi bi-envelope"></i>
                </div>
              </OverlayTrigger>
            </div>
          </div>

          <div style={{ maxWidth: '50px' }} className="offer-list-item">
            <div
              className={`icon-container offer-icon-container ${contractor.is_phone_verified ? 'verified' : 'not-verified'}`}
            >
              <OverlayTrigger
                key={`${bid.id}-confirm-offer`}
                placement="top"
                overlay={
                  <Tooltip id={`${bid.id}-phone`}>
                    {contractor.is_phone_verified ? 'Zweryfikowany numer telefonu' : 'Niezweryfikowany numer telefonu'}
                  </Tooltip>
                }
              >
                <div id={`${bid.id}-phone`} className="icon">
                  <i className="bi bi-telephone"></i>
                </div>
              </OverlayTrigger>
            </div>
          </div>

          <div style={{ maxWidth: '200px' }} className="offer-list-item">
            <p className="para">Kwota: {bid.bid} zł</p>
          </div>
          <div style={{ maxWidth: '220px' }} className="offer-list-item">
            <p className="para">{bid.timesince}</p>
          </div>

          {isOwner ? (
            <>
              {!acceptedBid && (
                <div style={{ maxWidth: '120px' }} className="offer-list-item">
                  <OverlayTrigger
                    key={`${bid.id}-confirm-offer`}
                    placement="top"
                    overlay={
                      <Tooltip id={`${bid.id}-confirm-offer`}>
                        {disabledFuchaAction
                          ? 'Nie możesz zatwierdzić fuchy która jest nieaktywna'
                          : 'Zatwierdź wycenę'}
                      </Tooltip>
                    }
                  >
                    <button
                      id={`${bid.id}-confirm-offer`}
                      className={`eg-btn btn--primary btn--sm confirm ${disabledFuchaAction ? 'expired-fucha-btn' : ''}`}
                      onClick={() => {
                        setBidToAccept(bid)
                        setShowModal(true)
                      }}
                      disabled={disabledFuchaAction}
                    >
                      Zatwierdź
                    </button>
                  </OverlayTrigger>
                </div>
              )}

              <div style={{ maxWidth: '70px' }} className="offer-list-item">
                <OverlayTrigger
                  key={`${bid.id}-message`}
                  placement="top"
                  overlay={
                    <Tooltip id={`${bid.id}-message`}>
                      {disabledFuchaAction
                        ? 'Nie możesz napisać do fachowca. Fucha nie jest aktywna'
                        : 'Napisz wiadomość do fachowca'}
                    </Tooltip>
                  }
                >
                  <button
                    disabled={disabledFuchaAction}
                    id={`${bid.id}-message`}
                    className={`eg-btn btn--primary btn--sm ${disabledFuchaAction ? 'expired-fucha-btn' : ''}`}
                    onClick={() => handleChatStart(bid.contractor.id)}
                  >
                    <i className="bi bi-chat-left-dots"></i>
                  </button>
                </OverlayTrigger>
              </div>

              {isAccepted ? (
                <div style={{ maxWidth: '70px' }} className="offer-list-item">
                  <OverlayTrigger
                    key={`${bid.id}-contractor-review`}
                    placement="top"
                    overlay={
                      fucha.has_review ? (
                        <Tooltip id={`${bid.id}-contractor-review`}>Już wystawiłeś recenzję dla tego fachowca</Tooltip>
                      ) : (
                        <Tooltip id={`${bid.id}-contractor-review`}>Oceń fachowca</Tooltip>
                      )
                    }
                  >
                    <button
                      onClick={() => {
                        setShowReviewModal(true)
                      }}
                      id={`${bid.id}-contractor-review`}
                      className={`${fucha.has_review ? 'add-review-button' : 'btn--primary'} eg-btn btn--sm`}
                      disabled={fucha.has_review}
                    >
                      <i className="bi bi-star"></i>
                    </button>
                  </OverlayTrigger>
                </div>
              ) : null}
            </>
          ) : (
            !bid.is_deleted && (
              <>
                <OverlayTrigger
                  key={`#${bid.id}-delete`}
                  placement="top"
                  overlay={<Tooltip id={`#${bid.id}-delete`}>Usuń swoją wycenę</Tooltip>}
                >
                  <button
                    id={`${bid.id}-delete`}
                    className={`eg-btn btn--primary btn--sm`}
                    onClick={() => {
                      setBidIdToDelete(bid.id)
                      setShowModal(true)
                    }}
                  >
                    <i className="bi bi-x-square"></i>
                  </button>
                </OverlayTrigger>
              </>
            )
          )}
        </div>
      </>
    )
  }

  const renderSmallScreenBidItem = (bid) => {
    const contractor = bid.contractor
    const isAccepted = bid.id === acceptedBid?.id
    return (
      <>
        <div key={`${bid.id}-small`} className="offer-list-item-container d-lg-none d-flex">
          <div className="bid-contractor-details">
            <div className="offer-image-container">
              <div className="offer-image-box">
                <Link to={DYNAMIC_URL_PATHS.ContractorProfile(bid.contractor.id)} onClick={() => scrollToTop()}>
                  <OverlayTrigger
                    key={`contractor-small-img-${bid.id}`}
                    placement="top"
                    overlay={
                      <Tooltip id={`contractor-small-img-${bid.id}`}>Kliknij żeby przejść do profilu fachowca</Tooltip>
                    }
                  >
                    <img
                      id={`contractor-small-img-${bid.id}`}
                      src={contractor.image ? contractor.image : AccountIcon}
                      alt="Admin"
                      className="rounded-circle"
                      width="50"
                    />
                  </OverlayTrigger>
                </Link>
              </div>
            </div>

            <div className="bid-contractor-info">
              <div className="offer-list-item">
                <Link to={DYNAMIC_URL_PATHS.ContractorProfile(bid.contractor.id)} onClick={() => scrollToTop()}>
                  <OverlayTrigger
                    key={`contractor-small-name-${bid.id}`}
                    placement="top"
                    overlay={
                      <Tooltip id={`contractor-small-name-${bid.id}`}>Kliknij żeby przejść do profilu fachowca</Tooltip>
                    }
                  >
                    <p id={`contractor-small-name-${bid.id}`} className="para name">
                      {contractor.name}
                    </p>
                  </OverlayTrigger>
                </Link>
              </div>

              <div className="offer-list-item">
                <div
                  style={{ cursor: bid.contractor.average_score > 0 ? 'pointer' : 'auto' }}
                  className="offer-review-rate"
                  onClick={() => {
                    if (bid.contractor.average_score > 0) {
                      handleOpenContractorReviewsSummaryModal(bid)
                      handleGetContractorReviewsSummary(bid.contractor.id)
                    }
                  }}
                >
                  {renderStarRating(bid.contractor.average_score)}
                  <span>({bid.contractor.average_score ? bid.contractor.average_score.toFixed(2) : '0'})</span>
                </div>
              </div>
            </div>
          </div>

          <div className="bid-details-container">
            <div className="bid-details-box">
              <div className="offer-list-item">
                <OverlayTrigger
                  key={`sum-small-${bid.id}`}
                  placement="top"
                  overlay={<Tooltip id={`sum-small-${bid.id}`}>Kwota</Tooltip>}
                >
                  <p id={`sum-small-${bid.id}`} className="para">
                    Kwota: {bid.bid} zł
                  </p>
                </OverlayTrigger>
              </div>
              <div className="offer-list-item">
                <OverlayTrigger
                  key={`date-small-${bid.id}`}
                  placement="top"
                  overlay={<Tooltip id={`date-small-${bid.id}`}>Data</Tooltip>}
                >
                  <p id={`date-small-${bid.id}`} className="para para-small">
                    {bid.timesince}
                  </p>
                </OverlayTrigger>
              </div>
            </div>

            <div className="veryfi-icons">
              <div className="offer-list-item">
                <div
                  className={`icon-container offer-icon-container ${contractor.is_email_verified ? 'verified' : 'not-verified'}`}
                >
                  <OverlayTrigger
                    key={`${bid.id}-confirm-small-offer`}
                    placement="top"
                    overlay={
                      <Tooltip id={`${bid.id}-small-envelope`}>
                        {contractor.is_email_verified ? 'Zweryfikowany adres email' : 'Niezweryfikowany adres email'}
                      </Tooltip>
                    }
                  >
                    <div id={`${bid.id}-small-envelope`} className="icon">
                      <i className="bi bi-envelope"></i>
                    </div>
                  </OverlayTrigger>
                </div>
              </div>

              <div className="offer-list-item">
                <div
                  className={`icon-container offer-icon-container ${contractor.is_phone_verified ? 'verified' : 'not-verified'}`}
                >
                  <OverlayTrigger
                    key={`${bid.id}-confirm-small-offer`}
                    placement="top"
                    overlay={
                      <Tooltip id={`${bid.id}-small-phone`}>
                        {contractor.is_phone_verified
                          ? 'Zweryfikowany numer telefonu'
                          : 'Niezweryfikowany numer telefonu'}
                      </Tooltip>
                    }
                  >
                    <div id={`${bid.id}-small-phone`} className="icon">
                      <i className="bi bi-telephone"></i>
                    </div>
                  </OverlayTrigger>
                </div>
              </div>
            </div>

            {isOwner ? (
              <div className="bid-buttons">
                {!acceptedBid && (
                  <div className="offer-list-item">
                    <OverlayTrigger
                      key={`${bid.id}-small-confirm-offer`}
                      placement="top"
                      overlay={
                        <Tooltip id={`${bid.id}-small-confirm-offer`}>
                          {disabledFuchaAction
                            ? 'Nie możesz zatwierdzić fuchy która jest nieaktywna'
                            : 'Zatwierdź wycenę'}
                        </Tooltip>
                      }
                    >
                      <button
                        id={`${bid.id}-small-confirm-offer`}
                        className={`eg-btn btn--primary btn--sm confirm ${disabledFuchaAction ? 'expired-fucha-btn' : ''}`}
                        onClick={() => {
                          setBidToAccept(bid)
                          setShowModal(true)
                        }}
                        disabled={disabledFuchaAction}
                      >
                        Zatwierdź
                      </button>
                    </OverlayTrigger>
                  </div>
                )}

                <div className="offer-list-item">
                  <OverlayTrigger
                    key={`${bid.id}-small-message`}
                    placement="top"
                    overlay={
                      <Tooltip id={`${bid.id}-small-message`}>
                        {disabledFuchaAction
                          ? 'Nie możesz napisać do fachowca. Fucha nie jest aktywna'
                          : 'Napisz wiadomość do fachowca'}
                      </Tooltip>
                    }
                  >
                    <button
                      disabled={disabledFuchaAction}
                      id={`${bid.id}-small-message`}
                      className={`eg-btn btn--primary btn--sm ${disabledFuchaAction ? 'expired-fucha-btn' : ''}`}
                      onClick={() => handleChatStart(bid.contractor.id)}
                    >
                      <i className="bi bi-chat-left-dots"></i>
                    </button>
                  </OverlayTrigger>
                </div>

                {isAccepted ? (
                  <div className="offer-list-item">
                    <OverlayTrigger
                      key={`${bid.id}-small-contractor-review`}
                      placement="top"
                      overlay={
                        fucha.has_review ? (
                          <Tooltip id={`${bid.id}-small-contractor-review`}>
                            Już wystawiłeś recenzję dla tego fachowca
                          </Tooltip>
                        ) : (
                          <Tooltip id={`${bid.id}-small-contractor-review`}>Oceń fachowca</Tooltip>
                        )
                      }
                    >
                      <button
                        onClick={() => {
                          setShowReviewModal(true)
                        }}
                        id={`${bid.id}-small-contractor-review`}
                        className={`${fucha.has_review ? 'add-review-button' : 'btn--primary'} eg-btn btn--sm`}
                        disabled={fucha.has_review || false}
                      >
                        <i className="bi bi-star"></i>
                      </button>
                    </OverlayTrigger>
                  </div>
                ) : null}
              </div>
            ) : (
              !bid.is_deleted && (
                <>
                  <OverlayTrigger
                    key={`${bid.id}-small-delete`}
                    placement="top"
                    overlay={<Tooltip id={`${bid.id}-small-delete`}>Usuń swoją wycenę</Tooltip>}
                  >
                    <button
                      id={`${bid.id}-small-delete`}
                      className="eg-btn btn--primary btn--sm remove-offer-btn"
                      onClick={() => {
                        setBidIdToDelete(bid.id)
                        setShowModal(true)
                      }}
                    >
                      <i className="bi bi-x-square"></i>
                    </button>
                  </OverlayTrigger>
                </>
              )
            )}
          </div>
        </div>
      </>
    )
  }

  const renderBid = (bid) => {
    return (
      <React.Fragment key={bid.id}>
        {renderBigScreenBidItem(bid)}
        {renderSmallScreenBidItem(bid)}
      </React.Fragment>
    )
  }

  const renderBids = () => {
    const deletedBids = bids.filter((bid) => bid.is_deleted)
    const activeBids = bids.filter((bid) => !bid.is_deleted)
    if (isOwner) {
      if (acceptedBid) {
        const rejectedBids = bids.filter((bid) => bid.id !== acceptedBid.id)
        return (
          <div className={`offers-list-container ${disabledFuchaAction ? 'expired-fucha' : ''}`}>
            <h6>Zatwierdzona wycena</h6>
            {renderBid(acceptedBid)}
            {rejectedBids.length > 0 && (
              <>
                <h6>Odrzucone</h6>
                {rejectedBids.map((bid) => {
                  return renderBid(bid)
                })}
              </>
            )}
          </div>
        )
      } else {
        return (
          <div className="offers-list-container">
            {bids.map((bid) => {
              return renderBid(bid)
            })}
          </div>
        )
      }
    }
    if (fucha.status === FUCHA_STATUS.ACTIVE) {
      return (
        <div className="offers-list-container">
          {activeBids.length > 0 ? <h6>Aktwyne</h6> : null}
          {activeBids.map((bid) => {
            return renderBid(bid)
          })}
          {!isOwner && deletedBids.length > 0 ? (
            <>
              <h6>Usunięte</h6>
              {deletedBids.map((bid) => {
                return renderBid(bid)
              })}
            </>
          ) : null}
        </div>
      )
    } else {
      if (fucha.accepted_bid) {
        return (
          <div className="offers-list-container">
            <h6>Zakończone - Twoja wycena wygrała! Gratulujemy!</h6>
            {renderBid(fucha.accepted_bid)}
          </div>
        )
      }
      return (
        <div className="offers-list-container">
          <h6>Zakończone - Niestety Twoja wycena nie została wybrana</h6>
          {bids.map((bid) => {
            return renderBid(bid)
          })}
        </div>
      )
    }
  }

  const renderSkeletonBids = () => {
    return (
      <div className="offers-list-container">
        {[1, 2, 3].map((index) => (
          <div key={index} className="offer-list-item-container">
            <div className="offer-image-container">
              <div className="offer-image-box">
                <Skeleton height={50} width={50} borderRadius={'50%'} />
              </div>
            </div>
            <div className="offer-list-item">
              <p className="para name">
                <Skeleton width={100} />
              </p>
            </div>
            <div className="offer-list-item">
              <Skeleton width={100} />
            </div>
            <div className="offer-list-item">
              <Skeleton width={100} />
            </div>
            <div className="offer-list-item">
              <Skeleton width={100} />
            </div>
            <div className="offer-list-item">
              <Skeleton width={100} />
            </div>
            <div className="offer-list-item">
              <Skeleton width={100} />
            </div>
            <div className="offer-list-item offer-btn-container">
              <Skeleton width={100} />
            </div>
          </div>
        ))}
      </div>
    )
  }

  const renderNoBids = () => {
    return (
      <div className="product-details-right  wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".2s">
        <div className="bid-form">
          <div className="form-title">
            <h5>{isOwner ? 'Nie ma jeszcze dostępnych wycen' : 'Brak wysłanych wycen'}</h5>
            <p className="para">
              {isOwner
                ? 'Spokojnie, fachowcy przeglądają Twoje zgłoszenie. Sprawdź ponownie za chwilę, a na pewno wkrótce pojawi się wycena, która Ci odpowiada!'
                : ''}
            </p>
          </div>
        </div>
      </div>
    )
  }

  const renderReviewModal = () => {
    return (
      <ConfirmModal
        header="Oceń tego fachowca!"
        showModal={showReviewModal}
        handleClose={handleCloseReviewModal}
        handleConfirm={handleAddReviewForContractor}
        size="lg"
        className="contractor-review-modal"
      >
        <img src={process.env.PUBLIC_URL + '/images/bg/client-bg.png'} className="review-bg" alt="" />
        <div className="row align-items-center flex-column review-container">
          <div className="col-lg-12 w-100">
            <div className="d-flex justify-content-center align-items-center h-100 w-100">
              <div className="image-container">
                <img
                  id={`contractor-img-${acceptedBid.id}`}
                  src={acceptedBid.contractor.image ? acceptedBid.contractor.image : AccountIcon}
                  alt="Admin"
                  className="rounded-circle"
                  width="110"
                />
              </div>
            </div>
          </div>

          <div className="col-lg-12 w-100">
            <div className="d-flex flex-column align-items-center h-100 w-100 contractor-name-box">
              <p>{acceptedBid.contractor.name}</p>
            </div>
          </div>

          <div className="col-lg-12 w-100" style={{ paddingBottom: '20px' }}>
            <div className="d-flex flex-column align-items-center h-100 w-100">
              <div className="rating-box">
                <div className="text-part">
                  <p>Jakość wykonania</p>
                </div>
                <div className="stars-part">
                  <Rating
                    fillColorArray={[
                      STAR_COLORS.poor,
                      STAR_COLORS.belowAverage,
                      STAR_COLORS.average,
                      STAR_COLORS.good,
                      STAR_COLORS.excellent,
                    ]}
                    tooltipDefaultText="Jakość wykonania"
                    titleSeparator="z"
                    size={23}
                    onClick={(rating) => handleReviewRating('quality', rating)}
                    value={ratingValues.quality}
                  />
                </div>
              </div>
              <div className="rating-box">
                <div className="text-part">
                  <p>Koszt wykonania</p>
                </div>
                <div className="stars-part">
                  <Rating
                    fillColorArray={[
                      STAR_COLORS.poor,
                      STAR_COLORS.belowAverage,
                      STAR_COLORS.average,
                      STAR_COLORS.good,
                      STAR_COLORS.excellent,
                    ]}
                    titleSeparator="z"
                    size={23}
                    onClick={(rating) => handleReviewRating('price', rating)}
                    value={ratingValues.price}
                    initialValue={0}
                  />
                </div>
              </div>
              <div className="rating-box">
                <div className="text-part">
                  <p>Czas wykonania</p>
                </div>
                <div className="stars-part">
                  <Rating
                    fillColorArray={[
                      STAR_COLORS.poor,
                      STAR_COLORS.belowAverage,
                      STAR_COLORS.average,
                      STAR_COLORS.good,
                      STAR_COLORS.excellent,
                    ]}
                    tooltipDefaultText="Czas Wykonania"
                    titleSeparator="z"
                    size={23}
                    onClick={(rating) => handleReviewRating('time', rating)}
                    value={ratingValues.time}
                  />
                </div>
              </div>
              <div className="rating-box">
                <div className="text-part">
                  <p>Kontakt z fachowcem</p>
                </div>
                <div className="stars-part">
                  <Rating
                    fillColorArray={[
                      STAR_COLORS.poor,
                      STAR_COLORS.belowAverage,
                      STAR_COLORS.average,
                      STAR_COLORS.good,
                      STAR_COLORS.excellent,
                    ]}
                    tooltipDefaultText="Kontakt z Fachowcem"
                    titleSeparator="z"
                    size={23}
                    onClick={(rating) => handleReviewRating('contact', rating)}
                    value={ratingValues.contact}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ConfirmModal>
    )
  }

  return (
    <div className="row" ref={offerListRef}>
      <div className="col-md-12 offer-items-container">
        <div className={`card ${disabledFuchaAction ? 'expired-fucha' : ''}`}>
          <div className="card-body">
            <div className="sidebar-widget-title">
              <h4>{isOwner ? 'Wyceny Od Fachowców' : 'Twoje Wyceny'}</h4>
              <span />
            </div>
            {loadingBids ? renderSkeletonBids() : bids.length === 0 ? renderNoBids() : renderBids()}
          </div>
        </div>
      </div>
      <ConfirmModal
        header={isOwner ? 'Czy na pewno chcesz wybrać tę wycenę?' : 'Czy na pewno chcesz usunąć tę wycenę?'}
        showModal={showModal}
        handleClose={handleCloseModal}
        handleConfirm={isOwner ? handleConfirmBid : handleDeleteBid}
        size="lg"
        className="bid-modal"
      >
        {isOwner ? (
          <>
            <div className="text-center">
              <p className="para">
                Po zatwierdzeniu Twoje ogłoszenie zostanie <b>sfinalizowane</b>, a wszystkie inne wyceny zostaną
                odrzucone. <b>Dane kontaktowe</b> zostaną przekazane wybranemu fachowcowi, aby mógł się z Tobą
                skontaktować i omówić szczegóły zlecenia.
              </p>
            </div>
          </>
        ) : (
          <>
            <p className="para">
              Pamiętaj, że możesz złożyć <b>maksymalnie dwie wyceny</b>. Usunięcie tej wyceny{' '}
              <b>nie umożliwi dodania kolejnej</b>, jeśli już wykorzystałeś limit dwóch wycen.
            </p>
          </>
        )}
      </ConfirmModal>
      {showReviewModal && renderReviewModal()}
      {showContractorReviewsSummaryModal && selectedBid && (
        <UserReviewsSummaryModal
          showModal={showContractorReviewsSummaryModal}
          handleClose={handleCloseContractorReviewsSummaryModal}
          reviewsSummary={contractorReviewsSummary}
          loading={loadingBids}
          userId={selectedBid.contractor.id}
          userImage={selectedBid.contractor.image}
          userName={selectedBid.contractor.name}
        />
      )}
    </div>
  )
}

export default BidListItemsCard
