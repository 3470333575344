import { REGIONS } from '../_constants'

export const createProvinceWorkAreaObjects = (selectedProvinces, selectedCounties) => {
  console.log('Selected provinces:', selectedProvinces)
  console.log('Selected counties:', selectedCounties)
  return selectedProvinces.map((province) => {
    const countiesForProvince = selectedCounties
      .filter((county) => REGIONS[province.value].includes(county.value))
      .map((county) => county.value)
    return {
      province: province.value,
      counties: countiesForProvince,
      is_province_wide: countiesForProvince.length === 0,
    }
  })
}
