import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import WOW from 'wowjs'
import { URL_PATHS } from '../../_constants/urls'
import { useUserActions } from '../../_actions'
import logoWhiteSVG from '../../assets/images/bg/logo-white.svg'
import { Switch, Route } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'
import PrivacyPolicy from '../page/privacyPolicy/PrivacyPolicy'

const Footer = () => {
  const { isAuth, auth } = useAuth()
  const { logout } = useUserActions()

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init()
  }, [])
  const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })

  return (
    <Switch>
      <Route path={URL_PATHS.CHAT}>
        <></>
      </Route>
      <Route path={'*'}>
        <footer>
          <div className="footer-top">
            <div className="container">
              <div className="row gy-5">
                <div className="col-lg-4">
                  <div className="footer-item">
                    <Link to={URL_PATHS.HOME} onClick={scrollTop}>
                      <img alt="logo" src={logoWhiteSVG} />
                    </Link>
                    <p className="text-left">
                      Mamfuche.pl to innowacyjna platforma, która skutecznie łączy osoby szukające profesjonalnych usług
                      remontowych, budowlanych i naprawczych z doświadczonymi i sprawdzonymi fachowcami.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4  d-flex justify-content-lg-center">
                  <div className="footer-item">
                    <h5>Przydatne Linki</h5>
                    <ul className="footer-list">
                      {/* <li>
                        <Link onClick={scrollTop} to={URL_PATHS.BLOG}>
                          Blog
                        </Link>
                      </li> */}
                      <li>
                        <Link onClick={scrollTop} to={URL_PATHS.PRICES}>
                          Cennik - Dla Fachowców
                        </Link>
                      </li>

                      {!isAuth && (
                        <li>
                          <Link onClick={scrollTop} to={URL_PATHS.SIGNUP_CONTRACTOR}>
                            Dołącz jako Fachowiec
                          </Link>
                        </li>
                      )}

                      <li>
                        <Link onClick={scrollTop} to={URL_PATHS.ABOUT}>
                          Kim Jesteśmy
                        </Link>
                      </li>
                      <li>
                        <Link onClick={scrollTop} to={URL_PATHS.LOGIN}>
                          Logowanie / Moje Konto
                        </Link>
                      </li>
                      <li>
                        <Link onClick={scrollTop} to={URL_PATHS.FUCHA_LISTING}>
                          Przeglądaj Fuchy
                        </Link>
                      </li>
                      {isAuth && (
                        <li>
                          <Link
                            onClick={() => {
                              scrollTop()
                              logout()
                            }}
                            to={URL_PATHS.HOME}
                          >
                            Wyloguj się
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 d-flex justify-content-lg-center">
                  <div className="footer-item">
                    <h5>Pomoc i Kontakt</h5>
                    <ul className="footer-list">
                      <li>
                        <Link onClick={scrollTop} to={URL_PATHS.HOW_IT_WORKS}>
                          Jak To Działa
                        </Link>
                      </li>
                      <li>
                        <Link onClick={scrollTop} to={URL_PATHS.CONTACT}>
                          Kontakt
                        </Link>
                      </li>
                      <li>
                        <Link to={URL_PATHS.PRIVACY_POLICY} onClick={scrollTop}>
                          Polityka Prywatności
                        </Link>
                      </li>
                      <li>
                        <Link to={URL_PATHS.TERMS_AND_CONDITIONS} onClick={scrollTop}>
                          Regulamin
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <div className="col-lg-3 col-md-6">
                  <div className="footer-item">
                    <h5>Ostatnie Wpisy</h5>
                    <ul className="recent-feed-list">
                    <li className="single-feed">
                      <div className="feed-img">
                        <Link onClick={scrollTop} to={URL_PATHS.BLOG_DETAIL}>
                          <img alt="images" src={process.env.PUBLIC_URL + '/images/blog/recent-feed1.png'} />
                        </Link>
                      </div>
                      <div className="feed-content">
                        <span>January 31, 2022</span>
                        <h6>
                          <Link onClick={scrollTop} to={URL_PATHS.BLOG_DETAIL}>
                            Grant Distributions Conti nu to Incr Ease.
                          </Link>
                        </h6>
                      </div>
                    </li>
                    <li className="single-feed">
                      <div className="feed-img">
                        <Link onClick={scrollTop} to={URL_PATHS.BLOG_DETAIL}>
                          <img alt="images" src={process.env.PUBLIC_URL + '/images/blog/recent-feed2.png'} />
                        </Link>
                      </div>
                      <div className="feed-content">
                        <span>February 21, 2022</span>

                        <h6>
                          <Link onClick={scrollTop} to={URL_PATHS.BLOG_DETAIL}>
                            Seminar for Children to Learn About.
                          </Link>
                        </h6>
                      </div>
                    </li>
                    <li className="single-feed">
                      <div className="feed-img">
                        <Link onClick={scrollTop} to={URL_PATHS.BLOG_DETAIL}>
                          <img alt="images" src={process.env.PUBLIC_URL + '/images/blog/recent-feed3.png'} />
                        </Link>
                      </div>
                      <div className="feed-content">
                        <span>March 22, 2022</span>
                        <h6>
                          <Link onClick={scrollTop} to={URL_PATHS.BLOG_DETAIL}>
                            Education and teacher for all African Children.
                          </Link>
                        </h6>
                      </div>
                    </li>
                  </ul>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="footer-bottom h-100">
            <div className="container">
              <div className="d-flex justify-content-center align-items-center">
                <p>Copyright &copy; {new Date().getFullYear()} mamfuche.pl</p>
                {/* <div className="col-lg-6 d-flex justify-content-lg-end justify-content-center align-items-center flex-sm-nowrap flex-wrap">
                <p className="d-sm-flex d-none">Akceptujemy płatności:</p>
                <ul className="footer-logo-list">
                  <li>
                    <Link to={'#'} onClick={scrollTop}>
                      <img alt="images" src={process.env.PUBLIC_URL + '/images/bg/footer-pay1.png'} />
                    </Link>
                  </li>
                  <li>
                    <Link to={'#'} onClick={scrollTop}>
                      <img alt="images" src={process.env.PUBLIC_URL + '/images/bg/footer-pay2.png'} />
                    </Link>
                  </li>
                  <li>
                    <Link to={'#'} onClick={scrollTop}>
                      <img alt="images" src={process.env.PUBLIC_URL + '/images/bg/footer-pay3.png'} />
                    </Link>
                  </li>
                  <li>
                    <Link to={'#'} onClick={scrollTop}>
                      <img alt="images" src={process.env.PUBLIC_URL + '/images/bg/footer-pay4.png'} />
                    </Link>
                  </li>
                  <li>
                    <Link to={'#'} onClick={scrollTop}>
                      <img alt="images" src={process.env.PUBLIC_URL + '/images/bg/footer-pay5.png'} />
                    </Link>
                  </li>
                </ul>
              </div> */}
              </div>
            </div>
          </div>
        </footer>
      </Route>
    </Switch>
  )
}

export default Footer
