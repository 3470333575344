export const REGIONS = {
  DOLNOŚLĄSKIE: [
    'Jelenia Góra',
    'Legnica',
    'Wałbrzych',
    'Wrocław',
    'Pow. Bolesławiecki',
    'Pow. Dzierżoniowski',
    'Pow. Głogowski',
    'Pow. Górowski',
    'Pow. Jaworski',
    'Pow. Jeleniogórski',
    'Pow. Kamiennogórski',
    'Pow. Kłodzki',
    'Pow. Legnicki',
    'Pow. Lubański',
    'Pow. Lubiński',
    'Pow. Lwówecki',
    'Pow. Milicki',
    'Pow. Oleśnicki',
    'Pow. Oławski',
    'Pow. Polkowicki',
    'Pow. Strzeliński',
    'Pow. Średzki',
    'Pow. Świdnicki',
    'Pow. Wołowski',
    'Pow. Trzebnicki',
    'Pow. Wałbrzyski',
    'Pow. Wrocławski',
    'Pow. Ząbkowicki',
    'Pow. Zgorzelecki',
  ],
  'KUJAWSKO - POMORSKIE': [
    'Bydgoszcz',
    'Grudziądz',
    'Toruń',
    'Włocławek',
    'Pow. Aleksandrowski',
    'Pow. Brodnicki',
    'Pow. Bydgoski',
    'Pow. Chełmiński',
    'Pow. Golubsko-dobrzyński',
    'Pow. Grudziądzki',
    'Pow. Inowrocławski',
    'Pow. Lipnowski',
    'Pow. Mogileński',
    'Pow. Nakielski',
    'Pow. Radziejowski',
    'Pow. Rypiński',
    'Pow. Sępoleński',
    'Pow. Świecki',
    'Pow. Toruński',
    'Pow. Tucholski',
    'Pow. Wąbrzeski',
    'Pow. Włocławski',
    'Pow. Żniński',
  ],
  ŁÓDZKIE: [
    'Łódź',
    'Piotrków Trybunalski',
    'Skierniewice',
    'Pow. Bełchatowski',
    'Pow. Brzeziński',
    'Pow. Kutnowski',
    'Pow. Łaski',
    'Pow. Łęczycki',
    'Pow. Łowicki',
    'Pow. łódzki wschodni',
    'Pow. Opoczyński',
    'Pow. Pabianicki',
    'Pow. Pajęczański',
    'Pow. Piotrkowski',
    'Pow. Poddębicki',
    'Pow. Radomszczański',
    'Pow. Rawski',
    'Pow. Sieradzki',
    'Pow. Skierniewicki',
    'Pow. Tomaszowski',
    'Pow. Wieluński',
    'Pow. Wieruszowski',
    'Pow. Zduńskowolski',
    'Pow. Zgierski',
  ],
  LUBELSKIE: [
    'Biała Podlaska',
    'Chełm',
    'Lublin',
    'Zamość',
    'Pow. Bialski',
    'Pow. Biłgorajski',
    'Pow. Chełmski',
    'Pow. Hrubieszowski',
    'Pow. Janowski',
    'Pow. Krasnostawski',
    'Pow. Kraśnicki',
    'Pow. Lubartowski',
    'Pow. Lubelski',
    'Pow. Łęczyński',
    'Pow. Łukowski',
    'Pow. Opolski',
    'Pow. Parczewski',
    'Pow. Puławski',
    'Pow. Radzyński',
    'Pow. Rycki',
    'Pow. Świdnicki',
    'Pow. Tomaszowski',
    'Pow. Włodawski',
    'Pow. Zamojski',
  ],
  LUBUSKIE: [
    'Gorzów Wielkopolski',
    'Zielona Góra',
    'Pow. Gorzowski',
    'Pow. Krośnieński',
    'Pow. Międzyrzecki',
    'Pow. Nowosolski',
    'Pow. Słubicki',
    'Pow. strzelecko-drezdenecki',
    'Pow. Sulęciński',
    'Pow. Świebodziński',
    'Pow. Wschowski',
    'Pow. Zielonogórski',
    'Pow. Żagański',
    'Pow. Żarski',
  ],
  MAŁOPOLSKIE: [
    'Kraków',
    'Nowy Sącz',
    'Tarnów',
    'Pow. Bocheński',
    'Pow. Brzeski',
    'Pow. Chrzanowski',
    'Pow. Dąbrowski',
    'Pow. Gorlicki',
    'Pow. Krakowski',
    'Pow. Limanowski',
    'Pow. Miechowski',
    'Pow. Myślenicki',
    'Pow. Nowosądecki',
    'Pow. Nowotarski',
    'Pow. Olkuski',
    'Pow. Oświęcimski',
    'Pow. Proszowicki',
    'Pow. Suski',
    'Pow. Tarnowski',
    'Pow. Tatrzański',
    'Pow. Wadowicki',
    'Pow. Wielicki',
  ],
  MAZOWIECKIE: [
    'Ostrołęka',
    'Płock',
    'Radom',
    'Siedlce',
    'Warszawa',
    'Pow. Białobrzeski',
    'Pow. Ciechanowski',
    'Pow. Garwoliński',
    'Pow. Gostyniński',
    'Pow. Grodziski',
    'Pow. Grójecki',
    'Pow. Kozienicki',
    'Pow. Legionowski',
    'Pow. Lipski',
    'Pow. Łosicki',
    'Pow. Makowski',
    'Pow. Miński',
    'Pow. Mławski',
    'Pow. Nowodworski',
    'Pow. Ostrołęcki',
    'Pow. Ostrowski',
    'Pow. Otwocki',
    'Pow. Piaseczyński',
    'Pow. Płocki',
    'Pow. Płoński',
    'Pow. Pruszkowski',
    'Pow. Przasnyski',
    'Pow. Przysuski',
    'Pow. Pułtuski',
    'Pow. Radomski',
    'Pow. Siedlecki',
    'Pow. Sierpecki',
    'Pow. Sochaczewski',
    'Pow. Sokołowski',
    'Pow. Szydłowiecki',
    'Pow. warszawski zachodni',
    'Pow. Węgrowski',
    'Pow. Wołomiński',
    'Pow. Wyszkowski',
    'Pow. Zwoleński',
    'Pow. Żuromiński',
    'Pow. Żyrardowski',
  ],
  OPOLSKIE: [
    'Opole',
    'Pow. Brzeski',
    'Pow. Głubczycki',
    'Pow. kędzierzyńsko-kozielski',
    'Pow. Kluczborski',
    'Pow. Krapkowicki',
    'Pow. Namysłowski',
    'Pow. Nyski',
    'Pow. Oleski',
    'Pow. Opolski',
    'Pow. Prudnicki',
    'Pow. Strzelecki',
  ],
  PODKARPACKIE: [
    'Krosno',
    'Przemyśl',
    'Rzeszów',
    'Tarnobrzeg',
    'Pow. Bieszczadzki',
    'Pow. Brzozowski',
    'Pow. Dębicki',
    'Pow. Jarosławski',
    'Pow. Jasielski',
    'Pow. Kolbuszowski',
    'Pow. Krośnieński',
    'Pow. Leski',
    'Pow. Leżajski',
    'Pow. Lubaczowski',
    'Pow. Łańcucki',
    'Pow. Mielecki',
    'Pow. Niżański',
    'Pow. Przemyski',
    'Pow. Przeworski',
    'Pow. Ropczycko-sędziszowski',
    'Pow. Rzeszowski',
    'Pow. Sanocki',
    'Pow. Stalowowolski',
    'Pow. Strzyżowski',
    'Pow. Tarnobrzeski',
  ],
  PODLASKIE: [
    'Białystok',
    'Łomża',
    'Suwałki',
    'Pow. Augustowski',
    'Pow. Białostocki',
    'Pow. Bielski',
    'Pow. Grajewski',
    'Pow. Hajnowski',
    'Pow. Kolneński',
    'Pow. Łomżyński',
    'Pow. Moniecki',
    'Pow. Sejneński',
    'Pow. Siemiatycki',
    'Pow. Sokólski',
    'Pow. Suwalski',
    'Pow. Wysokomazowiecki',
    'Pow. Zambrowski',
  ],
  POMORSKIE: [
    'Gdańsk',
    'Gdynia',
    'Słupsk',
    'Sopot',
    'Pow. Bytowski',
    'Pow. Chojnicki',
    'Pow. Człuchowski',
    'Pow. Gdański',
    'Pow. Kartuski',
    'Pow. Kościerski',
    'Pow. Kwidzyński',
    'Pow. Lęborski',
    'Pow. Malborski',
    'Pow. Nowodworski',
    'Pow. Pucki',
    'Pow. Słupski',
    'Pow. Starogardzki',
    'Pow. Sztumski',
    'Pow. Tczewski',
    'Pow. Wejherowski',
  ],
  ŚLĄSKIE: [
    'Bielsko-Biała',
    'Bytom',
    'Chorzów',
    'Częstochowa',
    'Dąbrowa Górnicza',
    'Gliwice',
    'Jastrzębie-Zdrój',
    'Jaworzno',
    'Katowice',
    'Mysłowice',
    'Piekary Śląskie',
    'Ruda Śląska',
    'Rybnik',
    'Siemianowice Śląskie',
    'Sosnowiec',
    'Świętochłowice',
    'Tychy',
    'Zabrze',
    'Żory',
    'Pow. Będziński',
    'Pow. Bielski',
    'Pow. bieruńsko-lędziński',
    'Pow. Cieszyński',
    'Pow. Częstochowski',
    'Pow. Gliwicki',
    'Pow. Kłobucki',
    'Pow. Lubliniecki',
    'Pow. Mikołowski',
    'Pow. Myszkowski',
    'Pow. Pszczyński',
    'Pow. Raciborski',
    'Pow. Rybnicki',
    'Pow. Tarnogórski',
    'Pow. Wodzisławski',
    'Pow. Zawierciański',
    'Pow. Żywiecki',
  ],
  ŚWIĘTOKRZYSKIE: [
    'Kielce',
    'Pow. Buski',
    'Pow. Jędrzejowski',
    'Pow. Kazimierski',
    'Pow. Kielecki',
    'Pow. Konecki',
    'Pow. Opatowski',
    'Pow. Ostrowiecki',
    'Pow. Pińczowski',
    'Pow. Sandomierski',
    'Pow. Skarżyski',
    'Pow. Starachowicki',
    'Pow. Staszowski',
    'Pow. Włoszczowski',
  ],
  'WARMIŃSKO - MAZURSKIE': [
    'Elbląg',
    'Olsztyn',
    'Pow. Bartoszycki',
    'Pow. Braniewski',
    'Pow. Działdowski',
    'Pow. Elbląski',
    'Pow. Ełcki',
    'Pow. Giżycki',
    'Pow. Gołdapski',
    'Pow. Iławski',
    'Pow. Kętrzyński',
    'Pow. Lidzbarski',
    'Pow. Mrągowski',
    'Pow. Nidzicki',
    'Pow. Nowomiejski',
    'Pow. Olecki',
    'Pow. Olsztyński',
    'Pow. Ostródzki',
    'Pow. Piski',
    'Pow. Szczycieński',
    'Pow. Węgorzewski',
  ],
  WIELKOPOLSKIE: [
    'Kalisz',
    'Konin',
    'Leszno',
    'Poznań',
    'Pow. Chodzieski',
    'Pow. Czarnkowsko-trzcianecki',
    'Pow. Gnieźnieński',
    'Pow. Gostyński',
    'Pow. Grodziski',
    'Pow. Jarociński',
    'Pow. Kaliski',
    'Pow. Kępiński',
    'Pow. Kolski',
    'Pow. Koniński',
    'Pow. Kościański',
    'Pow. Krotoszyński',
    'Pow. Leszczyński',
    'Pow. Międzychodzki',
    'Pow. Nowotomyski',
    'Pow. Obornicki',
    'Pow. Ostrowski',
    'Pow. Ostrzeszowski',
    'Pow. Pilski',
    'Pow. Pleszewski',
    'Pow. Poznański',
    'Pow. Rawicki',
    'Pow. Słupecki',
    'Pow. Szamotulski',
    'Pow. Średzki',
    'Pow. Śremski',
    'Pow. Turecki',
    'Pow. Wągrowiecki',
    'Pow. Wolsztyński',
    'Pow. Wrzesiński',
    'Pow. Złotowski',
  ],
  ZACHODNIOPOMORSKIE: [
    'Koszalin',
    'Szczecin',
    'Świnoujście',
    'Pow. Białogardzki',
    'Pow. Choszczeński',
    'Pow. Drawski',
    'Pow. Goleniowski',
    'Pow. Gryficki',
    'Pow. Gryfiński',
    'Pow. Kamieński',
    'Pow. Kołobrzeski',
    'Pow. Koszaliński',
    'Pow. Łobeski',
    'Pow. Myśliborski',
    'Pow. Policki',
    'Pow. Pyrzycki',
    'Pow. Sławieński',
    'Pow. Stargardzki',
    'Pow. Szczecinecki',
    'Pow. Świdwiński',
    'Pow. Wałecki',
  ],
}

export const ALL_COUNTIES = Object.values(REGIONS).flat()

export const COUNTRY_WIDE = 'CAŁA POLSKA'

export const REGIONS_WITH_COUNTRY = { [COUNTRY_WIDE]: [], ...REGIONS }
