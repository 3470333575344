import React, { useState, useEffect } from 'react'
import { useUser } from '../../../../context/UserContext'

const AccountStatusTileBody = ({ daysLeft, accountActiveTo }) => {
  const accountStatus = () => {
    const dayLabel = daysLeft === 1 ? 'dzień' : 'dni'

    if (daysLeft <= 0) {
      return (
        <div className="counter-item">
          <h2 className="text-danger">Nieaktywne</h2>
          <p className="para warning-info">
            Twoje konto jest obecnie nieaktywne. Przedłuż swoje konto, aby móc ponownie korzystać z serwisu.
          </p>
        </div>
      )
    } else if (daysLeft < 7) {
      return (
        <div className="counter-item">
          <h2 className="text-danger">Aktywne</h2>
          {daysLeft > 0 && (
            <p style={{ marginBottom: '0' }} className="para">
              {' '}
              do {accountActiveTo.toLocaleDateString('pl-PL')} / pozostało {daysLeft} {dayLabel}
            </p>
          )}
          <p className="para warning-info">
            Do wygaśnięcia ważności Twojego konta pozostało mniej niż 7 dni. Przedłuż ważność swojego konta aby móc
            ponownie korzystać z serwisu.
          </p>
        </div>
      )
    } else if (daysLeft < 30) {
      return (
        <div className="counter-item">
          <h2 className="text-warning">Aktywne</h2>
          {daysLeft > 0 && (
            <p style={{ marginBottom: '0' }} className="para">
              {' '}
              do {accountActiveTo.toLocaleDateString('pl-PL')} / pozostało {daysLeft} {dayLabel}
            </p>
          )}
          <p className="para warning-info">
            Do wygaśnięcia ważności Twojego konta pozostało mniej niż 30 dni. Przedłuż ważność swojego konta aby móc
            ponownie korzystać z serwisu.
          </p>
        </div>
      )
    }
    return (
      <div className="counter-item">
        <h2 className="text-success">Aktywne</h2>
        {daysLeft > 0 && (
          <p style={{ marginBottom: '0' }} className="para">
            {' '}
            do {accountActiveTo.toLocaleDateString('pl-PL')} / pozostało {daysLeft} {dayLabel}
          </p>
        )}
      </div>
    )
  }

  return <>{accountStatus()}</>
}

export default AccountStatusTileBody
