import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../context/AuthContext'
import { URL_PATHS } from '../../../../_constants/urls'
import { useDashboardTab } from '../../../../context/DashboardTabContext'
import { scrollToTop } from '../../../../_helpers'

const InactiveAccountCard = ({ loadingBids }) => {
  const { auth } = useAuth()
  const { activeTab, setActiveTab } = useDashboardTab()

  const renderSkeleton = () => {
    return (
      <div className="card-body">
        <div>
          <h4>
            <Skeleton width={200} />
          </h4>
        </div>
        <div className="product-details-right wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".2s">
          <div className="bid-form">
            <div className="form-title">
              <p>
                <Skeleton count={5} />
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="card mb-3">
      {loadingBids ? (
        renderSkeleton()
      ) : (
        <div className="card-body">
          <div className="sidebar-widget-title">
            <h4>Twoje konto jest nieaktywne</h4>
            <span />
          </div>
          <div className="product-details-right wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".2s">
            <div className="bid-form">
              <div className="form-title">
                <p>
                  Twoje konto jest obecnie nieaktywne. Aby wysłać wycenę na tę fuchę, przedłuż ważność swojego konta.
                </p>
                {auth?.user.is_contractor && (
                  <Link
                    style={{ width: '180px', padding: '5px 12px', margin: '20px 0' }}
                    className="eg-btn btn--primary header-btn"
                    to={`${URL_PATHS.DASHBOARD}`}
                    onClick={() => {
                      setActiveTab('panel')
                      scrollToTop()
                    }}
                  >
                    Przejdź do płatności
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default InactiveAccountCard
