import React from 'react'
import { Link } from 'react-router-dom'
import { URL_PATHS } from '../../../_constants/urls'
import { scrollToTop } from '../../../_helpers'

const InfoAfterSignup = () => {
  return (
    <div className="login-section">
      <img alt="images" src={process.env.PUBLIC_URL + '/images/bg/client-bg.svg'} className="img-fluid section-bg" />
      <img
        alt="images"
        src={process.env.PUBLIC_URL + '/images/bg/client-bg-small.svg'}
        className="img-fluid section-bg-small"
      />
      <div className="container">
        <div className="row d-flex justify-content-center g-4">
          <div className="col-xl-6 col-lg-8 col-md-10">
            <div className="form-wrapper wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".2s">
              <div className="form-title">
                <h3>Dziękujemy za rejestrację!</h3>
              </div>
              <div className="form-title reset-form-title">
                <p className="para p-2 mb-0">
                  Sprawdź swoją skrzynkę email (również folder spam) i <b>kliknij link aktywacyjny</b>, aby potwierdzić
                  swój email.
                </p>
                <p className="para p-2 mb-0">
                  <b>Pamiętaj!</b> Dopiero <b>po aktywacji</b> możliwe będzie zalogowanie się na swoje konto.
                </p>
                <div className="col-12 d-flex justify-content-center">
                  <Link
                    to={URL_PATHS.LOGIN}
                    onClick={() => scrollToTop()}
                    className="eg-btn btn--primary card--btn p-2 mx-auto mt-4"
                  >
                    Przejdź do logowania
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InfoAfterSignup
