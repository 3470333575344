import React, { useState, useEffect } from 'react'
import ChatWrapper from './ChatWrapper'
import { useUser } from '../../../context/UserContext'
import { useChatActions } from '../../../_actions'
import { ACCOUNT_STATUS } from '../../../_constants/user'
import InactiveAccountMessage from './InactiveAccountMessage'
import LoadingIndicator from './LoadingIndicator'

const Chat = () => {
  const { user } = useUser()
  const [token, setToken] = useState(null)
  const { getChatToken } = useChatActions()
  const activeAccount = user?.account_status === ACCOUNT_STATUS.ACTIVE
  const contractor = user?.is_contractor === true
  const badgeElement = document.getElementById('cookiescript_badge')

  if (badgeElement) {
    badgeElement.classList.add('d-none')
  }

  useEffect(() => {
    if (contractor && !activeAccount) return
    getChatToken()
      .then((data) => {
        setToken(data.token)
      })
      .catch((error) => {
        console.error('Error fetching chat token:', error)
      })
  }, [])

  if (contractor && !activeAccount) {
    return <InactiveAccountMessage />
  }

  if (!token || !user) {
    return <LoadingIndicator />
  }

  return <ChatWrapper user={user} token={token} />
}

export default Chat
