import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useFuchaActions, useContractorActions } from '../../../../_actions/'
import { SendBidSchema } from '../schema'
import { useAlerts } from '../../../../context/AlertContext'
import ConfirmModal from '../../../common/ConfirmModal'

const PlaceBidCard = ({ fucha, bids, setBids, loadingBids, onBidAdded }) => {
  const { addAlert } = useAlerts()
  const { placeBid } = useFuchaActions()
  const { getMyBids } = useContractorActions()

  const [showModal, setShowModal] = useState(false)

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    resolver: zodResolver(SendBidSchema),
  })

  const handleSubmitBid = (values) => {
    const payload = {
      fucha_id: fucha.id,
      bid: values.bid,
      message: values.message,
    }
    placeBid(payload)
      .then((data) => {
        setBids((prev) => {
          const updatedBids = prev.map((bid) => ({ ...bid, is_deleted: true }))
          return [{ ...data, is_deleted: false }, ...updatedBids]
        })

        reset()
        addAlert('Twoja wycena została wysłana, a zleceniodawca zostanie niedługo poinformowany.', 'success')
        onBidAdded()
      })
      .catch((err) => {
        if (err?.response?.data?.display) {
          addAlert(err.response.data.detail, 'error')
        } else {
          addAlert('Nie udało się wysłać wyceny. Spróbuj ponownie później...', 'error')
        }
      })
      .finally(() => {
        setShowModal(false)
      })
  }

  const renderConfirmationModal = () => {
    const amount = watch('bid')
    return (
      <ConfirmModal
        header="Potwierdzenie wyceny"
        showModal={showModal}
        handleClose={handleCloseModal}
        handleConfirm={handleSubmit(handleSubmitBid)}
        size="lg"
        className="confirm-bid-modal"
      >
        <p className="para">
          Twoja wycena to <b>{amount} zł</b>. Czy na pewno chcesz wysłać tę wycenę?
        </p>
      </ConfirmModal>
    )
  }

  return (
    <>
      <div className="card mb-3" style={{ marginRight: '0' }}>
        <div className="card-body">
          <div className="sidebar-widget-title">
            <h4>Wyślij wycenę</h4>
            <span />
          </div>
          <div className="product-details-right  wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".2s">
            {loadingBids ? (
              <div className="bid-form">
                <Skeleton height={200} />
              </div>
            ) : (
              <div className="bid-form">
                {bids.length < 2 ? (
                  <>
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="form-inner gap-2">
                        <input
                          {...register('bid')}
                          id="bid"
                          name="bid"
                          type="text"
                          onKeyDown={(event) => {
                            const allowedKeys = ['Enter', 'Backspace', 'Escape']
                            if (!/[0-9]/.test(event.key) && !allowedKeys.includes(event.key)) {
                              event.preventDefault()
                            }
                          }}
                          placeholder="0 zł"
                          required
                        />

                        <button
                          onClick={() => {
                            const amount = watch('bid')
                            if (amount && amount > 0) {
                              setShowModal(true)
                            } else {
                              setShowModal(false)
                            }
                          }}
                          className="eg-btn btn--primary btn--sm"
                        >
                          Wyślij wycenę
                        </button>
                      </div>
                      {errors.bid && <div className="error-message">{errors.bid.message}</div>}
                    </form>
                    <div className="extra-info-container">
                      <p className="bid-para">
                        Liczba wystawionych wycen: <b>{bids.length}</b>
                      </p>
                      {bids.length === 1 && (
                        <p className="bid-para">
                          Twoja poprzednia wycena to: <b>{bids[0].bid} zł</b>
                        </p>
                      )}
                      <span>*Pamiętaj! Możesz wystawić maksymalnie dwie wyceny!</span>
                    </div>
                  </>
                ) : (
                  <div className="extra-info-container">
                    <p className="para">Niestety złożyłeś już maksymalną liczbę wycen.</p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {showModal && renderConfirmationModal()}
    </>
  )
}

export default PlaceBidCard
