import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Form from '../../common/Form'
import Input from '../../common/Input'
import { Avatar } from '@files-ui/react'
import { useApi } from '../../../_helpers'
import { useUser } from '../../../context/UserContext'
import { useForm, watch, Controller } from 'react-hook-form'
import { useUserActions } from '../../../_actions/user.actions'
import { useAlerts } from '../../../context/AlertContext'
import { zodResolver } from '@hookform/resolvers/zod'
import Select from 'react-select'
import { MAX_IMAGE_SIZE, MAX_IMAGE_SIZE_MB } from '../../../_constants'
import { API_BASE_URL, API_URLS } from '../../../_constants'
import AccountIcon from '../../../assets/images/icons/account.svg'
import PersonalDetailsForm from './accountUpdateForms/PersonalDetailsForm'
import AddressForm from './accountUpdateForms/AddressForm'
import UpdatePasswordForm from './accountUpdateForms/UpdatePasswordForm'
import MarketingAndNotificationForm from './accountUpdateForms/MarketingAndNotificationForm'
import DeleteAccountForm from './accountUpdateForms/DeleteAccountForm'

const ContentOfMyAccount = ({ user }) => {
  const { api } = useApi()
  const { setUser } = useUser()
  const [avatar, setAvatar] = useState(user.profile_image_thumbnail ? `${user.profile_image_thumbnail}` : AccountIcon)
  const [loadingAvatar, setLoadingAvatar] = useState(false)
  const { updateAccountDetails } = useUserActions()
  const { addAlert } = useAlerts()
  const [counties, setCounties] = useState([])
  const [isCountyDisabled, setIsCountyDisabled] = useState(true)
  const [isCityDisabled, setIsCityDisabled] = useState(true)

  const handleAvatarChange = (file) => {
    if (!file) return
    if (file.size > MAX_IMAGE_SIZE) {
      addAlert(`Rozmiar pliku nie może przekroczyć ${MAX_IMAGE_SIZE_MB}MB`, 'error')
      return
    }
    setLoadingAvatar(true)
    const formData = new FormData()
    formData.append('file', file, file.name)

    api
      .post(API_URLS.UPDATE_AVATAR, formData, {})
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setAvatar(`${response.data.profile_image_thumbnail}`)
          setUser({
            ...user,
            profile_image_thumbnail: response.data.profile_image_thumbnail,
            profile_image: response.data.profile_image,
          })
          addAlert('Twoje zdjęcie zostało zaktualizowane', 'success')
        }
        setLoadingAvatar(false)
      })
      .catch((err) => {
        addAlert('Wystąpił błąd podczas aktualizacji zdjęcia', 'error')
        setLoadingAvatar(false)
      })
  }

  return (
    <>
      <div className="tab-pane fade show" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
        <div className="dashboard-profile">
          <div className="owner">
            <div className="image">
              <Avatar
                accept=".jpg, .png"
                alt="Avatar"
                changeLabel={'Zmień zdjęcie'}
                isLoading={loadingAvatar}
                loadingLabel={null}
                onChange={(imgSource) => handleAvatarChange(imgSource)}
                onError={() => setAvatar(AccountIcon)}
                src={avatar}
                style={{ maxWidth: '100px', height: '100px', backgroundColor: '#fff' }}
                variant="circle"
              />
            </div>
            <div className="content">
              <h3>
                {user.first_name} {user.last_name}
              </h3>
            </div>
          </div>
          <div className="form-wrapper">
            <PersonalDetailsForm user={user} />
            <AddressForm user={user} />
            <UpdatePasswordForm user={user} />
            <MarketingAndNotificationForm user={user} />
            <DeleteAccountForm user={user} />
          </div>
        </div>
      </div>
    </>
  )
}
export default ContentOfMyAccount

ContentOfMyAccount.propsTypes = {
  user: PropTypes.object.isRequired,
}
