import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import FuchaCard from '../fuchaListing/FuchaCard'
import { useFuchaActions } from '../../../_actions'
import { URL_PATHS } from '../../../_constants/urls'
// import Swiper core and required modules
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, Navigation } from 'swiper'
import SkeletonFuchaCard from '../../common/SkeletonFuchCard'
import { v4 as uuidv4 } from 'uuid'

const upComingSlider = {
  slidesPerView: 1,
  speed: 1000,
  spaceBetween: 24,
  loop: true,
  roundLengths: true,
  pagination: {
    el: '.swiper-pagination',
    clickable: 'true',
  },
  navigation: {
    nextEl: '.coming-prev2',
    prevEl: '.coming-next2',
  },

  breakpoints: {
    280: {
      slidesPerView: 1,
      pagination: false,
    },
    480: {
      slidesPerView: 1,
      pagination: false,
    },
    768: {
      slidesPerView: 2,
      pagination: false,
    },
    992: {
      slidesPerView: 2,
    },
    1200: {
      slidesPerView: 3,
    },
  },
}

SwiperCore.use([Navigation, Autoplay])

const EndingSoon = () => {
  const { getFuchaEndingSoon } = useFuchaActions()
  const [loading, setLoading] = useState(true)
  const [fuchaLatest, setFuchaLatest] = useState([])
  const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })

  useEffect(() => {
    getFuchaEndingSoon()
      .then((resp) => {
        setFuchaLatest([...resp])
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const getSkeletonFuchaCards = () => {
    const cards = []
    for (let i = 0; i < 3; i++) {
      cards.push(
        <div key={i} className="col-lg-4 col-md-6 col-sm-10">
          <SkeletonFuchaCard />
        </div>
      )
    }
    return cards
  }

  return (
    <>
      <div className="upcoming-seciton ending-soon pt-120 pb-120">
        <img
          alt="images"
          src={process.env.PUBLIC_URL + '/images/bg/section-bg2.png'}
          className="img-fluid section-bg2"
        />
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center mb-60 g-4">
            <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6">
              <div className="section-title2 text-lg-start text-center">
                <h2>Kończące się Fuchy</h2>
                <p className="mb-0">Zgarnij kończące się fuchy i zdobądź zlecenia, zanim będzie za późno!</p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-4 text-lg-end text-center">
              <Link to={URL_PATHS.FUCHA_LISTING} onClick={scrollTop} className="eg-btn btn--primary2 btn--md">
                Przeglądaj Fuchy
              </Link>
            </div>
            <div className="col-xl-6 col-lg-4 d-lg-block w-100">
              <div className="slider-bottom d-flex justify-content-end align-items-center">
                <div className="slider-arrows coming-arrow d-flex justify-content-end gap-3">
                  <div
                    className="coming-prev2 swiper-prev-arrow"
                    tabIndex={0}
                    role="button"
                    aria-label="Previous slide"
                  >
                    <i className="bi bi-arrow-left" />
                  </div>
                  <div className="coming-next2 swiper-next-arrow" tabIndex={0} role="button" aria-label="Next slide">
                    {' '}
                    <i className="bi bi-arrow-right" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <Swiper {...upComingSlider} className="swiper upcoming-slider2">
              <div className="swiper-wrapper">
                {loading
                  ? getSkeletonFuchaCards()
                  : fuchaLatest.map((fucha) => {
                      let key = `fucha-${uuidv4()}`
                      return (
                        <div key={key} className="col col-sm-10">
                          <SwiperSlide key={key} className="swiper-slide">
                            <FuchaCard fucha={fucha} isEndingSoon={true} />
                          </SwiperSlide>
                        </div>
                      )
                    })}
              </div>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  )
}

export default EndingSoon
