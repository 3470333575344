import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { PersonalInfoSchema } from './schemas/personalSchema'
import Form from '../../../common/Form'
import Input from '../../../common/Input'
import { useApi } from '../../../../_helpers'
import { useForm, watch, Controller } from 'react-hook-form'
import { useUserActions } from '../../../../_actions/user.actions'
import { useAlerts } from '../../../../context/AlertContext'
import { zodResolver } from '@hookform/resolvers/zod'
import { useUser } from '../../../../context/UserContext'

const PersonalDetailsForm = ({ user }) => {
  const { setUser } = useUser()
  const { api } = useApi()

  const { updateAccountDetails } = useUserActions()
  const { addAlert } = useAlerts()

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    control,
    watch,
    setValue,
    resetField,
  } = useForm({
    resolver: zodResolver(PersonalInfoSchema),
  })

  const onSubmit = async (data) => {
    const payload = {
      first_name: data.firstName,
      last_name: data.lastName,
      phone_number: data.phoneNumber,
    }

    if (user.phone_number !== data.phoneNumber) {
      setUser({
        ...user,
        ...payload,
        is_phone_verified: false,
      })
    } else {
      setUser({
        ...user,
        ...payload,
      })
    }

    try {
      await updateAccountDetails(payload)
      addAlert('Twoje konto zostało zaktualizowane pomyślnie!', 'success')
    } catch (error) {
      addAlert('Wystąpił błąd podczas aktualizacji konta. Spróbuj ponownie później.', 'error')
    }
  }

  return (
    <Form
      buttonLabel="Aktualizuj"
      register={register}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      className="eg-btn profile-btn"
      isSubmitting={isSubmitting}
    >
      <div className="form-section-title">
        <p>Dane osobowe</p>
      </div>
      <Input
        containerClassName={'col-xl-6 col-lg-12 col-md-6 text-start'}
        name="firstName"
        type="text"
        label="Imię"
        placeholder="Imię"
        error={errors.firstName?.message}
        defaultValue={user.first_name || ''}
      />
      <Input
        containerClassName={'col-xl-6 col-lg-12 col-md-6 text-start'}
        name="lastName"
        type="text"
        label="Nazwisko"
        placeholder="Nazwisko"
        error={errors.lastName?.message}
        defaultValue={user.last_name || ''}
      />
      <Input
        containerClassName={'col-xl-6 col-lg-12 col-md-6 text-start'}
        name="phoneNumber"
        label="Numer telefonu"
        type="text"
        placeholder="Numer Telefonu"
        error={errors.phoneNumber?.message}
        defaultValue={user.phone_number || ''}
      />
      <Input
        containerClassName={'col-xl-6 col-lg-12 col-md-6 text-start'}
        label="Adres Email"
        name="email"
        type="email"
        disabled={true}
        placeholder="Adres Email"
        error={errors.email?.message}
        defaultValue={user.email || ''}
      />
    </Form>
  )
}
export default PersonalDetailsForm

PersonalDetailsForm.propsTypes = {
  user: PropTypes.object.isRequired,
}
