import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useClientActions } from '../../../_actions'
import { useDashboardTab } from '../../../context/DashboardTabContext'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { DYNAMIC_URL_PATHS } from '../../../_constants'
import ConfirmModal from '../../common/ConfirmModal'
import ReactPaginate from 'react-paginate'
import { getPageCount, getToPageNumber, getFromPageNumber } from '../../../_helpers'
import FuchaImage from '../../../assets/images/fucha/fucha-image.png'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { setQueryParam } from '../../../_helpers'
import { FUCHA_STATUS, FUCHA_CANCELLABLE_STATUSES, FUCHA_DELETABLE_STATUSES } from '../../../_constants'
import { useAlerts } from '../../../context/AlertContext'

const fuchaStatusMapping = {
  aktywna: 'finished',
  nieaktywna: 'inactive',
  zakończona: 'active',
  zarchiwizowana: 'cancelled',
  przedawniona: 'cancelled',
  anulowana: 'cancelled',
}

const ContentOfMyFuchyClient = () => {
  const { addAlert } = useAlerts()
  const { activeTab, setActiveTab } = useDashboardTab()
  const { getMyFuchy, cancelFucha } = useClientActions()
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [filter, setFilter] = useState('all')
  const [fuchy, setFuchy] = useState([])
  const [fuchaToDelete, setFuchaToDelete] = useState(null)
  const [page, setPage] = useState(1)
  const [fuchyCount, setFuchyCount] = useState(0)
  const perPage = 6

  const fetchFuchy = () => {
    getMyFuchy(page, filter)
      .then((resp) => {
        setFuchy(resp.items)
        setFuchyCount(resp.count)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    fetchFuchy()
  }, [page])

  const handlePageClick = (e) => {
    setPage(e.selected + 1)
  }

  const handleDeleteFucha = (setIsConfirming) => {
    const isCancellable = FUCHA_CANCELLABLE_STATUSES.includes(fuchaToDelete.status)

    cancelFucha(fuchaToDelete.id)
      .then(() => {
        setLoading(true)
        fetchFuchy()
        if (isCancellable) {
          addAlert('Fucha została anulowana', 'success')
        } else {
          addAlert('Fucha została usunięta', 'success')
        }
      })
      .catch((error) => {
        if (isCancellable) {
          addAlert('Nie udało się anulować fuchy!', 'error')
        } else {
          addAlert('Nie udało się usunąć fuchy!', 'error')
        }
      })
      .finally(() => {
        setFuchaToDelete(false)
        setShowModal(false)
        setIsConfirming(false)
      })
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const renderSkeletonRows = () => {
    const skeletonRows = []

    for (let i = 0; i < 6; i++) {
      skeletonRows.push(
        <div key={i} className="col-lg-12 col-md-12 col-sm-12 my-fucha-list-item container">
          <div className="row">
            <div className="col-lg-12 mx-auto">
              <div className="list-group flex-row mb-4">
                <div className="list-group-item w-100">
                  <div className="info-container">
                    <Skeleton width={200} />
                  </div>
                  <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                    <div className="media-body order-2 order-lg-1">
                      <h5 className="mt-0 font-weight-bold mb-2">
                        <Skeleton width={250} />
                      </h5>
                      <p className="font-italic text-muted mb-0  mt-2 small">
                        <Skeleton width={250} />
                      </p>
                      <p className="font-italic text-muted mb-0 small">
                        <Skeleton width={250} />
                        <Skeleton width={250} />
                      </p>

                      <div className="d-flex align-items-center justify-content-between mt-3">
                        <Skeleton width={125} height={35} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="my-fucha-image-container">
                  <Skeleton className="ml-lg-5 order-1 order-lg-2 fucha-image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return skeletonRows
  }

  return (
    <>
      {fuchy.length === 0 && !loading ? (
        <div className="table-wrapper">
          <p style={{ marginBottom: '0', padding: '20px', fontWeight: 'bold' }} className="para text-center">
            Brak zleconych fuch
          </p>
        </div>
      ) : loading ? (
        renderSkeletonRows()
      ) : (
        fuchy.map((fucha, index) => {
          return (
            <div key={index} className="col-lg-12 col-md-12 col-sm-12 my-fucha-list-item container">
              <Tooltip anchorselect={`#delete-fucha-${fucha.id}`} place="top">
                Usuń fuchę
              </Tooltip>
              <div className="row my-fuchy-list-item">
                <div className="text-part col-lg-8 col-md-12 p-0">
                  <div className={`info-container ${fuchaStatusMapping[fucha.status]}`}>
                    <h6 className="font-weight-bold my-2 status-tag">{fucha.status}</h6>
                    {FUCHA_CANCELLABLE_STATUSES.includes(fucha.status) && (
                      <>
                        <OverlayTrigger
                          key={`cancel-fucha-${fucha.id}`}
                          placement="top"
                          overlay={<Tooltip id={`cancel-fucha-${fucha.id}`}>Anuluj Fuchę</Tooltip>}
                        >
                          <img
                            id={`cancel-fucha-${fucha.id}`}
                            className="delete-fucha-icon"
                            src={process.env.PUBLIC_URL + '/images/icons/cancel-icon.svg'}
                            alt=""
                            onClick={() => {
                              setShowModal(true)
                              setFuchaToDelete(fucha)
                            }}
                          />
                        </OverlayTrigger>
                      </>
                    )}
                    {FUCHA_DELETABLE_STATUSES.includes(fucha.status) && (
                      <OverlayTrigger
                        key={`delete-fucha-${fucha.id}`}
                        placement="top"
                        overlay={<Tooltip id={`delete-fucha-${fucha.id}`}>Usuń Fuchę</Tooltip>}
                      >
                        <img
                          id={`delete-fucha-${fucha.id}`}
                          className="delete-fucha-icon"
                          src={process.env.PUBLIC_URL + '/images/icons/delete-icon.svg'}
                          alt=""
                          onClick={() => {
                            setFuchaToDelete(fucha)
                            setShowModal(true)
                          }}
                        />
                      </OverlayTrigger>
                    )}
                  </div>

                  <div className="media align-items-lg-center flex-column flex-lg-row">
                    <div className="media-body order-2 order-lg-1">
                      <h5 className="font-weight-bold mb-2">{fucha.title}</h5>
                      <p className="font-italic text-muted mb-0 mt-2 small text-truncate">{fucha.description}</p>
                      <p className="font-italic text-muted mb-0  mt-2 small">
                        {' '}
                        <span className="span-text">Kategoria:</span>
                        {fucha.categories.slice(0, 4).map((category, index) => (
                          <span key={index}>
                            {category.name}
                            {index < Math.min(fucha.categories.length, 4) - 1 ? ', ' : ''}
                          </span>
                        ))}
                        {fucha.categories.length >= 4 && <span> ...</span>}
                      </p>
                      <p className="font-italic text-muted mb-0 small">
                        {' '}
                        <span className="span-text">Zakres Prac:</span>
                        {fucha.types_of_work.slice(0, 4).map((type, index) => (
                          <span key={index}>
                            {type.name}
                            {index < Math.min(fucha.types_of_work.length, 4) - 1 ? ', ' : ''}
                          </span>
                        ))}
                        {fucha.types_of_work.length >= 4 && <span> ...</span>}
                      </p>
                      <p className="font-italic text-muted mb-0 small">
                        {' '}
                        <span className="span-text">Liczba wycen:</span>
                        <span>{fucha.bid_count}</span>
                      </p>

                      <div className="d-flex align-items-center justify-content-between mt-3">
                        {/* <h6 className="font-weight-bold my-2">Status: {fucha.status}</h6> */}
                        {fucha.status === FUCHA_STATUS.INACTIVE ? (
                          <button
                            className="eg-btn btn--primary btn--sm btn-inactive"
                            onClick={(e) => {
                              e.preventDefault()
                              setQueryParam('fuchaId', fucha.id)
                              setActiveTab('tab21')
                            }}
                          >
                            Dokończ Fuchę
                          </button>
                        ) : (
                          <div className="my-fucha-link">
                            <Link
                              to={DYNAMIC_URL_PATHS.FuchaDetail(fucha.id)}
                              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                              className={`eg-btn btn--${fucha.status == FUCHA_STATUS.FINISHED ? 'primary' : fucha.status == FUCHA_STATUS.ACTIVE ? 'success' : 'dark'} btn--sm`}
                            >
                              Zobacz Fuchę
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-12 p-0">
                  <div className="my-fucha-image-container">
                    <img
                      className="ml-lg-5 order-1 order-lg-2 fucha-image"
                      src={fucha.image ? `${fucha.image}` : FuchaImage}
                      alt={`Fucha ${index + 1}`}
                    />
                  </div>
                </div>
              </div>
            </div>
          )
        })
      )}

      <div className="table-pagination">
        <p>
          <b>Liczba Fuch: {fuchyCount}</b> <br />
          {fuchyCount > 0 &&
            `Wyświetlasz od ${getFromPageNumber(page, perPage)} do ${getToPageNumber(page, perPage, fuchyCount)}`}
        </p>
        <nav className="pagination-wrap">
          <ul className="pagination style-two d-flex justify-content-center gap-md-3 gap-2">
            <ReactPaginate
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={getPageCount(fuchyCount, perPage)}
              previousLabel="<"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
              forcePage={page > 0 ? page - 1 : 0}
            />
          </ul>
        </nav>
      </div>
      <ConfirmModal
        header={fuchaToDelete?.status === FUCHA_STATUS.CANCELLED ? 'Usuwanie fuchy' : 'Anulowanie fuchy'}
        showModal={showModal}
        handleClose={handleCloseModal}
        handleConfirm={handleDeleteFucha}
        size="lg"
      >
        {fuchaToDelete?.status !== FUCHA_STATUS.CANCELLED ? (
          <p className="para">
            Czy na pewno chcesz anulować tę fuche? Pamiętaj, że tej operacji nie można cofnąć, a wszystkie wyceny
            zostaną odrzucone.
          </p>
        ) : (
          <p className="para">Czy na pewno chcesz usunąć tę fuchę?</p>
        )}
      </ConfirmModal>
    </>
  )
}

export default ContentOfMyFuchyClient
