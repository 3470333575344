import React, { useState, useEffect } from 'react'
import Tile from '../../common/Tile'
import ProductChoiceTileBody from './tiles/ProductChoiceTileBody'
import PaymentHistoryTileBody from './tiles/PaymentHistoryTileBody'
import AccountStatusTileBody from './tiles/AccountStatusTileBody'
import { useAlerts } from '../../../context/AlertContext'
import { getQueryParam, removeQueryParam } from '../../../_helpers'
import { ReactComponent as AccountStatusIcon } from '../../../assets/images/icons/account-status.svg'

const ContentOfPayments = ({ user }) => {
  const { addAlert } = useAlerts()
  const accountActiveTo = new Date(user.account_active_to)
  const today = new Date()

  // Set both dates to midnight
  accountActiveTo.setHours(0, 0, 0, 0)
  today.setHours(0, 0, 0, 0)

  const oneDay = 1000 * 60 * 60 * 24
  const daysLeft = Math.round((accountActiveTo - today) / oneDay)

  useEffect(() => {
    const successQueryParam = getQueryParam('success')
    if (successQueryParam === 'True') {
      addAlert('Twoje konto zostało przedłużone', 'success')
    } else if (successQueryParam === 'False') {
      addAlert('Wystąpił błąd podczas płatności', 'error', null)
    }
    removeQueryParam('success')
  }, [])

  return (
    <div className="tab-pane fade show" role="tabpanel" aria-labelledby="v-pills-profile-tab">
      <div className="dashboard-profile">
        <div
          className="tab-pane fade show active"
          id="v-pills-dashboard"
          role="tabpanel"
          aria-labelledby="v-pills-dashboard-tab"
        >
          <div className="dashboard-area dashboard-work-type box--shadow">
            <div className="row g-4">
              <Tile bodyClassName="payment-status-account" title="Status Konta" col={12}>
                <AccountStatusTileBody daysLeft={daysLeft} accountActiveTo={accountActiveTo} />
                <div className="icon">
                  <AccountStatusIcon width={50} height={50} />
                </div>
              </Tile>
              <Tile bodyClassName="payment-body" title="Przedłuż swoje konto" col={12}>
                <ProductChoiceTileBody user={user} />
              </Tile>

              <Tile bodyClassName="payment-history-body" title="Historia Płatności" col={12}>
                <PaymentHistoryTileBody />
              </Tile>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContentOfPayments
