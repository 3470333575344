import React, { useEffect, useState } from 'react'
import AccountIcon from '../../../../assets/images/icons/account.svg'
import { getFormattedDate } from '../../../../_helpers'
import { useAuth } from '../../../../context/AuthContext'
import { useFuchaActions, useContractorActions, useClientActions } from '../../../../_actions'
import { renderStarRating } from '../../../../_helpers'
import { useAlerts } from '../../../../context/AlertContext'
import { history } from '../../../../_helpers'
import { STAR_COLORS } from '../../../../_constants/values'
import ConfirmModal from '../../../common/ConfirmModal'
import UserReviewsSummaryModal from '../../../common/UserReviewsSummaryModal'
import { Rating } from 'react-simple-star-rating'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { ACCOUNT_STATUS } from '../../../../_constants/user'

const FuchaOwnerInfoCard = ({ fucha, maybeUser, disabledFuchaAction }) => {
  const { addAlert, displayAPIAlert } = useAlerts()
  const { createClientReview } = useContractorActions()
  const { getClientReviewsSummary } = useClientActions()
  const { getClientDetails, startChat } = useFuchaActions()

  const { auth } = useAuth()
  const [clientDetails, setClientDetails] = useState({})
  const [showReviewModal, setShowReviewModal] = useState(false)
  const [showReviewSummaryModal, setShowReviewSummaryModal] = useState(false)
  const [reviewsSummary, setReviewsSummary] = useState({})
  const [ratingValues, setRatingValues] = useState({
    contact: 0,
    descriptionAccuracy: 0,
    payment: 0,
  })
  const owner = fucha.user
  const ownerId = fucha.user_id

  const isContractor = auth?.user?.is_contractor || false
  const isAcceptedBidContractor = fucha?.accepted_bid?.contractor?.id === maybeUser.id
  const isActiveContractor = maybeUser?.is_contractor === true && maybeUser?.account_status === ACCOUNT_STATUS.ACTIVE

  const handleChatStart = () => {
    if (!isContractor) {
      addAlert('Nie posiadasz uprawnień do chatu z właścicielem fuchy', 'error')
      return
    }
    startChat(fucha.id, maybeUser.id)
      .then((data) => {
        history.push('/chat?chatId=' + data.channel_id)
      })
      .catch((err) => {
        addAlert('Wystąpił błąd podczas tworzenia chatu', 'error')
      })
  }

  const handleAddReviewForClient = (setIsConfirming) => {
    const payload = {
      fucha_id: fucha.id,
      payment: ratingValues.payment,
      contact: ratingValues.contact,
      description_accuracy: ratingValues.descriptionAccuracy,
    }

    if (Object.values(ratingValues).some((value) => value === 0)) {
      addAlert('Proszę wybrać ocenę od 1 do 5 przed wystawieniem opinii.', 'error')
      setIsConfirming(false)
      return
    }

    createClientReview(payload)
      .then((data) => {
        addAlert('Recenzja została wysłana pomyślnie!', 'success')
        setClientDetails((prev) => ({ ...prev, has_review: true }))
      })
      .catch((err) => {
        displayAPIAlert(err, 'Nie udało się wysłać recenzji!')
      })
      .finally(() => {
        handleCloseReviewModal()
        setIsConfirming(false)
      })
  }

  const handleCloseReviewModal = () => {
    setShowReviewModal(false)
  }

  const handleCloseReviewSummaryModal = () => {
    setShowReviewSummaryModal(false)
    setReviewsSummary({})
  }

  const handleOpenReviewSummaryModal = () => {
    if (owner.average_score > 0) {
      setShowReviewSummaryModal(true)
      getClientReviewsSummary(ownerId)
        .then((data) => {
          setReviewsSummary({
            ...data,
            descriptionAccuracy: data.description_accuracy,
          })
        })
        .catch((err) => {
          displayAPIAlert(err, 'Wystąpił błąd podczas pobierania danych')
        })
    }
  }

  useEffect(() => {
    if (isAcceptedBidContractor) {
      getClientDetails(fucha.id)
        .then((data) => {
          setClientDetails(data)
        })
        .catch((err) => {
          displayAPIAlert(err, 'Wystąpił błąd podczas pobierania danych')
        })
    }
  }, [])

  const renderReviewModal = () => {
    return (
      <ConfirmModal
        header="Oceń tego klienta!"
        showModal={showReviewModal}
        handleClose={handleCloseReviewModal}
        handleConfirm={handleAddReviewForClient}
        size="lg"
        className="client-review-modal"
      >
        <img src={process.env.PUBLIC_URL + '/images/bg/client-bg.png'} className="review-bg" alt="" />
        <div className="row align-items-center flex-column review-container">
          <div className="col-lg-12 w-100">
            <div className="d-flex justify-content-center align-items-center h-100 w-100">
              <div className="image-container">
                <img
                  id={`client-img-${ownerId}`}
                  src={owner.image ? owner.image : AccountIcon}
                  alt="Admin"
                  className="rounded-circle"
                  width="110"
                />
              </div>
            </div>
          </div>

          <div className="col-lg-12 w-100">
            <div className="d-flex flex-column align-items-center h-100 w-100 user-name-box">
              <p>{owner.first_name}</p>
            </div>
          </div>

          <div className="col-lg-12 w-100" style={{ paddingBottom: '20px' }}>
            <div className="d-flex flex-column align-items-center h-100 w-100">
              <div className="rating-box">
                <div className="text-part">
                  <p>Kontakt z klientem</p>
                </div>
                <div className="stars-part">
                  <Rating
                    fillColorArray={[
                      STAR_COLORS.poor,
                      STAR_COLORS.belowAverage,
                      STAR_COLORS.average,
                      STAR_COLORS.good,
                      STAR_COLORS.excellent,
                    ]}
                    tooltipDefaultText="Kontakt z klientem"
                    titleSeparator="z"
                    size={23}
                    onClick={(rating) => setRatingValues((prev) => ({ ...prev, contact: rating }))}
                    value={ratingValues.contact}
                  />
                </div>
              </div>
              <div className="rating-box">
                <div className="text-part">
                  <p>Płatność za usługę</p>
                </div>
                <div className="stars-part">
                  <Rating
                    fillColorArray={[
                      STAR_COLORS.poor,
                      STAR_COLORS.belowAverage,
                      STAR_COLORS.average,
                      STAR_COLORS.good,
                      STAR_COLORS.excellent,
                    ]}
                    tooltipDefaultText="Płatność za usługę"
                    titleSeparator="z"
                    size={23}
                    onClick={(rating) => setRatingValues((prev) => ({ ...prev, payment: rating }))}
                    value={ratingValues.payment}
                  />
                </div>
              </div>
              <div className="rating-box">
                <div className="text-part">
                  <p>Zgodność opisu fuchy</p>
                </div>
                <div className="stars-part">
                  <Rating
                    fillColorArray={[
                      STAR_COLORS.poor,
                      STAR_COLORS.belowAverage,
                      STAR_COLORS.average,
                      STAR_COLORS.good,
                      STAR_COLORS.excellent,
                    ]}
                    tooltipDefaultText="Zgodność opisu fuchy"
                    titleSeparator="z"
                    size={23}
                    onClick={(rating) => setRatingValues((prev) => ({ ...prev, descriptionAccuracy: rating }))}
                    value={ratingValues.descriptionAccuracy}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ConfirmModal>
    )
  }

  return (
    <div className={`card ${disabledFuchaAction ? 'expired-fucha' : ''}`}>
      <div className="card-body">
        <div className="sidebar-widget-title">
          <h4>Poznaj Właściciela Fuchy</h4>
          <span />
        </div>
        <div className="image-container">
          <div className="image-box">
            <img src={owner.image ? owner.image : AccountIcon} alt="Admin" className="rounded-circle" width="100" />
          </div>
        </div>
        <div className="client-details-container">
          <div className="information-container">
            <div className="client-name-container">
              <div className="name-container">
                <p className="name">{owner.first_name}</p>
              </div>
              <div
                style={{ cursor: owner.average_score > 0 ? 'pointer' : 'auto' }}
                className="review-rate mb-1"
                onClick={() => {
                  if (owner.average_score > 0) {
                    handleOpenReviewSummaryModal()
                  }
                }}
              >
                {renderStarRating(owner.average_score)}
                <span>({owner.average_score ? owner.average_score.toFixed(2) : '0'})</span>
              </div>
            </div>
            <div className="icon-container">
              <div className="icon">
                <i className="bi bi-calendar-check"></i>
              </div>
              <p>Z mamfuche.pl od {getFormattedDate(owner.date_joined, false)}</p>
            </div>
            <div className="verification-info-container">
              {isAcceptedBidContractor ? (
                <>
                  <div
                    className={`icon-container offer-icon-container ${owner.is_email_verified ? 'verified' : 'not-verified'}`}
                  >
                    <div className="icon">
                      <i className="bi bi-envelope"></i>
                    </div>
                    <p>{clientDetails.email}</p>
                  </div>
                  <div className={`icon-container ${owner.is_phone_verified ? 'verified' : 'not-verified'}`}>
                    <div className="icon">
                      <i className="bi bi-telephone"></i>
                    </div>
                    <p>{clientDetails.phone_number}</p>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className={`icon-container offer-icon-container ${owner.is_email_verified ? 'verified' : 'not-verified'}`}
                  >
                    <div className="icon">
                      <i className="bi bi-envelope"></i>
                    </div>
                    <p className={`verify-text ${owner.is_email_verified ? 'verified' : 'not-verified'}`}>
                      {owner.is_email_verified ? 'Zweryfikowany adres email' : 'Niezweryfikowany adres email'}
                    </p>
                  </div>
                  <div className={`icon-container ${owner.is_phone_verified ? 'verified' : 'not-verified'}`}>
                    <div className="icon">
                      <i className="bi bi-telephone"></i>
                    </div>
                    <p className={`verify-text ${owner.is_phone_verified ? 'verified' : 'not-verified'}`}>
                      {owner.is_phone_verified ? 'Zweryfikowany numer telefonu' : 'Niezweryfikowany numer telefonu'}
                    </p>
                  </div>
                </>
              )}
            </div>
            {isContractor && (
              <OverlayTrigger
                key="write-message-tooltip"
                placement="top"
                overlay={
                  <Tooltip id="write-message-tooltip">
                    {!isActiveContractor
                      ? 'Tylko aktywne konto pozwala na kontakt z właścicielem fuchy'
                      : 'Kliknij aby napisać wiadomość do właściciela fuchy'}
                  </Tooltip>
                }
              >
                <button
                  disabled={!isActiveContractor}
                  id="write-message-tooltip"
                  className={`eg-btn btn--primary btn--sm mt-3 mb-3 ${!isActiveContractor ? 'disabled-button' : ''}`}
                  onClick={() => handleChatStart()}
                >
                  Napisz wiadomość
                </button>
              </OverlayTrigger>
            )}
            {isAcceptedBidContractor && (
              <OverlayTrigger
                key={`${fucha.id}-small-contractor-review`}
                placement="top"
                overlay={
                  <Tooltip id={`${fucha.id}-small-contractor-review`}>
                    {clientDetails.has_review ? 'Już wystawiłeś recenzję' : 'Oceń Klienta'}
                  </Tooltip>
                }
              >
                <button
                  onClick={() => {
                    setShowReviewModal(true)
                  }}
                  id={`${fucha.id}-small-contractor-review`}
                  className={'btn--primary eg-btn btn--sm m-2'}
                  disabled={clientDetails.has_review}
                >
                  <i className="bi bi-star"></i>
                </button>
              </OverlayTrigger>
            )}
            {showReviewModal && renderReviewModal()}
            <UserReviewsSummaryModal
              handleClose={handleCloseReviewSummaryModal}
              loading={reviewsSummary ? false : true}
              reviewsSummary={reviewsSummary}
              showModal={showReviewSummaryModal}
              userId={ownerId}
              userImage={owner.image}
              userName={owner.first_name}
              isContractor={false}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FuchaOwnerInfoCard
