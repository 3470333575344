import React, { useState, useEffect } from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { Link } from 'react-router-dom'
import { ReactComponent as AccountStatusIcon } from '../../../assets/images/icons/account-status.svg'
import { ReactComponent as ActiveFuchaIcon } from '../../../assets/images/icons/active-fucha.svg'
import { ReactComponent as BiddingHandIcon } from '../../../assets/images/icons/bidding-hand1.svg'
import { ReactComponent as CalendarIcon } from '../../../assets/images/icons/calendar2.svg'
import { ReactComponent as ConfettiIcon } from '../../../assets/images/icons/confetti.svg'
import { ReactComponent as DoubleCHeckIcon } from '../../../assets/images/icons/double-check.svg'
import { ReactComponent as HouseCog } from '../../../assets/images/icons/house-cog.svg'
import { ReactComponent as HouseHearts } from '../../../assets/images/icons/house-hearts.svg'
import { ReactComponent as HouseWrench } from '../../../assets/images/icons/house-wrench.svg'
import { ReactComponent as MobilePhoneIcon } from '../../../assets/images/icons/mobile-phone.svg'
import { ReactComponent as ReviewIcon } from '../../../assets/images/icons/review2.svg'
import { ReactComponent as ContractorProfileIcon } from '../../../assets/images/icons/contractor-profile.svg'
import AccountStatusTileBody from './tiles/AccountStatusTileBody'
import PhoneVerificationModal from '../../common/PhoneVeryficationModal'
import ProductChoiceTileBody from './tiles/ProductChoiceTileBody'
import Tile from '../../common/Tile'
import { useClientActions } from '../../../_actions/client.actions'
import { useContractorActions } from '../../../_actions/contractor.actions'
import { useUserActions } from '../../../_actions/user.actions'
import { useAlerts } from '../../../context/AlertContext'
import { shouldDisplayServerSideError } from '../../../_helpers'
import SkeletonSummaryTiles from './SkeleteonContent'
import { MAX_NUMBER_OF_ACTIVE_BIDS } from '../../../_constants/fucha'
import { URL_PATHS } from '../../../_constants'
import { useDashboardTab } from '../../../context/DashboardTabContext'
import { scrollToTop } from '../../../_helpers'

const ContentOfDashboardTab = ({ user }) => {
  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [modalError, setModalError] = useState('')
  const [otp, setOtp] = useState('')
  const [isConfirmingModal, setIsConfirmingModal] = useState(false)
  const [dashboardData, setDashboardData] = useState({})
  const { activeTab, setActiveTab } = useDashboardTab()
  const { addAlert } = useAlerts()
  const { getContractorDashboardData } = useContractorActions()
  const { getClientDashboardData } = useClientActions()
  const { phoneVerificationCode, verifyPhone } = useUserActions()
  const isContractor = user.is_contractor
  let isPhoneVerified = user.is_phone_verified
  const isUserProfile = user.profile ? true : false

  const getNewOTP = () => {
    phoneVerificationCode()
      .then((data) => {})
      .catch((error) => {
        if (shouldDisplayServerSideError(error)) {
          setModalError(error.response.data.detail)
        } else {
          addAlert(
            'Wystąpił błąd podczas wysyłania kodu weryfikacyjnego. Spróbuj ponownie zweryfikować numer telefonu.',
            'error'
          )
        }
      })
  }

  const handleClosePhoneVerificationModal = () => {
    setOtp('')
    setModalError('')
    setIsConfirmingModal(false)
    setShowModal(false)
  }

  const handleConfirmPhoneVerification = () => {
    setIsConfirmingModal(true)
    verifyPhone({ otp: otp })
      .then((data) => {
        addAlert('Udało się zweryfikować numer telefonu.', 'success')
        user.is_phone_verified = true
        isPhoneVerified = true
        setShowModal(false)
      })
      .catch((error) => {
        if (shouldDisplayServerSideError(error)) {
          setModalError(error.response.data.detail)
        } else {
          addAlert('Wystąpił błąd podczas weryfikacji numeru telefonu. Spróbuj ponownie później.', 'error')
        }
      })
      .finally(() => {
        setIsConfirmingModal(false)
        setOtp('')
      })
  }

  const handleOpenPhoneVerificationModal = () => {
    getNewOTP()
    setShowModal(true)
  }

  const renderPhoneVerificationTile = () => {
    return (
      <>
        <Tile bodyClassName="verification-phone-number" title="Weryfikacja numeru telefonu" col={12}>
          <div>
            <h2>+48 {user.phone_number}</h2>
            <p className="para">Zweryfikuj swój numer telefonu aby zwiększyć wiarygodność Twojego konta</p>
            <button className={`eg-btn btn--primary btn--sm mt-2`} onClick={() => handleOpenPhoneVerificationModal()}>
              Zweryfikuj
            </button>
          </div>
          <div className="icon">
            <MobilePhoneIcon width={50} height={50} />
          </div>
        </Tile>
        <PhoneVerificationModal
          showModal={showModal}
          handleCloseModal={handleClosePhoneVerificationModal}
          handleConfirmModal={handleConfirmPhoneVerification}
          otp={otp}
          setOtp={setOtp}
          newOtpCallback={getNewOTP}
          modalError={modalError}
          isConfirming={isConfirmingModal}
        />
      </>
    )
  }

  const renderClientTiles = () => {
    return (
      <div
        className="tab-pane fade show active"
        id="v-pills-dashboard"
        role="tabpanel"
        aria-labelledby="v-pills-dashboard-tab"
      >
        <div className="dashboard-area box--shadow">
          <div className="row g-4">
            {!isPhoneVerified && renderPhoneVerificationTile()}
            <Tile title="Aktywne Wyceny" col={6}>
              <div className="counter-item">
                <h2>{dashboardData.active_bids_count}</h2>
              </div>
              <div className="icon">
                <BiddingHandIcon width={50} height={50} />
              </div>
            </Tile>
            <Tile title="Wszystkie Fuchy" col={6}>
              <div className="counter-item">
                <h2>{dashboardData.total_count}</h2>
              </div>
              <div className="icon">
                <DoubleCHeckIcon width={50} height={50} />
              </div>
            </Tile>
            <Tile title="Aktywne Fuchy" col={6}>
              <div className="counter-item">
                <h2>{dashboardData.active_count}</h2>
              </div>
              <div className="icon">
                <HouseWrench width={50} height={50} />
              </div>
            </Tile>
            <Tile title="Zakończone Fuchy" col={6}>
              <div className="counter-item">
                <h2>{dashboardData.completed_count}</h2>
              </div>
              <div className="icon">
                <HouseHearts width={50} height={50} />
              </div>
            </Tile>
            <Tile title="Nieaktywne Fuchy" col={6}>
              <div className="counter-item">
                <h2>{dashboardData.inactive_count}</h2>
              </div>
              <div className="icon">
                <HouseCog width={50} height={50} />
              </div>
            </Tile>
            <Tile title="Konto Od" col={6}>
              <div className="counter-item">
                <h3>{dashboardData.account_created_at}</h3>
              </div>
              <div className="icon">
                <CalendarIcon width={50} height={50} />
              </div>
            </Tile>
          </div>
        </div>
      </div>
    )
  }

  const renderContractorTiles = () => {
    const daysLeft = dashboardData.active_days_left
    const accountActiveTo = new Date(dashboardData.account_active_to)
    return (
      <div
        className="summary-dashboard-container tab-pane fade show active"
        id="v-pills-dashboard"
        role="tabpanel"
        aria-labelledby="v-pills-dashboard-tab"
      >
        <div className="dashboard-area box--shadow">
          <div className="row g-4">
            {daysLeft <= 30 && (
              <Tile bodyClassName="payment-body" title="Już dziś przedłuż swoje konto" col={12}>
                <ProductChoiceTileBody user={user} />
              </Tile>
            )}
            {!isPhoneVerified && renderPhoneVerificationTile()}
            {!isUserProfile && (
              <Tile title="Twój profil fachowca nie został jeszcze utworzony!">
                <div>
                  <p className="para">
                    <b>Uzupełnij profil fachowca aby wzbudzić większe zaufanie wśród zlecających fuchy!</b>
                  </p>
                  <Link
                    to={`${URL_PATHS.DASHBOARD}`}
                    onClick={() => {
                      setActiveTab('tab11')
                      scrollToTop()
                    }}
                  >
                    <button className={`eg-btn btn--primary btn--sm mt-2`}>Stwórz Profil Fachowca</button>
                  </Link>
                </div>
                <div className="icon">
                  <ContractorProfileIcon width={60} height={60} />
                </div>
              </Tile>
            )}
            <Tile bodyClassName="summary-status-account" title="Status Konta" col={6}>
              <AccountStatusTileBody daysLeft={daysLeft} accountActiveTo={accountActiveTo} />
              <div className="icon">
                <AccountStatusIcon width={50} height={50} />
              </div>
            </Tile>

            <Tile bodyClassName="days-left" title="Pozostałe Aktywne Dni" col={6}>
              <div className="counter-item">
                <h2 className={daysLeft < 7 ? 'text-danger' : daysLeft <= 30 ? 'text-warning' : ''}>{daysLeft}</h2>
                {daysLeft > 0 && <p className="para"> do {accountActiveTo.toLocaleDateString('pl-PL')}</p>}
              </div>
              <div className="icon">
                <CalendarIcon width={50} height={50} />
              </div>
            </Tile>

            <Tile title="Zrealizowane Fuchy" col={6}>
              <div className="counter-item">
                <h2>{dashboardData.total_fuchy_assigned_count}</h2>
              </div>
              <div className="icon">
                <ConfettiIcon width={50} height={50} />
              </div>
            </Tile>

            <Tile title="Aktywne Fuchy" col={6}>
              <div className="counter-item">
                <OverlayTrigger
                  key="number-of-active-fucha"
                  placement="top"
                  overlay={
                    <Tooltip id="number-of-active-fucha">
                      Możesz składać wyceny do maksymalnie 20 fuch jednocześnie
                    </Tooltip>
                  }
                >
                  <h2 id="number-of-active-fucha">
                    <span
                      className={
                        dashboardData.active_fuchy_count < MAX_NUMBER_OF_ACTIVE_BIDS ? 'text-success' : 'text-danger'
                      }
                    >
                      {dashboardData.active_fuchy_count}
                    </span>{' '}
                    / {MAX_NUMBER_OF_ACTIVE_BIDS}
                  </h2>
                </OverlayTrigger>
              </div>

              <div className="icon">
                <ActiveFuchaIcon width={50} height={50} />
              </div>
            </Tile>
            <Tile title="Liczba Ocen" col={6}>
              {dashboardData.reviews_count ? (
                <div className="counter-item">
                  <h2>{dashboardData.reviews_count}</h2>
                </div>
              ) : (
                <h5>Nie masz jeszcze żadnych opinii</h5>
              )}
              <div className="icon">
                <ReviewIcon width={50} height={50} />
              </div>
            </Tile>
            <Tile title="Twoja Średnia Ocena" col={6}>
              {dashboardData.reviews_average_score ? (
                <div className="counter-item">
                  <h2>{dashboardData.reviews_average_score.toFixed(2)}</h2>
                </div>
              ) : (
                <h5>Nie masz jeszcze żadnych opinii</h5>
              )}
              <div className="icon">
                <ReviewIcon width={50} height={50} />
              </div>
            </Tile>
          </div>
        </div>
      </div>
    )
  }

  useEffect(() => {
    if (isContractor) {
      getContractorDashboardData()
        .then((resp) => {
          setDashboardData(resp)
        })
        .catch(() => {
          addAlert('Wystąpił błąd podczas pobierania danych', 'error')
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      getClientDashboardData(user.id)
        .then((resp) => {
          setDashboardData(resp)
        })
        .catch(() => {
          addAlert('Wystąpił błąd podczas pobierania danych', 'error')
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [])

  return <>{loading ? <SkeletonSummaryTiles /> : isContractor ? renderContractorTiles() : renderClientTiles()}</>
}

export default ContentOfDashboardTab
