import React from 'react'
import Skeleton from 'react-loading-skeleton'
import ImageGallery from 'react-image-gallery'
import FuchaImage from '../../../../assets/images/fucha/fucha-image.png'

const FuchaGalleryCard = ({ fucha, fuchaLoading, disabledFuchaAction }) => {
  return (
    <div className="col-lg-6 col-md-12 d-flex align-items-stretch">
      <div className={`card gallery-card ${disabledFuchaAction ? 'expired-fucha' : ''}`}>
        <div className="card-body gallery-card-body fucha-detail-gallery-container">
          <div className="sidebar-widget-title">
            <h4>Zdjęcia Fuchy</h4>
            <span />
          </div>
          {fuchaLoading ? (
            <div style={{ width: '100%', minHeight: '100%' }}>
              <Skeleton height={500} />
            </div>
          ) : (
            <div className="gallery-container">
              <ImageGallery
                items={
                  fucha.images.length
                    ? fucha.images.map((image) => {
                        return {
                          original: `${image.url}`,
                          thumbnail: `${image.gallery_min_thumbnail_url}`,
                        }
                      })
                    : [{ original: FuchaImage, thumbnail: FuchaImage }]
                }
                showPlayButton={false}
                additionalClass="fucha-images-gallery"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default FuchaGalleryCard
