import React from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../context/AuthContext'
import { URL_PATHS } from '../../../_constants/urls'
import { useDashboardTab } from '../../../context/DashboardTabContext'
import { scrollToTop } from '../../../_helpers'

const InactiveAccountMessage = () => {
  const { auth } = useAuth()
  const { activeTab, setActiveTab } = useDashboardTab()

  return (
    <section className="chat-section">
      <div className="chat-container d-flex flex-column align-items-center justify-content-center">
        <h4 className="text-center">
          Twoje konto jest obecnie nieaktywne. <br />
          Aby móc korzystać z czatu przedłuż ważność swojego konta.
        </h4>
        {auth?.user.is_contractor && (
          <Link
            style={{ margin: '20px 0' }}
            className="eg-btn btn--primary header-btn"
            to={`${URL_PATHS.DASHBOARD}`}
            onClick={() => {
              setActiveTab('panel')
              scrollToTop()
            }}
          >
            Przejdź do płatności
          </Link>
        )}
      </div>
      <div className="chat-footer"></div>
    </section>
  )
}

export default InactiveAccountMessage
