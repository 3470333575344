export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
export const API_WEBSOCKET_BASE_URL = process.env.REACT_APP_API_WEBSOCKET_BASE_URL

export const API_URLS = {
  AUTH_TOKEN: '/api/token/pair',
  AUTH_TOKEN_REFRESH: '/api/token/refresh',
  CATEGORIES: '/api/fucha/search-filters',
  CHAT_TOKEN: '/api/chat/token',
  CHAT_UNREAD_MESSAGES_COUNT: '/api/chat/unread-messages-count',
  CHECKOUT_SESSION: '/api/payments/session',
  CLIENT_ACCEPT_BID: '/api/users/client/accept-bid',
  CLIENT_CREATE_REVIEW: '/api/users/client/review-contractor',
  CLIENT_DASHBOARD_DATA: '/api/users/client/dashboard-data',
  CLIENT_MY_FUCHY: '/api/users/client/my-fuchy',
  CLIENT_UPDATE_ACCOUNT: '/api/users/client/account',
  CONTRACTOR_ACTIVE_BIDS_COUNT: '/api/users/contractor/active-bids-count',
  CONTRACTOR_CREATE_REVIEW: '/api/users/contractor/review-client',
  CONTRACTOR_DASHBOARD_DATA: '/api/users/contractor/dashboard-data',
  CONTRACTOR_GALLERY: '/api/users/contractor/gallery',
  CONTRACTOR_MY_FUCHY: '/api/users/contractor/my-fuchy',
  CONTRACTOR_MY_REVIEWS: '/api/users/contractor/my-reviews',
  CONTRACTOR_UPDATE_PROFILE: '/api/users/contractor/profile',
  CURRENT_USER: '/api/users/current-user',
  DELETE_ACCOUNT: '/api/users/delete-account',
  FUCHA_CREATE: '/api/fucha/',
  FUCHA_ENDING_SOON: '/api/fucha/ending-soon',
  FUCHA_LATEST: '/api/fucha/latest',
  FUCHA_LISTING: '/api/fucha/search',
  NOTIFICATIONS_MARK_ALL_READ: '/api/notifications/mark-all-read',
  NOTIFICATIONS_UNREAD: '/api/notifications/unread',
  NOTIFICATIONS_UNREAD_COUNT: '/api/notifications/unread/count',
  PASSWORD_RESET: '/api/users/reset-password',
  PASSWORD_UPDATE: '/api/users/update-password',
  PAYMENT_HISTORY: '/api/payments/history',
  PHONE_VERIFICATION_CODE: '/api/users/phone-verification-code',
  PLACE_BID: '/api/users/contractor/bids',
  PRODUCTS: '/api/payments/products',
  RESEND_VERIFICATION_EMAIL: '/api/users/resend-verification-email',
  SEND_CONTACT_FORM: '/api/contact/',
  SIGNUP: '/api/users/signup',
  UPDATE_AVATAR: '/api/users/update-avatar',
  USER_UPDATE_ACCOUNT: '/api/users/account',
  USER_UPDATE_ADDRESS: '/api/users/address',
  USER_UPDATE_NOTIFICATION_AND_MARKETING: '/api/users/marketing-and-notification-preferences',
  VERIFY_EMAIL: '/api/users/verify-email',
  VERIFY_PHONE: '/api/users/verify-phone',
}

export const DYNAMIC_API_URLS = {
  CancelMyFucha: function (fuchaId) {
    return `${API_URLS.CLIENT_MY_FUCHY}/${fuchaId}`
  },
  DeleteBid: function (bidId) {
    return `/api/users/contractor/bids/${bidId}`
  },
  FuchaDetail: function (fuchaId) {
    return `/api/fucha/${fuchaId}`
  },
  FuchaBids: function (fuchaId) {
    return `/api/fucha/${fuchaId}/bids`
  },
  FuchaDeleteImage: function (fuchaId, imageId) {
    return `/api/fucha/${fuchaId}/images/${imageId}`
  },
  FuchaImages: function (fuchaId) {
    return `/api/fucha/${fuchaId}/images`
  },
  FuchaPublish: function (fuchaId) {
    return `/api/fucha/${fuchaId}/publish`
  },
  SimilarFuchy: function (fuchaId) {
    return `/api/fucha/${fuchaId}/similar`
  },
  GetClientReviewsSummary: function (clientId) {
    return `/api/users/client/${clientId}/summary-reviews`
  },
  GetContractorReviewsSummary: function (contractorId) {
    return `/api/users/contractor/${contractorId}/summary-reviews`
  },
  GetFuchaMessages: function (fuchaId) {
    return `/api/users/contractor/messages/${fuchaId}`
  },
  GetMyBids: function (fuchaId) {
    return `/api/users/contractor/my-fuchy/${fuchaId}/bids`
  },
  ContractorGalleryDetail: function (galleryId) {
    return `/api/users/contractor/gallery/${galleryId}`
  },
  ContractorPublicGallery: function (contractorId) {
    return `/api/users/contractor/${contractorId}/gallery`
  },
  ContractorPublicProfile: function (contractorId) {
    return `/api/users/contractor/${contractorId}/public-profile`
  },
  NotificationMarkRead: function (notificationId) {
    return `/api/notifications/${notificationId}/mark-read`
  },
  GetClientDetails: function (fuchaId) {
    return `/api/fucha/${fuchaId}/client`
  },
  StartChat: function (fuchaId, contractorId) {
    return `/api/fucha/${fuchaId}/chat/${contractorId}`
  },
}

export const URL_PATHS = {
  ABOUT: `${process.env.PUBLIC_URL}/kim-jestesmy`,
  BLOG: `${process.env.PUBLIC_URL}/blog`,
  BLOG_DETAIL: `${process.env.PUBLIC_URL}/blog-details`,
  CHAT: `${process.env.PUBLIC_URL}/chat`,
  CONTACT: `${process.env.PUBLIC_URL}/kontakt`,
  DASHBOARD: `${process.env.PUBLIC_URL}/moj-profil`,
  DASHBOARD_SUMMARY: `${process.env.PUBLIC_URL}/moj-profil?tab=panel`,
  ERROR: `${process.env.PUBLIC_URL}/ups`,
  FAQ: `${process.env.PUBLIC_URL}/faq`,
  FUCHA_DETAILS: `${process.env.PUBLIC_URL}/fuchy/:fuchaId`,
  FUCHA_LISTING: `${process.env.PUBLIC_URL}/fuchy`,
  HOME: `${process.env.PUBLIC_URL}/`,
  HOW_IT_WORKS: `${process.env.PUBLIC_URL}/jak-dziala`,
  HOW_IT_WORKS_CONTRACTOR: `${process.env.PUBLIC_URL}/jak-dziala?dla-fachowca=1`,
  INFO_AFTER_SIGNUP: `${process.env.PUBLIC_URL}/dziekujemy-za-rejestracje`,
  LOGIN: `${process.env.PUBLIC_URL}/login`,
  NOT_FOUND: `${process.env.PUBLIC_URL}/nie-znaleziono`,
  PASSWORD_RESET: `${process.env.PUBLIC_URL}/zapomnialem-haslo`,
  PASSWORD_UPDATE: `${process.env.PUBLIC_URL}/aktualizuj-haslo`,
  PRICES: `${process.env.PUBLIC_URL}/cennik`,
  PRIVACY_POLICY: `${process.env.PUBLIC_URL}/polityka-prywatnosci`,
  SIGNUP: `${process.env.PUBLIC_URL}/rejestracja`,
  SIGNUP_CONTRACTOR: `${process.env.PUBLIC_URL}/rejestracja-fachowiec`,
  TERMS_AND_CONDITIONS: `${process.env.PUBLIC_URL}/regulamin`,
}

export const DYNAMIC_URL_PATHS = {
  ContractorProfile: function (userId) {
    return `${process.env.PUBLIC_URL}/fachowiec/${userId}/`
  },
  FuchaDetail: function (fuchaId) {
    return `${process.env.PUBLIC_URL}/fuchy/${fuchaId}/`
  },
  PasswordUpdate: function (token) {
    return `${process.env.PUBLIC_URL}/aktualizuj-haslo/${token}`
  },
}

export const DASHBOARD_TABS_ARGS = {
  SUMMARY: 'panel',
  ACCOUNT: 'konto',
  FUCHY: 'fuchy',
  PAYMENTS: 'patnosci',
  CREATE_FUCHA: 'wystaw-fuche',
  PROFILE: 'profil',
  RATING: 'ocen-fuche',
}

export default API_BASE_URL
