export const MAX_IMAGE_SIZE = 7 * 1024 * 1024
export const MAX_IMAGE_SIZE_MB = MAX_IMAGE_SIZE / 1024 / 1024
export const STAR_COLORS = {
  average: '#fe922a',
  belowAverage: '#ee7b66',
  default: '#696969',
  excellent: '#2bae09',
  good: '#e6b72f',
  poor: '#d81b09',
}
export const ReCAPTCHA_SITE_KEY = '6LeqZKEqAAAAADm2SX5G2u9NFxsWuPaPgJZTZPmv'
